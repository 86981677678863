import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MdChevronLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const SettingHeader = ({ title }) => {
    const navigate = useNavigate()

    return (
        <div className='setting-header'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="d-flex align-items-center cursor" onClick={() => navigate(-1)}>
                            <div className="circle me-3">
                                <MdChevronLeft className='text-white' />
                            </div>
                            Back
                        </div>

                        <h2 className={`${title ? "d-block mt-4" : "d-none"}`}>{title}</h2>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SettingHeader