import React, { useEffect, useState } from "react";
import {
  GetAuthUserLocalStorage,
  GetTokenLocalStorage,
  GetUserRoleLocalStorage,
} from "../services/localStorage/localStorage";
import { Route, Navigate, Outlet, useNavigate } from "react-router";
import ThemeModal from "../components/popup/ThemeModa";

const ProtectedRoute = () => {
  const token = GetTokenLocalStorage();
  const role = GetUserRoleLocalStorage();
  const loggedInUser = GetAuthUserLocalStorage();
  const navigate = useNavigate();
  // loggedInUser && loggedInUser?.isProfileCompleted ? navigate('/find-match') :
  // navigate("/complete-profile")}
  return token ? (
    loggedInUser?.isProfileCompleted ? (
      <Outlet />
    ) : (
      <Navigate to="/complete-profile" />
    )
  ) : (
    <Navigate to="/signin" />
  );
  // token ? <Outlet /> : <Navigate to="/signin" />
};

export const ProtectedRouteCompleteProfile = () => {
  const token = GetTokenLocalStorage();
  return token ? <Outlet /> : <Navigate to="/signin" />;
};

// export const ProtectedRouteForPaymentBasicFree = () => {
//   const token = GetTokenLocalStorage();
//   const user = GetAuthUserLocalStorage();
//   return user?.subscription_plan?.plan_id == null ? (
//     <Navigate to="/" />
//   ) : (
//     <Outlet />
//   );
// };
// export const ProtectedRouteForPaymentBasicFree = () => {
//   const token = GetTokenLocalStorage();
//   const user = GetAuthUserLocalStorage();
//   const [showModal, setShowModal] = useState(false);

//   // Check if user subscription plan is null and show modal
//   if (user?.subscription_plan?.plan_id == null) {
//     setShowModal(true);
//   }

//   return (
//     <>
//       {showModal && (

//       )}
//       <Outlet />
//     </>
//   );
// };

export const ProtectedRouteForPaymentBasicFree = () => {
  const token = GetTokenLocalStorage();
  const user = GetAuthUserLocalStorage();

  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Function to open the modal
  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    // Call the function to open the modal when the component mounts
    if (user?.subscription_plan?.payment == null) {
      openModal();
    }
  }, [user]); // Add dependencies if needed

  const onSubmitModal = () => {
    navigate("/membership");
  };

  return (
    <>
      {/* Render the modal component based on state */}
      {showModal && (
        <ThemeModal
          initialState={showModal}
          setState={setShowModal}
          Text={"Please update your subscription plan!"}
          heading={"WARNING"}
          size={"md"}
          cancelText={"Cancel"}
          btnText={"Update"}
          onSubmitModal={onSubmitModal}
        />
      )}

      {/* Render the main content or Outlet */}
      {!showModal && <Outlet />}
    </>
  );
};
export default ProtectedRoute;
