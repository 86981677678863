import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowIcon, ArrowLeft } from "../../constants/svgs";
import { EmptyLocalStorage } from "../../services/localStorage/localStorage";
import { toast } from "react-toastify";
import { error, success } from "../../constants/msg";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../services/auth/auth";

const LogoutPopup = ({ logoutPopup, setLogoutPopup }) => {
  const navigate = useNavigate();

  const logout = async () => {
    const res = await Logout();
    success(res?.data?.message);
    EmptyLocalStorage();
    // success("suceesfully LogedOut!")
    navigate("/signin");
    window.reload()
  };

  return (
    <Modal
      className="theme-popup logout-popup"
      show={logoutPopup}
      onHide={() => setLogoutPopup(false)}
      size="md"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <div>
          <div className="header text-center">
            <h2>Log Out</h2>
            <p className="mt-2">Are you sure you want to Log Out?</p>
          </div>

          <div className="mt-30 d-flex align-items-center justify-content-center ">
            <Button
              className="btn-outline btn-dark"
              onClick={() => setLogoutPopup(false)}
            >
              <div className="circle">
                <ArrowLeft />
              </div>
              CANCEL
            </Button>

            <Button onClick={logout} className="btn-solid btn-blue ms-4">
              LOGOUT
              <div className="circle">
                <ArrowIcon />
              </div>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutPopup;
