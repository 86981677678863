import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Assets from '../../constants/images'
import { EyeIcon, MessageIcon, ShareIcon, WhatsappIcon } from '../../constants/svgs'
import ImagePopup from '../popup/ImagePopup'
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { constant } from '../../utils/constants'
import { addFav } from '../../services/matches'
import { success } from '../../constants/msg'
import { SendRequest } from "../../services/userProfile";
import SharePopup from '../popup/SharePopup'

const MemberProfileCard = (props) => {
    const navigate = useNavigate()
    const [imagePopup, setImagePopup] = useState(false)
    const individualMatches = props?.individualMatches
    const splited_data = individualMatches?.height ? individualMatches?.height?.toString().split('.') : ''
    const handleFavourite = async () => {
        await addFav(
            { 'user_id': individualMatches?._id }
        )
        success(`${individualMatches?.first_name + ' ' + individualMatches?.last_name} add in your Favourites`)
    }
    const copyUrl = () => {
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        success('Text Copied to Clipboard')
    }

    const NavigateToMessages = async () => {
        try {
            let request = await SendRequest({
                request_to: individualMatches?._id
            })
            navigate('/setting/my-messages', {
                selected_room: request?.data?.data?._id
            })
        } catch (e) {
            // console.log(e.message)
        }

    }
    return (
        <>
            <div className='member-profile-card'>
                <Row>
                    <Col xs={12} lg={5} xl={4}>
                        <div className="img-wrapper">
                            <img src={individualMatches?.images?.length > 0 ? `${constant.BASE_URL}/${individualMatches?.images[0]}` : Assets.GeneralPlaceholderImg} alt="" onError={(e) => e.target.src = Assets.GeneralPlaceholderImg} />
                            <div className="icon-wrapper" onClick={() => setImagePopup(true)}>
                                <EyeIcon />
                                <span className='d-inline-block ms-2 text-white'>{individualMatches && individualMatches?.images?.length > 0 ? individualMatches?.images?.length : 0}</span>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} lg={7} xl={8} className='mt-4 mt-lg-0'>
                        <div className="content">
                            <div className="header mb-4 pb-4 d-flex flex-column flex-md-row justify-content-md-between">
                                <div>
                                    <h3 className='mb-1'><b>{individualMatches?.first_name + ' ' + individualMatches?.last_name}</b> <sub>({individualMatches?.age} Years)</sub></h3>
                                    <h3 className='mb-1 fw-normal'>{individualMatches?.gender}</h3>
                                    <h3 className='fw-normal'>{individualMatches?.country}</h3>
                                </div>

                                <div className='d-flex mt-4 mt-md-0'>
                                    {/* <div className="box bg-white">
                                        <IoMdHeartEmpty
                                            onClick={handleFavourite}
                                            className='icon outline' />
                                    </div> */}

                                    <div className="box bg-grey ms-3" onClick={copyUrl}>
                                        <ShareIcon />
                                    </div>

                                    {/* <div className="box bg-green ms-3">
                                    <WhatsappIcon />
                                </div> */}

                                    <div className="box bg-dark ms-3" onClick={NavigateToMessages}>
                                        <MessageIcon />
                                    </div>
                                </div>
                            </div>

                            <div className="list">
                                <Row>
                                    <Col md={5}>
                                        <Row>
                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Mother Tongue</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                {individualMatches?.mother_tongue?.length > 0 ? (
                                                    <h4 className='ps-0 ps-sm-3'>
                                                        {individualMatches?.mother_tongue?.join(', ')}
                                                    </h4>
                                                ) : ''}
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Cast</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.cast}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Religion</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.religion}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Martial Status</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.marital_status}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Spoken Language</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>
                                                    {individualMatches?.spoken_language?.length > 0 ?
                                                        individualMatches?.spoken_language?.join(', ')
                                                        : ''}
                                                </h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Physical Status</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.physical_status}</h4>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={{ span: 6, offset: 1 }}>
                                        <Row>
                                            {individualMatches.preferredCountry ?
                                                <>
                                                    <Col xs={12} sm={6} className='mb-4'>
                                                        <h4 className='color-grey'>Preferred Country</h4>
                                                    </Col>

                                                    <Col xs={12} sm={6} className='mb-4'>
                                                        <h4 className='ps-0 ps-sm-3'>{individualMatches.preferredCountry}</h4>
                                                    </Col>
                                                </>
                                                : ""}
                                            {individualMatches.preferredReligion ?
                                                <>
                                                    <Col xs={12} sm={6} className='mb-4'>
                                                        <h4 className='color-grey'>Preferred Religion</h4>
                                                    </Col>
                                                    <Col xs={12} sm={6} className='mb-4'>
                                                        <h4 className='ps-0 ps-sm-3'>{individualMatches?.preferredReligion}</h4>
                                                    </Col>
                                                </>
                                                : ""}

                                            {/* <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Preferred Country</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches.preferredCountry}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Preferred Religion</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.preferredReligion}</h4>
                                            </Col> */}

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Height</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{splited_data.length > 0 ? splited_data[0] + "''" + splited_data[1] : ''}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Complexion</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.complexion}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Drinking Habit</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.drinking_habit || '-'}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Smoking Habit</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.smoking_habit || '-'}</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='color-grey'>Eating Habit</h4>
                                            </Col>

                                            <Col xs={12} sm={6} className='mb-4'>
                                                <h4 className='ps-0 ps-sm-3'>{individualMatches?.eating_habit || '-'}</h4>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col >
                </Row >
            </div >

            <ImagePopup imagePopup={imagePopup} setImagePopup={setImagePopup} defaultData={individualMatches?.images} />
        </>
    )
}

export default MemberProfileCard