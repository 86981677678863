import React from 'react'
import { Button, Form, Modal } from "react-bootstrap"
import Assets from '../../constants/images'
import { NavLink } from 'react-router-dom'
import { ArrowIcon, ArrowLeft, ArrowProfileIcon } from '../../constants/svgs'
import { LiaTimesSolid } from "react-icons/lia";
import CustomCircularProgressbar from '../general/CustomCircularProgressbar'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from '../form/CustomInput'

const PriceFilterPopup = ({ priceFilterPopup, setPriceFilterPopup, vendorminmax, setVendorminmax, ...props }) => {
    const { handleSubmit, register, control, reset, watch, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' })
    const closeModal = () => {
        setPriceFilterPopup(false)
    }
    const handleSubmitData = (data) => {
        props?.setMin(data?.minimum)
        props?.setMax(data?.maximum)
        reset()
    }
    return (
        <Modal
            className='theme-popup'
            show={priceFilterPopup}
            onHide={closeModal}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div>
                    <div className='d-flex align-items-center justify-content-between border-bottom pb-3 mb-4'>
                        <h3 className='color-blue fw-700'>Filter By Price</h3>
                        <div>
                            <LiaTimesSolid size={22} className='icon cursor' onClick={closeModal} />
                        </div>
                    </div>

                    <Form onSubmit={handleSubmit(handleSubmitData)}>
                        <div className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <div className='mb-25 w-100 '>
                                <Controller
                                    control={control}
                                    name="minimum"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Minimum"
                                            placeholder="Minimum"
                                            type="number"
                                            required={true}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {errors.minimum && <small className='text-start d-block text-danger mt-1'>{errors.minimum.message}</small>}
                            </div>

                            <div className='mb-25 w-100 ms-sm-4'>
                                <Controller
                                    control={control}
                                    name="maximum"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Maximum"
                                            placeholder="Maximum"
                                            type="number"
                                            required={true}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {errors.maximum && <small className='text-start d-block text-danger mt-1'>{errors.maximum.message}</small>}
                            </div>
                        </div>

                        <div className='mt-3 d-flex flex-column flex-sm-row align-items-sm-center '>
                            <Button className="btn-outline btn-dark" type="button" onClick={() => reset()}>
                                <div className="circle">
                                    <ArrowLeft />
                                </div>
                                Reset
                            </Button>

                            <Button className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue" type="submit" onClick={() => {
                                setVendorminmax(!vendorminmax)
                                setPriceFilterPopup(false)
                            }}>
                                Filter
                                <div className="circle">
                                    <ArrowIcon />
                                </div>
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PriceFilterPopup