import React, { useContext, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../form/CustomInput'
import { useNavigate } from 'react-router-dom'
import { ArrowIcon } from '../../constants/svgs'
import { error, success } from '../../constants/msg'
import { ForgetPassword } from '../../services/auth/auth'
import { fogotPassContext } from '../../App'

const ForgotPasswordForm = () => {
    const { handleSubmit, register, control, reset, watch, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' })
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [forgotData, setForgotData] = useContext(fogotPassContext)


    // const onSubmit = (data) => {
    //     data.type = "forgotPassword"
    //     success("Email sent successfully")
    //     setTimeout(() => {
    //         navigate('/otp-verification', { state: data })
    //     }, 1200);
    // }

    const onSubmit = async (data) => {
        setLoading(true)
        const sendData = {
            email: data?.email,
            path: 'forgotPassword'
        }

        try {
            const res = await ForgetPassword(data)
            success(res?.data?.message)
            // data.type = "registration"
            setTimeout(() => {
                navigate('/otp-verification', { state: sendData })
            }, 1200)
            const forgotPassword = { 'path': 'forgotPassword' }
            const response_data_object = res?.data?.data
            const pathAndReqData = { ...response_data_object, ...forgotPassword }
            setForgotData(pathAndReqData);
            setLoading(false)
        } catch (e) {
            setLoading(false)
            error(e?.response?.data?.message);
        }
    }
    return (
        <div className='auth forgot-password-form'>
            <h1>Forgot Password</h1>
            <h3 className='mt-4'>
                Reset your password here <br />
                We are here to help you to recover your password.
                Enter the email address you used when you joined Rishtey and Shadi.and we'll send you
                instructions to reset your password.
            </h3>

            <div>
                <Form className='mt-5' onSubmit={handleSubmit(onSubmit)}>
                    {/* EMAIL INPUT */}
                    <div className='mb-30'>
                        <Controller
                            control={control}
                            name="email"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                },
                                maxLength: {
                                    value: VALIDATIONS.EMAIL,
                                    message: VALIDATIONS_TEXT.EMAIL
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                }
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Email Address"
                                    placeholder="Email Address"
                                    type="email"
                                    required={true}
                                    maxLength={VALIDATIONS.EMAIL}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}
                    </div>

                    {/* BUTTON AND LINKS */}
                    <Button className="btn-solid btn-blue" type="submit" disabled={loading}>
                        SUBMIT
                        <div className="circle">
                            <ArrowIcon />
                        </div>
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPasswordForm