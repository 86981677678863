import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tabs, Tab } from "react-bootstrap";
import {
  ArrowIcon,
  ArrowLeft,
  DeleteIcon,
  FilterIcon,
  TimesIconSm,
  UploadIconBlue,
} from "../../constants/svgs";
import Assets from "../../constants/images";
import VendorCard from "./VendorCard";
import PriceFilterPopup from "../popup/PriceFilterPopup";
import { getVendorsByType } from "../../services/vendors";

const VendorsForm = ({
  watch,
  control,
  formState,
  setValue,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { errors, isDirty, isValid, isSubmitting } = formState;
  const [vendors] = watch(["vendors"]);
  const [priceFilterPopup, setPriceFilterPopup] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [activeTab, setActiveTab] = useState("banquets");
  const [minimum, maximum] = watch(["minimum", "maximum"]);
  const [vendorminmax, setVendorminmax] = useState(false)
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  const [tabs, setTabs] = useState([
    {
      id: "1",
      value: "Banquets",
      type: "banquets",
    },
    {
      id: "2",
      value: "Photo/Videographer",
      type: "photo_videographer",
    },
    {
      id: "3",
      value: "Catering",
      type: "catering",
    },
    {
      id: "4",
      value: "Music",
      type: "music",
    },
    {
      id: "5",
      value: "Entertainments",
      type: "entertainments",
    },
    {
      id: "6",
      value: "Others",
      type: "others",
    },
  ]);
  const handleChange = (data) => {
    let tempVendors = [...selectedVendors];
    const index = tempVendors.findIndex((vendor) => vendor?._id == data?._id);

    if (index != -1) {
      tempVendors.splice(index, 1);
      setSelectedVendors(tempVendors);
    } else {
      tempVendors.push(data);
      setSelectedVendors(tempVendors);
    }
  };

  const removeVendor = (index) => {
    let tempVendors = [...selectedVendors];
    tempVendors.splice(index, 1);
    setSelectedVendors(tempVendors);
  };

  useEffect(() => {
    setValue("vendors", selectedVendors);
  }, [selectedVendors]);

  useEffect(() => {
    if (vendors?.length > 0) {
      setSelectedVendors(vendors);
    }
  }, []);

  const [vendorsList, setVendorsList] = useState([]);
  useEffect(() => {
    GetVendorsByType();
  }, [activeTab, vendorminmax]);
  const GetVendorsByType = async () => {
    const params = {
      type: activeTab,
      min_price: min,
      max_price: max,
    };
    const response = await getVendorsByType(params);
    setVendorsList(response?.data?.data);
  };
  return (
    <>
      <div className="wedding-card-form">
        <Row>
          <Col xs={12} xl={11} className='border-bottom pb-3 mb-4'>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h3 className="fw-800 color-blue">Vendors</h3>
                <i className='italic-tag-line fw-400 mb-5 color-grey'>Vendor selection for the planned program(s</i>
              </div>
              <div className="d-flex align-items-center">
                <h6
                  className="me-2 color-dark cursor"
                  onClick={() => setPriceFilterPopup(true)}
                >
                  Reset Filter
                </h6>
                <FilterIcon />
              </div>
            </div>
          </Col>

          <Col xs={12} xl={11}>
            <div>
              <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="tabs mb-3"
              >
                {tabs?.map((item, index) => (
                  <Tab
                    key={index}
                    eventKey={item?.type}
                    title={item?.value}
                  ></Tab>
                ))}
              </Tabs>
            </div>

            {/* <div className="d-flex align-items-center flex-wrap">
              {selectedVendors?.map((item, index) => (
                <div key={index} className="tag mb-3 me-3">
                  <div className="d-flex align-items-center">
                    <small className="color-dark">{item?.name}</small>
                    <span
                      className="cursor ms-3"
                      onClick={() => removeVendor(index)}
                    >
                      <TimesIconSm />
                    </span>
                  </div>
                </div>
              ))}
            </div> */}

            <div className="overflow-wrapper mt-3 pe-2">
              {vendorsList?.map((item, index) => (
                <div key={index} className="mb-4 border-bottom pb-4">
                  <VendorCard
                    data={item}
                    selectedVendors={selectedVendors}
                    handleChange={handleChange}
                  />
                </div>
              ))}
            </div>

            {/* NEXT STEP BUTTON */}
            <div className="mt-5 d-flex flex-column flex-sm-row align-items-sm-center ">
              <Button
                onClick={handlePreviousStep}
                className="btn-outline btn-dark"
                type="button"
              >
                <div className="circle">
                  <ArrowLeft />
                </div>
                PREVIOUS STEP
              </Button>

              <Button
                onClick={handleNextStep}
                className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue"
                type="submit"
                disabled={!isDirty || !isValid || selectedVendors?.length <= 0}
              >
                NEXT STEP
                <div className="circle">
                  <ArrowIcon />
                </div>
              </Button>
            </div>
          </Col>
        </Row>
      </div >

      <PriceFilterPopup
        priceFilterPopup={priceFilterPopup}
        setPriceFilterPopup={setPriceFilterPopup}
        setMin={setMin}
        setMax={setMax}
        vendorminmax={vendorminmax}
        setVendorminmax={setVendorminmax}
      />
    </>
  );
};

export default VendorsForm;
