import React, { forwardRef } from 'react'
import { Form } from 'react-bootstrap'

const CustomCheck = forwardRef((props, ref) => {
    const {
        field,
        name,
        label = "",
        type = "checkbox",
        required = false,
        value,
        onChange,
        disabled = false
    } = props

    return (
        <Form.Group className="custom-check">
            <Form.Check
                {...field}
                type={type}
                name={name}
                label={label}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </Form.Group>
    )
})

export default CustomCheck