import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ArrowIcon } from "../../constants/svgs";
import CustomSelect from "../form/CustomSelect";
import CustomInput from "../form/CustomInput";
import { DropDown } from "../../services/dropdown";
import { MdYoutubeSearchedFor } from "react-icons/md";
import { RxReset } from "react-icons/rx";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";

const MatchFilterCard = ({ control, errors, handleParamsReset, ...props }) => {
  const [country, setCountry] = useState([]);
  const [religion, setReligion] = useState([]);
  const { handleSubmit, reset } = useForm({ mode: 'onChange' })
  const user = GetAuthUserLocalStorage()

  // const handleParams = (key, value) => {
  //   props?.setSearchParams((prevValues) => ({
  //     ...prevValues,
  //     [key]: value,
  //   }));
  // };
  useEffect(() => {
    GetCountryDropDown();
    GetReligionDropDown();
  }, []);
  const GetCountryDropDown = async () => {
    const params = {
      type: "country",
    };
    const res = await DropDown(params);
    const response = res?.data?.data?.map((dt) => ({
      value: dt?._id,
      label: dt?.name,
    }));
    setCountry(response);
  };

  const GetReligionDropDown = async () => {
    const params = {
      type: "religion",
    };
    const res = await DropDown(params);
    const response = res?.data?.data?.map((dt) => ({
      value: dt?._id,
      label: dt?.name,
    }));
    setReligion(response);
  };
  const onSubmitData = (data) => {
    // props?.setSearchParams({
    //   minAge: data?.minAge,
    //   maxAge: data?.maxAge,
    //   country: data?.country,
    //   religion: data?.religion,
    // })
  }
  return (
    <div className="match-filter-card">
      {user?.subscription_plan?.payment === null || user?.subscription_plan?.plan_name === "Basic" ? '' :
        <div className="reset d-flex cursor" onClick={handleParamsReset}>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="reset-tooltip">Reset</Tooltip>}
          >
            <span className="ms-auto" onClick={handleParamsReset}>
              <RxReset />
            </span>
          </OverlayTrigger>
          {/* <span className="ms-auto">
          <RxReset />
        </span> */}
          {/* <span className="text">Reset</span> */}
        </div>
      }

      <Row>
        {/* LOOKING FOR INPUT */}
        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
        </Col>

        {/* AGE RANGE INPUT */}
        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
          <div className="mb-25">
            <label className="mb-2 d-inline-block">Age Min</label>
            <div>
              <Controller
                control={control}
                name="ageMin"
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Min Age"
                    placeholder="Height"
                    type="number"
                    disabled={user?.subscription_plan?.payment === null || user?.subscription_plan?.plan_name === "Basic" ? true : false}
                    required={true}
                    // value={props?.searchParams?.minAge}
                    value={field.value}
                    // onChange={(e) => handleParams("minAge", e.target.value)}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
        </Col>

        {/* AGE RANGE INPUT */}
        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
          <div className="mb-25">
            <label className="mb-2 d-inline-block">Age Max</label>
            <div>
              <Controller
                control={control}
                name="ageMax"
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Max Age"
                    placeholder="Height"
                    type="number"
                    required={true}
                    disabled={user?.subscription_plan?.payment === null || user?.subscription_plan?.plan_name === "Basic" ? true : false}
                    // value={props?.searchParams?.maxAge}
                    value={field.value}
                    // onChange={(e) => handleParams("maxAge", e.target.value)}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
        </Col>

        {/*COUNTRY INPUT */}
        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
          <div className="mb-25">
            <label className="mb-2 d-inline-block">Country</label>
            <div>
              <Controller
                control={control}
                name="country"
                rules={{
                  required: {
                    value: false,
                  },
                }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    label="Country"
                    placeholder="Country"
                    options={country}
                    required={false}
                    isDisabled={user?.subscription_plan?.payment === null || user?.subscription_plan?.plan_name === "Basic" ? true : false}
                    // value={props?.searchParams?.country}
                    value={field.value}
                    // onChange={(e) => handleParams("country", e)}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
        </Col>

        {/*RELIGION INPUT */}
        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
          <div className="mb-25">
            <label className="mb-2 d-inline-block">Religion</label>
            <div>
              <Controller
                control={control}
                name="religion"
                rules={{
                  required: {
                    value: false,
                  },
                }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    label="Religion"
                    placeholder="Religion"
                    options={religion}
                    required={false}
                    // value={props?.searchParams?.religion}
                    value={field.value}
                    isDisabled={user?.subscription_plan?.payment === null || user?.subscription_plan?.plan_name === "Basic" ? true : false}
                    // onChange={(e) => {
                    //   handleParams("religion", e);
                    // }}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
        </Col>

        {/*SEARCH BUTTON */}
        <Col xs={12} sm={6} md={4} lg={3} xl={2} className="reset-btn">
          <label className='d-inline-block invisible mb-2'>Button</label>
          <Button className="btn-solid btn-blue mb-25" type="submit"
            // onClick={handleParamsReset}
            disabled={user?.subscription_plan?.payment === null || user?.subscription_plan?.plan_name === "Basic" ? true : false}
          >
            SEARCH
            <div className="circle">
              <MdYoutubeSearchedFor />
            </div>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MatchFilterCard;