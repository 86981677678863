import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import OtpInput from 'react-otp-input'
import { Button, Form } from 'react-bootstrap'
import { ArrowIcon } from '../../constants/svgs'
import { useLocation, useNavigate } from 'react-router-dom'
import { error, success } from '../../constants/msg'
import { VerifyOtp, resendOTP } from '../../services/auth/auth'
import { EmptyLocalStorage, GetLocalStorageSignUpEmail, GetTokenLocalStorageSignUpEmail } from '../../services/localStorage/localStorage'
import { fogotPassContext } from '../../App'

const VerificationCodeForm = () => {
    const { handleSubmit, register, control, reset, watch, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' })
    const [counter, setCounter] = useState(30);
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [forgotData, setForgotData] = useContext(fogotPassContext)
    const onSubmit = async (data) => {
        setLoading(true)
        let type = location?.state
        const submitData = {
            'email': forgotData && forgotData.email ? forgotData.email : null,
            'otp': data?.otp
        }


        try {
            const res = await VerifyOtp(submitData)
            setLoading(false)
            EmptyLocalStorage()
            success(res?.data?.message)
            if (forgotData?.path == "forgotPassword") {
                setTimeout(() => {
                    navigate('/reset-password')
                }, 1200);
            }
            else {
                setTimeout(() => {
                    navigate('/signin')
                }, 1200);
            }
        }
        catch (e) {
            setLoading(false)
            error(e?.response?.data?.message)
            navigate('/otp-verification', { state: location?.state?.email })
        }
    }

    const resendCode = async () => {
        setCounter(30)
        const data = {
            'email': forgotData && forgotData.email ? forgotData.email : null,
        }
        try {
            const res = await resendOTP(data)
            success(res?.data?.message)
            setCounter(30)
        }
        catch (e) {
            setCounter(0)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        let timer = counter > 0 && setInterval(() => {
            setCounter(counter - 1)
        }, 1000)

        return () => clearInterval(timer)
    }, [counter])

    return (
        <div className='auth otp-verification-form'>
            <h1>OTP Verification</h1>
            <h3 className='mt-4'>
                Enter the 4 digit verification code we just sent you on
                your email address.
            </h3>

            <div>
                <Form className='form-wrapper mt-5' onSubmit={handleSubmit(onSubmit)}>
                    {/* OTP INPUT */}
                    <div className='otp-wrapper'>
                        <Controller
                            control={control}
                            name="otp"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.VERIFICATION_CODE_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.VERIFICATION_CODE_MIN,
                                    message: VALIDATIONS_TEXT.VERIFICATION_CODE_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.VERIFICATION_CODE_MAX,
                                    message: VALIDATIONS_TEXT.VERIFICATION_CODE_MAX
                                },
                            }}
                            render={({ field }) => (
                                <OtpInput
                                    className="otp-input"
                                    value={field.value}
                                    onChange={(otp) => field.onChange(otp)}
                                    numInputs={4}
                                    isInputNum={true}
                                    placeholder="----"
                                    hasErrored={true}
                                    focusStyle="focus"
                                />
                            )}
                        />
                        {errors.otp && <small className='text-start d-block text-danger mt-1'>{errors.otp.message}</small>}
                    </div>
                    <Button type="submit" disabled={loading} className={`mt-30 btn-solid btn-blue btn-verify`}>
                        VERIFY OTP
                        <div className="circle">
                            <ArrowIcon />
                        </div>
                    </Button>
                </Form>

                {
                    counter > 0 ?
                        <span className='d-inline-block'>Resend in: <b className='timer'> 00:{counter < 10 && '0'}{counter}</b></span>
                        :
                        <>
                            <span> If you didn't receive a code! &nbsp;</span>
                            <span className='resend-code' onClick={resendCode}>Resend Code</span>
                        </>
                }
            </div>
        </div>
    )
}

export default VerificationCodeForm