import React, { useState, useEffect, useContext } from 'react'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from '../form/CustomInput'
import CustomSelect from '../form/CustomSelect'
import { ArrowIcon, ArrowLeft } from '../../constants/svgs'
import { Button, Form, Col, Row } from 'react-bootstrap'
import CustomTextarea from '../form/CustomTextarea'
import { DropDown } from '../../services/dropdown'
import { GetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { genderSelectedContext } from '../../App'
import { useParams } from 'react-router-dom/dist'

const PersonalInfoForm = ({ control, formState, handleStep, country, religion }) => {
    const { errors, isDirty, isValid, isSubmitting } = formState
    const Location = window.location.pathname
    // console.log(Location, 'iii');
    const [genderSelected, setGenderSelected] = useContext(genderSelectedContext)
    const [language, setLanguage] = useState([])
    const [physicalStatus, setPhysicalStatus] = useState([])
    const [complexion, setComplexion] = useState([])
    const [eatingHabit, setEatingHabit] = useState([])
    const [drinking, setDrinking] = useState([])
    const [smoking, setSmoking] = useState([])


    useEffect(() => {
        GetLanguageDropDown()
        GetPhysicalsStatusDropDown()
        GetComplexionDropDown()
        GetEatingHabitDropDown()
        GetDrinkingDropDown()
        GetSmokingDropDown()
    }, [])

    const GetLanguageDropDown = async () => {
        const params = {
            type: 'language'
        }
        const res = await DropDown(params)
        const response = res?.data?.data?.map((dt) => ({
            value: dt?._id,
            label: dt?.name
        }))
        setLanguage(response)
    }

    const GetPhysicalsStatusDropDown = async () => {
        const params = {
            type: 'physical-status',
            gender: genderSelected.toLowerCase()
        }
        const res = await DropDown(params)
        const response = res?.data?.data?.map((dt) => ({
            value: dt?._id,
            label: dt?.name
        }))
        setPhysicalStatus(response)
    }


    const GetComplexionDropDown = async () => {
        const params = {
            type: 'complexion'
        }
        const res = await DropDown(params)
        const response = res?.data?.data?.map((dt) => ({
            value: dt?._id,
            label: dt?.name
        }))
        setComplexion(response)
    }


    const GetEatingHabitDropDown = async () => {
        const params = {
            type: 'eating-habit'
        }
        const res = await DropDown(params)
        const response = res?.data?.data?.map((dt) => ({
            value: dt?._id,
            label: dt?.name
        }))
        setEatingHabit(response)
    }

    const GetDrinkingDropDown = async () => {
        const params = {
            type: 'drinking'
        }
        const res = await DropDown(params)
        const response = res?.data?.data?.map((dt) => ({
            value: dt?._id,
            label: dt?.name
        }))
        setDrinking(response)
    }

    const GetSmokingDropDown = async () => {
        const params = {
            type: 'smoking'
        }
        const res = await DropDown(params)
        const response = res?.data?.data?.map((dt) => ({
            value: dt?._id,
            label: dt?.name
        }))
        setSmoking(response)
    }



    const handleDisable = () => {
        if (!isDirty || !isValid || isSubmitting) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <div className='basic-info-form'>
            {/* SPOKEN LANGUAGE INPUT */}
            <div className="mb-25">
                <Controller
                    control={control}
                    name="spokenLanguage"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.SPOKEN_LANGUAGE_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Spoken Language"
                            placeholder="Spoken Language *"
                            options={language}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                            isMulti={true}
                        />
                    )}
                />
                {errors.spokenLanguage && <small className='text-start d-block text-danger mt-1'>{errors.spokenLanguage.message}</small>}

            </div>

            {/* PHYSICAL STATUS INPUT */}
            <div className="mb-25">
                <Controller
                    control={control}
                    name="physicalStatus"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.PHYSICAL_STATUS_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Physical Status"
                            placeholder="Physical Status *"
                            options={physicalStatus}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                {errors.physicalStatus && <small className='text-start d-block text-danger mt-1'>{errors.physicalStatus.message}</small>}

            </div>

            {/* MEDICAL INPUT */}
            {/* <div className="mb-25">
                <Controller
                    control={control}
                    name="medical"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.MEDICAL_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Medical"
                            placeholder="Medical"
                            options={[
                                { label: "Good", value: "good" },
                            ]}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                {errors.medical && <small className='text-start d-block text-danger mt-1'>{errors.medical.message}</small>}

            </div> */}

            {/* HEIGHT INPUT */}
            <Row>
                <Col xs={'6'}>
                    <div className="mb-25">
                        <Controller
                            control={control}
                            name="feet"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.HEIGHT_REQUIRED,
                                },
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Feet *"
                                    placeholder="Height *"
                                    type="number"
                                    required={true}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.feet && <small className='text-start d-block text-danger mt-1'>{errors.feet.message}</small>}

                    </div>
                </Col>
                <Col xs={'6'}>
                    <div className="mb-25">
                        <Controller
                            control={control}
                            name="inch"
                            // rules={{
                            //     required: {
                            //         value: true,
                            //         message: VALIDATIONS_TEXT.HEIGHT_REQUIRED,
                            //     },
                            // }}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Height is required',
                                },
                                min: {
                                    value: 0,
                                    message: 'Please enter a non-negative value',
                                },
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Inch *"
                                    placeholder="Height *"
                                    type="number"
                                    required={true}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.inch && <small className='text-start d-block text-danger mt-1'>{errors.inch.message}</small>}

                    </div>
                </Col>
            </Row>


            {/* COMPLEXION INPUT */}
            <div className="mb-25">
                <Controller
                    control={control}
                    name="complexion"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.COMPLEXION_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Complexion"
                            placeholder="Complexion *"
                            options={complexion}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                {errors.complexion && <small className='text-start d-block text-danger mt-1'>{errors.complexion.message}</small>}

            </div>

            {/* VEGETARIAN INPUT */}
            <div className="mb-25">
                <Controller
                    control={control}
                    name="vegetarian"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.VEGETARIAN_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Vegetarian"
                            placeholder="Vegetarian *"
                            options={[
                                { label: "Yes", value: "yes" },
                                { label: "No", value: "no" },
                            ]}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                {errors.vegetarian && <small className='text-start d-block text-danger mt-1'>{errors.vegetarian.message}</small>}

            </div>

            {/* EATING HEBITS INPUT */}
            <div className="mb-25">
                <Controller
                    control={control}
                    name="eatingHebits"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.EATING_HEBITS_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Eating Hebits"
                            placeholder="Eating Hebits *"
                            options={eatingHabit}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                {errors.eatingHebits && <small className='text-start d-block text-danger mt-1'>{errors.eatingHebits.message}</small>}

            </div>

            {/* DRINKING HEBITS INPUT */}
            <div className="mb-25">
                <Controller
                    control={control}
                    name="drinkingHebits"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.DRINKING_HEBITS_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Drinking Hebits"
                            placeholder="Drinking Hebits *"
                            options={drinking}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                {errors.drinkingHebits && <small className='text-start d-block text-danger mt-1'>{errors.drinkingHebits.message}</small>}

            </div>

            {/* SMOKING HEBITS INPUT */}
            <div className="mb-25">
                <Controller
                    control={control}
                    name="smokingHebits"
                    rules={{
                        required: {
                            value: true,
                            message: VALIDATIONS_TEXT.SMOKING_HEBITS_REQUIRED,
                        },
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Smoking Hebits"
                            placeholder="Smoking Hebits *"
                            options={smoking}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                {errors.smokingHebits && <small className='text-start d-block text-danger mt-1'>{errors.smokingHebits.message}</small>}

            </div>

            {/* ABOUT YOURSELF INPUT */}
            <div className='mb-25'>
                <Controller
                    control={control}
                    name="about"
                    rules={{
                        required: {
                            value: false,
                        },
                        maxLength: {
                            value: VALIDATIONS.BIO_MAX,
                            message: VALIDATIONS_TEXT.BIO_MAX
                        },
                    }}
                    render={({ field }) => (
                        <CustomTextarea
                            {...field}
                            label="About Yourself"
                            placeholder="About Yourself *"
                            type="text"
                            required={true}
                            maxLength={VALIDATIONS.BIO_MAX}
                            value={field.value}
                            onChange={field.onChange}
                            disabled={false}
                        />
                    )}
                />
                {errors.about && <small className='text-start d-block text-danger mt-1'>{errors.about.message}</small>}
            </div>

            <div className='mb-3'>
                <h3 className='text-start'>ADD YOUR PREFERENCES (OPTIONAL)</h3>
            </div>
            <div className="mb-25">
                <Controller
                    control={control}
                    name="preferredCountry"
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Preferred Country"
                            placeholder="Preferred Country"
                            options={country}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </div>

            <div className="mb-25">
                <Controller
                    control={control}
                    name="preferredReligion"
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            label="Preferred Religion"
                            placeholder="Preferred Religion"
                            options={religion}
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </div>
            {/* COMPLETE BUTTON */}
            <div className='mt-5 d-flex flex-column flex-sm-row align-items-sm-center '>
                <Button onClick={handleStep} className="btn-outline btn-dark" type="button">
                    <div className="circle">
                        <ArrowLeft />
                    </div>
                    PREVIOUS STEP
                </Button>

                <Button className="btn-solid btn-blue ms-0 ms-sm-4 mt-3 mt-sm-0" type="submit" disabled={false}>
                    {Location === '/setting/edit-profile' ? 'UPDATE PROFILE' : 'COMPLETE PROFILE'}
                    <div className="circle">
                        <ArrowIcon />
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default PersonalInfoForm