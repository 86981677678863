import React, { useState } from 'react'
import IntroductionBanner from '../components/static/IntroductionBanner'
import { completeProfile } from '../constants/content'
import { Col, Container, Form, Image, Row } from 'react-bootstrap'
import BasicInfoForm from '../components/static/BasicInfoForm'
import CompleteProfileForm from '../components/static/CompleteProfileForm'

const CompleteProfilePage = () => {
    const [activeStep, setActiveStep] = useState(1)

    return (
        <div className='static-pages complete-profile-page'>
            <IntroductionBanner
                title={completeProfile.title}
                description={completeProfile.description}
                withBtns={false}
            />

            <div className="content-wrapper">
                <Container>
                    <Row>
                        <Col xs={12} xl={5}>
                            <div className="titles">
                                <h1 className=''>Complete Your Profile to Find Best Match</h1>
                                <h4 className='mt-3'>
                                    Begin your quest for a significant connection by crafting your profile. This is where your search for
                                    the ideal match starts. Offer an in-depth view of your personality, hobbies, interests, and goals,
                                    setting the stage for a customized matchmaking journey.
                                    <br /><br />
                                    The richer the information you provide, the more effectively our sophisticated algorithms can match
                                    you with partners who resonate with your beliefs and relationship aspirations. Embark on the
                                    journey to meet your perfect match by finalizing your profile now. Your ideal partner could be just a
                                    few clicks away.
                                </h4>
                            </div>
                        </Col>

                        <Col xs={12} xl={{ span: 6, offset: 1 }}>
                            <div className="step-form-wrapper mt-4 mt-xl-0">
                                <div className="header mb-30">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={`step ${activeStep == 1 ? "active" : ""} d-flex align-items-center justify-content-md-center border-right pb-3 pb-md-0`}>
                                                <div className="circle">
                                                    <h6>01</h6>
                                                </div>
                                                <h6 className='text-uppercase ms-3'>Basic information</h6>
                                            </div>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <div className={`step ${activeStep == 2 ? "active" : ""} d-flex align-items-center justify-content-md-center mt-3 mt-md-0`}>
                                                <div className="circle">
                                                    <h6>02</h6>
                                                </div>
                                                <h6 className='text-uppercase ms-3'>personal information</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="steps-form">
                                    <Row>
                                        <Col xs={12}>
                                            <CompleteProfileForm activeStep={activeStep} setActiveStep={setActiveStep} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CompleteProfilePage