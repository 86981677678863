import React, { useContext, useEffect, useState } from "react";
import TenureCard from "./TenureCard";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import PaymentMethodCard from "./PaymentMethodCard";
import { ArrowIcon } from "../../constants/svgs";
import { confirmpayment } from "../../services/payment";
import { useNavigate, useParams } from "react-router-dom";
import { subscribeplanDataContext } from "../../App";
import { error, success } from "../../constants/msg";
import { SetAuthUserLocalStorage } from "../../services/localStorage/localStorage";

const PaymentForm = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });
  const [subscribeplanData, setSubscribeplanData] = useContext(
    subscribeplanDataContext
  );

  const [tenure] = watch(["tenure"]);
  const { id } = useParams();
  const onSubmit = async (data) => {
    data["cardId"] = id;
    data["subscription_id"] = subscribeplanData[0]?._id;
    data["price_policy"] = data?.tenure;
    try {
      const response = await confirmpayment(data);
      SetAuthUserLocalStorage(response?.data?.data);
      success(response?.data?.message);
      navigate("/find-match");
    } catch (e) {
      error(e?.response?.data?.message);
      navigate("/membership");
    }
  };
  return (
    <div className="payment-form">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TenureCard control={control} errors={errors} />
        {/* <PaymentMethodCard control={control} errors={errors} /> */}
        <div className="d-flex justify-content-end mt-30">
          <Button className="btn-solid btn-blue" type="submit">
            PAY NOW
            <div className="circle">
              <ArrowIcon />
            </div>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PaymentForm;
