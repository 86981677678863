import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { MdChevronLeft } from 'react-icons/md'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import MemberProfileCard from '../components/static/MemberProfileCard'
import MatchCard from '../components/static/MatchCard'
import Assets from '../constants/images'
import { GetMatches } from '../services/matches'
import { GetUserById } from '../services/userProfile'
import { reloadMatchesContext } from '../App'
import { Loader } from '../components/loader/Loader'

const MemberProfilePage = () => {
    const { id } = useParams('')
    const navigate = useNavigate()
    const [individualMatches, setIndividualMatches] = useState([])
    const [matches, setMatches] = useState([])
    const [pagination, setPagination] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataloading, setDataLoading] = useState(false)
    const [reloadMatches, setReloadMatches] = useContext(reloadMatchesContext)

    useEffect(() => {
        if (reloadMatches) {
            GetMatchesOfUser()
            setReloadMatches(false)
        }
    }, [reloadMatches])

    useEffect(() => {
        GetMatchesByUserId()
    }, [id])

    useEffect(() => {
        GetMatchesOfUser()
    }, [])
    const GetMatchesByUserId = async () => {
        const res = await GetUserById(id)
        setIndividualMatches(res?.data?.data)
    }

    const GetMatchesOfUser = async () => {
        setDataLoading(true)
        const res = await GetMatches()
        setMatches(res?.data?.data)
        setPagination(res?.data?.pagination)
        setDataLoading(false)
    }
    const onPageChange = () => {
        setLoading(true)
        navigate('/find-match')
        setLoading(false)
    }


    return (
        <div className='static-pages member-profile-page'>
            <div className='introduction-banner'>
                <Container>
                    <Row>
                        <Col lg={9} xl={9}>
                            <div className="content">
                                <NavLink to="/find-match" className="d-flex align-item-center">
                                    <div className="circle me-3">
                                        <MdChevronLeft className='text-dark' />
                                    </div>
                                    Back to Find Match
                                </NavLink>
                                <h2 className='mt-4'>Member Profile</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="profile-card-wrapper">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <MemberProfileCard individualMatches={individualMatches} />
                        </Col>

                        <Col xs={12} xl={12}>
                            <div className="about-me">
                                <h3 className='mb-4'>About Me</h3>
                                <h4>
                                    {individualMatches?.about}
                                </h4>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="similar-profiles">
                                <h3 className=''>Similar Profiles</h3>
                            </div>
                        </Col>

                        {
                            dataloading ? <Loader /> :
                                matches?.map((item, index) => (
                                    <Col key={index} xs={12} sm={6} lg={4} xl={3} className='mb-50'>
                                        <MatchCard item={item} index={index} data={matches} setData={setMatches} />
                                    </Col>
                                ))
                        }
                        {
                            pagination?.total_documents > 10 ?
                                <Col xs={12}>
                                    {loading ? <Loader /> :
                                        <div className='d-flex justify-content-center mt-4'>
                                            <Button className="btn-solid btn-blue" onClick={onPageChange}>View More</Button>
                                        </div>
                                    }
                                </Col>
                                : ''
                        }
                    </Row>
                </Container>
            </div>

        </div >
    )
}

export default MemberProfilePage