import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowIcon, ArrowLeft } from "../../constants/svgs";
import { EmptyLocalStorage } from "../../services/localStorage/localStorage";
import { toast } from "react-toastify";
import { error, success } from "../../constants/msg";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../services/auth/auth";

const ThemeModal = ({
  btnText,
  cancelText,
  heading,
  Text,
  initialState,
  setState,
  onSubmitModal,
  size,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      className="theme-popup logout-popup"
      show={initialState}
      onHide={() => {
        setState(false)
        navigate('/')
      }}
      size={size}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div>
          <div className="header text-center">
            <h2>{heading}</h2>
            <p className="mt-2">{Text}</p>
          </div>

          <div className="mt-30 d-flex align-items-center justify-content-center ">
            {cancelText ? (
              <Button
                className="btn-outline btn-dark"
                onClick={() => {
                  setState(false)
                  navigate('/')
                }
                }
              >
                <div className="circle">
                  <ArrowLeft />
                </div>
                {cancelText}
              </Button>
            ) : (
              ""
            )}

            <Button onClick={onSubmitModal} className="btn-solid btn-blue ms-4">
              {btnText}
              <div className="circle">
                <ArrowIcon />
              </div>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ThemeModal;
