import React, { forwardRef } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'

const CustomInput = forwardRef((props, ref) => {
    const {
        field,
        label = "",
        placeholder = "",
        type = "text",
        required = false,
        minLength = 0,
        maxLength = 255,
        value = "",
        onChange,
        disabled = false
    } = props

    return (
        <FloatingLabel label={label} className=''>
            <Form.Control
                {...field}
                type={type}
                placeholder={placeholder}
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                disabled={disabled}
                onChange={(value) => onChange(value)}
            />
        </FloatingLabel>
    )
})

export default CustomInput