import React from 'react'
import { Carousel, Modal } from "react-bootstrap"
import Assets from '../../constants/images'
import { constant } from '../../utils/constants'

const ImagePopup = ({ imagePopup, setImagePopup, ...props }) => {
    const defaultImages = props?.defaultData

    return (
        <Modal
            className='theme-popup image-popup'
            show={imagePopup}
            onHide={() => setImagePopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div>
                    <Carousel fade>
                        {defaultImages?.length > 0 ?
                            defaultImages.map((item, index) => { // Corrected the order of parameters
                                return (
                                    <Carousel.Item key={index}>
                                        <img className='w-100' src={`${constant.BASE_URL}/${item}`} onError={(e) => e.target.src = Assets.GeneralPlaceholderImg} alt="" />
                                    </Carousel.Item>
                                );
                            })
                            : ''}
                    </Carousel>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImagePopup