import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ArrowProfileIcon } from '../../constants/svgs'
import LogoutPopup from '../popup/LogoutPopup'
import DeleteConfirmPopup from '../popup/DeleteConfirmPopup'
import { EmptyLocalStorage, GetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { DeleteUserById } from '../../services/userProfile'
import { error, success } from '../../constants/msg'

const SettingSidebar = () => {
    const [logoutPopup, setLogoutPopup] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const navigate = useNavigate()
    const user_id = GetAuthUserLocalStorage()?._id
    const DeleteAcc = async () => {
        try {
            await DeleteUserById(user_id)
            navigate('/')
            EmptyLocalStorage()
            setDeletePopup(false)
            success("Suceesfully Deleted!")
        }
        catch (e) {
            error(e?.response?.data?.message)
        }
    }
    return (
        <>
            <div className='setting-sidebar'>
                <ul>
                    <li className='d-flex align-items-center justify-content-between my-3 pt-3'>
                        <NavLink to="/setting/my-profile">My Profile</NavLink>
                        <ArrowProfileIcon />
                    </li>

                    <li className='d-flex align-items-center justify-content-between my-3 pt-3'>
                        <NavLink to="/setting/edit-profile">Edit Profile</NavLink>
                        <ArrowProfileIcon />
                    </li>

                    <li className='d-flex align-items-center justify-content-between my-3 pt-3'>
                        <NavLink to="/setting/my-matches">My Matches</NavLink>
                        <ArrowProfileIcon />
                    </li>

                    <li className='d-flex align-items-center justify-content-between my-3 pt-3'>
                        <NavLink to="/setting/my-messages">My Messages</NavLink>
                        <ArrowProfileIcon />
                    </li>

                    <li className='d-flex align-items-center justify-content-between my-3 pt-3'>
                        <NavLink to="/setting/my-membership">Your Membership</NavLink>
                        <ArrowProfileIcon />
                    </li>

                    <li className='d-flex align-items-center justify-content-between my-3 pt-3'>
                        <NavLink to="/setting/notifications">Notifications</NavLink>
                        <ArrowProfileIcon />
                    </li>

                    <li className='d-flex align-items-center justify-content-between my-3 py-3' onClick={() => setLogoutPopup(true)}>
                        <span>Log Out</span>
                        <ArrowProfileIcon />
                    </li>

                    <li className='d-flex align-items-center justify-content-between my-3 py-3' onClick={() => setDeletePopup(true)}>
                        <span
                            style={{
                                color: 'red'
                            }}
                        >Delete Account</span>
                        <ArrowProfileIcon />
                    </li>

                </ul>
            </div>

            <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />
            <DeleteConfirmPopup deleteConfirmPopup={deletePopup} setDeleteConfirmPopup={setDeletePopup} handleDelete={DeleteAcc} txtFirst={"Delete Account"} txtSecond={"Are you sure you want to Delete Account?"}
                cnfrmtxt={"Delete"}

            />
        </>
    )
}

export default SettingSidebar