import React from 'react'
import ThemeNavbar from '../../components/navbar/ThemeNavbar'
import { Col, Container, Row } from 'react-bootstrap'
import Assets from '../../constants/images'
import { UserDarkIcon } from '../../constants/svgs'

const AuthLayout = ({ children }) => {
    return (
        <div className='auth-layout'>
            <ThemeNavbar logo={Assets.AuthLogo} layout="auth" userIcon={<UserDarkIcon />} />

            <div className="mt-50">
                <Container>
                    <Row>
                        <Col md={9} lg={5} xl={4} className='my-auto'>
                            {children}
                        </Col>

                        <Col className='d-none d-lg-block' lg={{ span: 5, offset: 2 }} xl={{ span: 5, offset: 3 }}>
                            <div>
                                <img className='w-100' src={Assets.AuthImg} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AuthLayout