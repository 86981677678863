import api from ".";
import { apiUrl, constant } from "../utils/constants";

export const getUserProjects = (params) => api.get(`${constant.VER}/${apiUrl.project}`, { params })

export const deleteProgram = (id, params) => api.delete(`${constant.VER}/${apiUrl.project}/${id}`, { params })

export const createProject = (data) => api.post(`${constant.VER}/${apiUrl.project}`, data)
export const updateProject = (id, data) => api.put(`${constant.VER}/${apiUrl.project}/${id}`, data)

export const getProjectById = (id, params) => api.get(`${constant.VER}/${apiUrl.project}/${id}`, { params })

export const sendInvitation = (data) => api.post(`${constant.VER}/${apiUrl.send_invitation}`, data)

// export const SendRequest = (data) => api.post(`${constant.VER}${apiUrl.message}/send-request`, data)



// export const updateProfile = (id, data) => api.put(`${constant.VER}${apiUrl.user}/${id}`, data)
