import React, { forwardRef } from 'react'
import { Form } from 'react-bootstrap'

const CustomTextarea = forwardRef((props, ref) => {
    const {
        field,
        label = "",
        placeholder = "",
        required = false,
        minLength = 0,
        maxLength = 255,
        value = "",
        onChange,
        disabled = false
    } = props

    return (
        <Form.Group className="custom-textarea">
            <Form.Control
                {...field}
                as="textarea"
                placeholder={placeholder}
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </Form.Group>
    )
})

export default CustomTextarea