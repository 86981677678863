import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ArrowIcon, ArrowLeft, UploadIconBlue } from "../../constants/svgs";
import WeddingCard from "./WeddingCard";
import Assets from "../../constants/images";
import { createImagePreview } from "../../utils/helper";
import { uploadImageAPI } from "../../services/uploadimage";
import { constant } from "../../utils/constants";
import { weddingFileContexts } from "../../App";
import WeddingCardInvatation from "../Invitation/Invatation";

const WeddingCardForm = ({
  watch,
  control,
  formState,
  setValue,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { errors, isDirty, isValid, isSubmitting } = formState;
  const [weddingTemplates] = watch(["weddingTemplates"]);
  const [weddingFile, setWeddingFile] = useContext(weddingFileContexts);
  const [selectedWeddingTemplates, setSelectedWeddingTemplates] = useState([]);
  const [weddingTemplatesList, setWeddingTemplatesList] = useState([
    {
      id: 1,
      image: Assets.WeddingCard,
      title: "Wedding Card Title",
      isChecked: false,
    },
    {
      id: 2,
      image: Assets.WeddingCard,
      title: "Wedding Card Title",
      isChecked: true,
    },
  ]);

  const handleFileUpload = async (e) => {
    const imageFile = e.target.files[0];
    const formData = new FormData();
    const key = `image`;
    formData.append(key, imageFile);
    const response = await uploadImageAPI(formData);
    const makeURL = constant?.BASE_URL + "/" + response?.data?.data?.filename;
    setWeddingFile(makeURL);
  };

  const handleCardSelect = (data) => {
    let tempWeddingTemplates = [...selectedWeddingTemplates];
    const index = tempWeddingTemplates.findIndex(
      (template) => template?.id == data?.id
    );

    if (index != -1) {
      tempWeddingTemplates.splice(index, 1);
      setSelectedWeddingTemplates(tempWeddingTemplates);
    } else {
      tempWeddingTemplates.push(data);
      setSelectedWeddingTemplates(tempWeddingTemplates);
    }
  };

  useEffect(() => {
    setValue("weddingTemplates", selectedWeddingTemplates);
  }, [selectedWeddingTemplates]);

  useEffect(() => {
    if (weddingTemplates?.length > 0) {
      setSelectedWeddingTemplates(weddingTemplates);
    }
  }, []);

  const UploadImage = async (Imagefile) => {
    const formData = new FormData();
    const key = `image`;
    formData.append(key, Imagefile);
    const response = await uploadImageAPI(formData);
  };
  return (
    <div className="wedding-card-form">
      <Row>
        <Col xs={12} xl={11}>
          <h3 className="fw-800 border-bottom pb-3 mb-4 color-blue">
            Ceremony Card Templates
          </h3>
        </Col>

        <Col xs={12} xl={11}>
          <Row>
            <Col xs={12} sm={6} lg={4} xl={6} className="mb-4">
              {/* <label className="image-upload" htmlFor="image">
                <input
                  className="d-none"
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleFileUpload}
                />
                <div className="text-center h-100 d-flex justify-content-center align-items-center flex-column">
                  <UploadIconBlue />
                  <h6 className="mt-3">Browse image to upload</h6>
                </div>
              </label> */}
            </Col>
            <Col xs={12} sm={6} lg={4} xl={6} className="mb-4">
              {/* <div>
                {weddingFile && (
                  <img
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={weddingFile}
                    alt="Uploaded File"
                  />
                )}
              </div> */}
            </Col>

            {/* {weddingTemplatesList?.map((item, index) => (
              <Col xs={12} className="mb-4" key={index}>
                <WeddingCardInvatation
                  data={item}
                  selectedWeddingTemplates={selectedWeddingTemplates}
                  handleChange={handleCardSelect}
                />
              </Col>
            ))} */}

            <Col xs={12} className="mb-4">
              <WeddingCardInvatation
                watch={watch}
                control={control}
                formState={formState}
                setValue={setValue}
              />
            </Col>
          </Row>

          {/* <WeddingCard /> */}
          {/* NEXT STEP BUTTON */}
          <div className="mt-5 d-flex flex-column flex-sm-row align-items-sm-center ">
            <Button
              onClick={handlePreviousStep}
              className="btn-outline btn-dark"
              type="button"
            >
              <div className="circle">
                <ArrowLeft />
              </div>
              PREVIOUS STEP
            </Button>

            <Button
              onClick={handleNextStep}
              className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue"
              type="submit"
              disabled={!isDirty || !isValid}
            >
              NEXT STEP
              <div className="circle">
                <ArrowIcon />
              </div>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WeddingCardForm;
