import React, { useContext, useState } from "react";
import Assets from "../../constants/images";
import { LockIcon } from "../../constants/svgs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { constant } from "../../utils/constants";
import { addFav } from "../../services/matches";
import { success, warning } from "../../constants/msg";
import { reloadMatchesContext, showWarningModalContext } from "../../App";
import ThemeModal from "../popup/ThemeModa";
import WarningModal from "../popup/Warning";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";

const MatchCard = ({ matchLoading, item, index, data, setData }) => {
  const [reloadMatches, setReloadMatches] = useContext(reloadMatchesContext);
  const navigate = useNavigate()
  const param = window.location.href;
  const paramSplit = param.split("/");
  const pageName = paramSplit[paramSplit.length - 1];
  const [showModal, setShowModal] = useState(false);
  const user = GetAuthUserLocalStorage()
  console.log(user, 'yser local wala');

  const handleFavourite = async () => {
    await addFav({
      user_id: item?._id,
    });
    setReloadMatches(true);
    {
      pageName === "my-matches"
        ? warning(
          `${item?.first_name + " " + item?.last_name
          } removed from Favourites`
        )
        : success(
          `${item?.first_name + " " + item?.last_name} add in your Favourites`
        );
    }
  };
  const checkPlan = () => {
    if (user?.subscription_plan?.plan_name === "Basic") {
      setShowModal(true)
    }
    else {
      navigate(`/member-profile/${item?._id}`)
    }
  }
  return (
    <div className="match-card" onClick={checkPlan}>
      <NavLink>
        <div className="img-wrapper">
          <img
            src={
              item?.images && item?.images?.length > 0
                ? `${constant.BASE_URL}/${item?.images[0]}`
                : Assets.GeneralPlaceholderImg
            }
            alt=""
            onError={(e) => (e.target.src = Assets.GeneralPlaceholderImg)}
          />
          {item?.lock && (
            <div className="circle">
              <LockIcon />
            </div>
          )}
        </div>
      </NavLink>
      <div className="content mt-2">
        <div className="d-flex align-items-center justify-content-between">
          <h3>
            <b>{item?.first_name + " " + item?.last_name}</b>
          </h3>
          <div className="checkbox" onClick={handleFavourite}>
            {pageName === "my-matches" ? (
              <IoMdHeart className="icon text-danger" />
            ) : item?.is_liked ? (
              <IoMdHeart className="icon text-danger" />
            ) : (
              <IoMdHeartEmpty className="icon color-grey" />
            )}
          </div>
        </div>
        <h4 className="my-2">
          {item?.age} - {item?.country}
        </h4>
      </div>
      <WarningModal setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default MatchCard;
