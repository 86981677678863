import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ArrowDownIcon, ArrowIconBlack, ArrowTop } from "../../constants/svgs";
import { NavLink, useNavigate } from "react-router-dom";

const Services = () => {
  const [activeOne, setActiveOne] = useState(false);
  const [activeTwo, setActiveTwo] = useState(false);
  const navigate = useNavigate();

  const handleActiveOne = () => {
    if (activeOne) {
      setActiveOne(false);
    } else {
      setActiveOne(true);
    }
  };

  const handleActiveTwo = () => {
    if (activeTwo) {
      setActiveTwo(false);
    } else {
      setActiveTwo(true);
    }
  };

  return (
    <div className="services">
      <Container className="h-100">
        <Row className="h-100">
          <Col lg={5} className="">
            <h4 className="lh-0-ls-5">SERVICE</h4>
            <h1 className="mt-3">
              Your Personalized Wedding Ceremonies Coordination Hub
            </h1>
            <h4 className="mt-4">
              Crafting your ideal wedding has become more effortless than ever.
              Immerse yourself in our array of exclusive, state-of-the-art
              utilities tailored to streamline and elevate every step of your
              wedding adventure. From user-friendly budget management tools and
              interactive to-do lists to customized vendor connections and
              dynamic seating arrangements, we&#39;ve assembled a selection of
              pioneering resources that empower you to orchestrate your wedding
              experience exactly as you envision.
            </h4>

            <Button
              className="btn-outline btn-dark text-uppercase mt-4"
              type="button"
              onClick={() => navigate("/")}
              // href="/"
            >
              <div className="circle">
                <ArrowIconBlack />
              </div>
              VIEW ALL SERVICES
            </Button>
          </Col>

          <Col lg={{ span: 6, offset: 1 }} className="mt-4 mt-lg-0">
            <Row>
              <Col xs={12} className="mb-4">
                <div
                  className={`box d-flex flex-column flex-md-row`}
                  onClick={handleActiveOne}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <h3 className="mb-3 mb-md-0">01</h3>
                  <div className="px-md-4">
                    <h3>Find Your Match</h3>
                    <p className="mt-3">
                      Whether you're a seasoned event organizer or a couple
                      preparing for your special day, our top-notch wedding
                      tools are designed to save you valuable time and effort.
                    </p>

                    <div
                      className={`border-top links mt-4 ${
                        activeOne && "active"
                      }`}
                    >
                      <Row>
                        <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to="/setting/my-matches"
                            className="text-uppercase border-bottom pb-4"
                          >
                            My Matches
                          </NavLink>
                        </Col>

                        <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to="/find-match"
                            className="text-uppercase border-bottom pb-4"
                          >
                            Find Your Match
                          </NavLink>
                        </Col>

                        {/* <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to=""
                            className="text-uppercase border-bottom pb-4"
                          >
                            Wedding Guest List
                          </NavLink>
                        </Col>

                        <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to=""
                            className="text-uppercase border-bottom pb-4"
                          >
                            Wedding Guest List
                          </NavLink>
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                  <div
                    className={`circle ${activeOne && "active"} mt-3 mt-md-0`}
                  >
                    {activeOne ? <ArrowTop /> : <ArrowDownIcon />}
                  </div>
                </div>
              </Col>

              <Col xs={12}>
                <div
                  className={`box d-flex flex-column flex-md-row`}
                  onClick={handleActiveTwo}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <h3 className="mb-3 mb-md-0">02</h3>
                  <div className="px-md-4">
                    <h3>Wedding Ceremony Planner</h3>
                    <p className="mt-3">
                      The most exceptional weddings are crafted collaboratively.
                      Utilize our Online Wedding Planning Assistant to involve
                      your loved ones in every step of the journey.
                    </p>

                    <div
                      className={`border-top links mt-4 ${
                        activeTwo && "active"
                      }`}
                    >
                      <Row>
                        <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to="/create-new-project"
                            className="text-uppercase border-bottom pb-4"
                          >
                            Create Ceremony Project
                          </NavLink>
                        </Col>

                        <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to="/my-wedding-projects"
                            className="text-uppercase border-bottom pb-4"
                          >
                            Your Projects
                          </NavLink>
                        </Col>

                        {/* <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to=""
                            className="text-uppercase border-bottom pb-4"
                          >
                            Wedding Guest List
                          </NavLink>
                        </Col>

                        <Col xs={12} sm={6} xl={6} className="mb-4 pt-4">
                          <NavLink
                            to=""
                            className="text-uppercase border-bottom pb-4"
                          >
                            Wedding Guest List
                          </NavLink>
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                  <div
                    className={`circle ${activeTwo && "active"} mt-3 mt-md-0`}
                  >
                    {activeTwo ? <ArrowTop /> : <ArrowDownIcon />}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
