import React, { useContext, useEffect, useState } from 'react'
import SettingHeader from '../components/static/SettingHeader'
import { Col, Container, Row } from 'react-bootstrap'
import { HiPlus } from "react-icons/hi";
import ProjectBox from '../components/static/ProjectBox';
import { NavLink } from 'react-router-dom';
import { getUserProjects } from '../services/projects';
import { GetAuthUserLocalStorage } from '../services/localStorage/localStorage';
import { weddingLoadingContext } from '../App';

const MyWeddingProjects = () => {
    const user = GetAuthUserLocalStorage()
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useContext(weddingLoadingContext);

    useEffect(() => {
        GetUserProjects()
    }, [])

    const GetUserProjects = async () => {
        const params = {
            created_by: user?._id
        }
        const response = await getUserProjects(params)
        setProjects(response?.data?.data)
    }
    return (
        <div className='static-pages my-wedding-page'>
            <div className="d-none d-md-block">
                <SettingHeader title="Create New Wedding Project" />
            </div>

            <div className="main-wrapper">
                <Container>
                    <Row>
                        <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='mb-3'>
                            <NavLink to="/create-new-project" className='mt-4'>
                                <div className="add-box p-3">
                                    <HiPlus size={26} />
                                    <h6 className='mt-3'>CREATE NEW PROJECT</h6>
                                </div>
                            </NavLink>
                        </Col>
                        {
                            projects?.map((dt) => {
                                return (
                                    <>
                                        <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='mb-3'>
                                            <ProjectBox projects={dt} GetProjects={GetUserProjects} />
                                        </Col>

                                    </>
                                )
                            })
                        }

                        {/* <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='mb-3'>
                            <ProjectBox />
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default MyWeddingProjects