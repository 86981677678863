import _ from 'lodash';
import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { GetAuthUserLocalStorage } from '../../services/localStorage/localStorage';

const CustomCircularProgressbar = () => {
    const user = GetAuthUserLocalStorage()

    return (
        <div className='custom-circular-progressbar'>
            <CircularProgressbarWithChildren
                value={user?.isProfileCompleted ? 100 : 30}
                text={`${user?.isProfileCompleted ? 100 : 30}%`}
                strokeWidth={10}
                styles={buildStyles({
                    strokeLinecap: "butt"
                })}
            >
                <RadialSeparators
                    count={12}
                    style={{
                        background: "#fff",
                        width: "2px",
                        height: `${10}%`
                    }}
                />
            </CircularProgressbarWithChildren>
        </div>
    )
}

function RadialSeparators(props) {
    const turns = 1 / props.count;
    return _.range(props.count).map(index => (
        <div
            style={{
                position: "absolute",
                height: "100%",
                transform: `rotate(${index * turns}turn)`
            }}
        >
            <div style={props.style} />
        </div>
    ));
}

export default CustomCircularProgressbar