import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

export const constant = {
    DEVICE_TYPE: "web",
    DEVICE_TOKEN: "",
    LOCAL_STORAGE_TOKEN: "foodnauts-token",
    LOCAL_STORAGE_USER: "foodnauts-user",
    LOCAL_STORAGE_ROLE: "foodnauts-role",
    LOCAL_STORAGE_EMAIL_SIGNUP: "signup-email",
    BASE_URL: "http://142.93.253.213:3000/",
    // BASE_URL: "http://backend.rishteyandshadi.com:5000",
    IMAGE_DIR_NAME: "images",
    VIDEO_DIR_NAME: "videos",
    VER: "/api",
    auth: "/auth",
}

export const messageTypes = {
    text: 1,
    image: 2,
    video: 3,
    audio: 4,
    doc: 5,
}

export const apiUrl = {
    login: '/sign-in',
    signup: '/register',
    verify_otp: '/verify-otp',
    resend_otp: '/resend-otp',
    forget_password: '/forgot-password',
    reset_password: '/reset-password',
    logout: "/logout",
    contact_us: '/contact-us',
    // dropdown
    dropdown: '/general/dropdown',
    // createProfile
    user: '/user',
    message: '/message',
    createProfile: '/complete-profile',
    upload_image: '/general/image-upload',
    // 
    matches: '/matches',
    // addtofav
    addtofav: '/add-to-favourites',
    // fav matches
    mymatches: '/get-favourite-profiles',
    project: 'project',
    vendors: 'vendors',
    send_invitation: "/project/send-invitation",
    // payment
    payment: '/payment',
    payment_subscribe_plan: '/payment/subscribe-plan',
    addCard: '/add-card',
    subscriptionPlan: '/subscription-plan',

}

export const s3Credential = {
    bucketName: "",
    region: "",
    accessKeyId: "",
    secretAccessKey: "",
    s3EndPoint: "",
    fileSize: "",
    dirName: "",
}

export const errorMessages = {
    fileSize: "The file size is too large",
    fileSuccess: "file uploaded successfully",
    fileError: "Error in uploading file",
}


export const currencyOptions = [
    { label: "united states of america (USD)", value: "usd" },
    { label: "Pakistan (PKR)", value: "pkr" },
    { label: "united arab emirates (AED)", value: "aed" },
    { label: "India (INR)", value: "inr" },
]

  // To download as PDF
const exportPDF = async (index) => {
    const input = document.getElementById(`pdf-content-${index}`);
    if (!input) return; // Exit if the element doesn't exist

    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width * 0.2645833333; // convert pixels to mm
    const imgHeight = canvas.height * 0.2645833333; // convert pixels to mm

    // If the image is too tall for the page, we can scale it down
    const scale = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    // Add the image to the PDF centered
    pdf.addImage(imgData, 'PNG', (pdfWidth - imgWidth * scale) / 2, (pdfHeight - imgHeight * scale) / 2, imgWidth * scale, imgHeight * scale);
    pdf.save(`download-${index}.pdf`);
  };