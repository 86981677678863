import React from 'react'
import { Controller } from 'react-hook-form'
import CustomInput from '../form/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { Button, Col, Row } from 'react-bootstrap'
import { ArrowIcon } from '../../constants/svgs'

const PersonalInformationForm = ({ watch, control, formState, setValue, handleNextStep, handlePreviousStep }) => {
    const { errors, isDirty, isValid, isSubmitting, trigger } = formState
    console.log(isValid, isDirty, errors, 'arqam');
    return (
        <div className='personal-information-form'>
            <Row>
                <Col xs={12} xl={11}>
                    <h3 className='fw-800 border-bottom pb-3 mb-4 color-blue'>Enter Your Personal Information</h3>
                </Col>

                <Col xs={12} xl={11}>
                    {/* BRIDE NAME INPUT */}
                    <div className="mb-25">
                        <Controller
                            control={control}
                            name="brideName"
                            rules={{
                                minLength: {
                                    value: VALIDATIONS.NAME_MIN,
                                    message: VALIDATIONS_TEXT.NAME_MIN
                                },
                                maxLength: {
                                    value: 21,
                                    message: VALIDATIONS_TEXT.NAME_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                },
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Enter Bride's Name"
                                    placeholder="Enter Bride's Name"
                                    type="text"
                                    minLength={VALIDATIONS.NAME_MIN}
                                    maxLength={21}
                                    required={true}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.brideName && <small className='text-start d-block text-danger mt-1'>{errors.brideName.message}</small>}
                    </div>

                    {/* GROOM NAME INPUT */}
                    <div className="mb-25">
                        <Controller
                            control={control}
                            name="groomName"
                            rules={{
                                minLength: {
                                    value: VALIDATIONS.NAME_MIN,
                                    message: VALIDATIONS_TEXT.NAME_MIN
                                },
                                maxLength: {
                                    value: 21,
                                    message: VALIDATIONS_TEXT.NAME_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                },
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Enter Groom's Name"
                                    placeholder="Enter Groom's Name"
                                    type="text"
                                    minLength={VALIDATIONS.NAME_MIN}
                                    maxLength={21}
                                    required={true}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.groomName && <small className='text-start d-block text-danger mt-1'>{errors.groomName.message}</small>}

                    </div>

                    {/* NEXT STEP BUTTON */}
                    <div className='mt-5'>
                        <Button onClick={handleNextStep} className="btn-solid btn-blue" type="submit" disabled={!isDirty || !isValid}>
                            NEXT STEP
                            <div className="circle">
                                <ArrowIcon />
                            </div>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PersonalInformationForm