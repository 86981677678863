import api from ".";
import { apiUrl, constant } from "../utils/constants";

export const createProfile = (data) => api.post(`${constant.VER}${apiUrl.user}${apiUrl.createProfile}`, data)

export const GetUserById = (id, params) => api.get(`${constant.VER}${apiUrl.user}/${id}`, { params })

export const SendRequest = (data) => api.post(`${constant.VER}${apiUrl.message}/send-request`, data)

export const DeleteUserById = (id, params) => api.delete(`${constant.VER}${apiUrl.user}/${id}`, { params })


export const updateProfile = (id, data) => api.put(`${constant.VER}${apiUrl.user}/${id}`, data)
