import React from 'react'
import ChatImg from '../../assets/img/chat.png'

const NoChatSelected = () => {
    return (
        <div className="no-chat-selected h-100">
            <div className="animation-div">
                <img src={ChatImg} alt="" />
                <h4 className='fw-800'>Select a chat to start converstaion</h4>
            </div>
        </div>
    )
}

export default NoChatSelected