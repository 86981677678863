import React from 'react'
import { messageTypes } from '../../utils/constants'
import Assets from '../../constants/images'
import { MdDownloadForOffline } from "react-icons/md"
import moment from 'moment'
import { constant } from '../../utils/constants'

const MessageBox = ({ message }) => {
    const getMessage = {
        [messageTypes.text]: <TextMessage message={message?.message} time={message?.createdAt} />,
        [messageTypes.image]: <ImageMessage message={message?.message} image={message?.file}
            time={message?.createdAt} />,
        [messageTypes.video]: <VideoMessage video={message?.file} time={message?.createdAt} />,
        [messageTypes.audio]: <AudioMessage audio={message?.file} time={message?.createdAt} />,
        [messageTypes.doc]: <DocMessage message={message?.message} doc={message?.file} time={message?.createdAt} />,
    }

    if (message.message_type === "text") {
        return (<>{getMessage[messageTypes.text]}</>)
    } else if (message.message_type === "file") {
        if (message?.file[0]?.type === "image") return (<>{getMessage[messageTypes.image]}</>)
        if (message?.file[0]?.type === "application") return (<>{getMessage[messageTypes.doc]}</>)
        if (message?.file[0]?.type === "video") return (<>{getMessage[messageTypes.video]}</>)
        if (message?.file[0]?.type === "audio") return (<>{getMessage[messageTypes.audio]}</>)
    }


}

const TextMessage = ({ message, time }) => {
    return (
        <div className="text-message">
            <div className='message-box'>
                <p>{message}</p>
            </div>
            <small className='color-grey mt-1'>{moment(Date(time)).format('hh:mm a')}</small>
        </div>
    )
}

const ImageMessage = ({ message, image, time }) => {
    return (
        <div className="image-message">
            <div className="message-box">
                {image?.map(dt => {
                    if (dt?.type === "image") {
                        return (
                            <div className="img-wrapper mb-3">
                                <img src={`${constant.BASE_URL}/${dt?.url}`}
                                    onError={(e) => e.target.src = Assets.GeneralPlaceholder} alt="" />
                            </div>)
                    } else if (dt?.type === "application") {
                        return <DocFile name={dt?.url} />
                    }
                })}
                <p className='mt-2'>{message}</p>
            </div>
            <small className='color-grey mt-1'>{moment(Date(time)).format('hh:mm a')}</small>
        </div>
    )
}

const AudioMessage = ({ audio, time }) => {
    return (
        <div className="audio-message">
            <audio src={`${constant.BASE_URL}/${audio[0]?.url}`} controls></audio>
            <small className='color-grey mt-1'>{moment(Date(time)).format('hh:mm a')}</small>
        </div>
    )
}

const VideoMessage = ({ video, time }) => {
    return (
        <div className="video-message">
            <div className="message-box">
                <video controls>
                    <source src={`${constant.BASE_URL}/${video[0]?.url}`} />
                </video>
            </div>
            <small className='color-grey mt-1'>{moment(Date(time)).format('hh:mm a')}</small>
        </div>
    )
}

const DocMessage = ({ doc, time }) => {
    return (
        <div className="doc-message">
            <div className='message-box'>
                <div className='d-flex align-items-center'>
                    <div className='download-wrapper me-2'>
                        <MdDownloadForOffline className='download-icon' />
                    </div>
                    <span><a href={`${constant.BASE_URL}/${doc[0]?.url}`}>{doc[0]?.url}</a></span>
                </div>
            </div>
            <small className='color-grey mt-1'>{moment(Date(time)).format('hh:mm a')}</small>
        </div>
    )
}

const DocFile = ({ name }) => {
    return (
        <div className="doc-message">
            <div className='message-box'>
                <div className='d-flex align-items-center'>
                    <div className='download-wrapper me-2'>
                        <MdDownloadForOffline className='download-icon' />
                    </div>
                    <span>{name}</span>
                </div>
            </div>
        </div>
    )
}

export default MessageBox