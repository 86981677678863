import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { addToCard } from "../../services/payment";
import { useState } from "react";
import { success } from "../../constants/msg";
import { useNavigate } from "react-router-dom";

const PaymentFormModal = ({
  paymentPopup,
  setPaymentProcess,
  stripePromise,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });
  const [disabled, setDisabled] = useState(false);
  const onSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (error) {
      error(error);
    } else {
      const tokenId = token.id;
      const response = await addToCard({ cardTokenId: tokenId });
      success(response?.data?.message);
      setPaymentProcess(false);
      navigate(`/payment/plan/${token?.card?.id}`);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="form-payment">
      <Row>
        <Col xs={12}>
          <CardElement options={{ hidePostalCode: true }} />
        </Col>
        <Col xs={12}>
          <Button
            type="submit"
            disabled={!stripe}
            className="btn-solid btn-blue payment-button mt-4 ms-4 ms-auto"
          >
            SUBMIT
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentFormModal;
