import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import CustomInput from "../form/CustomInput";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../../constants/app-constants";
import { Button, Col, Row } from "react-bootstrap";
import { ArrowIcon, ArrowLeft, DeleteIcon } from "../../constants/svgs";
import { IoMdAdd } from "react-icons/io";
import CustomSelect from "../form/CustomSelect";

const RsvpandGreetingForm = ({
  watch,
  control,
  formState,
  setValue,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { errors, isDirty, isValid, isSubmitting } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "rsvp" });
  const [
    brideName,
    groomName,
    budget,
    programs,
    guestscounts,
    vendors,
    eventPlanning,
    guests,
    collaboratedTeam,
    currency,
    religion,
    rsvp,
    weddingTemplates,
  ] = watch([
    "brideName",
    "groomName",
    "budget",
    "programs",
    "guestscounts",
    "vendors",
    "eventPlanning",
    "guests",
    "collaboratedTeam",
    "currency",
    "religion",
    "rsvp",
    "weddingTemplates",
  ]);

  const [programCount, setProgramCount] = useState([]);
  useEffect(() => {
    const res =
      programs?.length > 0
        ? programs?.map((dt, index) => ({
          label: dt?.name,
          value: index++,
        }))
        : "";

    setProgramCount(res);
  }, []);

  // useEffect(() => {
  //   const defaultrsvp =
  //     programs?.length > 0
  //       ? programs?.map((dt, index) => ({
  //         name: "",
  //         email: "",
  //         cell: "",
  //         programname: {
  //           label: dt?.name,
  //           value: dt?.name,
  //         },
  //       }))
  //       : "";
  //   const duplicatedRsvp = [...defaultrsvp, ...defaultrsvp];

  //   setValue("rsvp", duplicatedRsvp);
  // }, [programs]);

  const addRsvp = () => {
    append({ programname: "", name: "", email: "", cell: "" });
  };
  return (
    <div className="guest-list-form">
      <Row>
        <Col xs={12} xl={11}>
          <h3 className="fw-800 border-bottom pb-3 mb-4 color-blue">RSVP Details</h3>
        </Col>

        <Col xs={12} xl={11}>
          <div className="mb-25 d-flex align-items-center justify-content-between">
            <div className=' d-flex align-items-center'>
              <div className={`add-cirlce ${rsvp?.length > 0
                ? rsvp?.length === programs?.length * 2
                  ? "d-none"
                  : ""
                : ""
                }`} onClick={addRsvp}>
                <IoMdAdd size={22} />
              </div>
              <h6 className='ms-3 color-dark'>ADD Member</h6>
            </div>

            <div className=' d-flex align-items-center'>
              <div className="guest-cirlce">
                <h6>{fields?.length}</h6>
              </div>
              <h6 className='ms-3 color-dark'>Members Added</h6>
            </div>
          </div>

          <Row className={`mb-25 ${fields?.length > 0 ? "d-flex" : "d-none"}`}>
            <Col xs={12}>
              <span className="d-block w-100 border-bottom pb-3 mb-3">
                <b>DETAILS</b>
              </span>
            </Col>
          </Row>

          <Row>
            {fields.map((guest, index) => (
              <React.Fragment>
                {/* SELECT EVENT */}
                <Col xs={6} key={index}>
                  <div className="mb-25">
                    <Controller
                      control={control}
                      name={`rsvp[${index}].programname`}
                      rules={{
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.GENDER_REQUIRED,
                        },
                      }}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          label="Program"
                          placeholder="Program"
                          options={programCount}
                          required={true}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e); // Invoke the field.onChange function and pass the selected value
                          }}
                        />
                      )}
                    />
                  </div>
                </Col>
                {/* NAME INPUT */}
                <Col xs={6} key={index}>
                  <div className="mb-25">
                    <Controller
                      control={control}
                      name={`rsvp[${index}].name`}
                      rules={{
                        minLength: {
                          value: VALIDATIONS.NAME_MIN,
                          message: VALIDATIONS_TEXT.NAME_MIN,
                        },
                        maxLength: {
                          value: VALIDATIONS.NAME_MAX,
                          message: VALIDATIONS_TEXT.NAME_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.NAME_REQUIRED,
                        },
                      }}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Name"
                          placeholder="Name"
                          type="text"
                          minLength={VALIDATIONS.NAME_MIN}
                          maxLength={VALIDATIONS.NAME_MAX}
                          required={true}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    {errors.rsvp &&
                      errors.rsvp[index] &&
                      errors.rsvp[index].fname && (
                        <small className="text-start d-block text-danger mt-1">
                          {errors.rsvp[index].fname.message}
                        </small>
                      )}
                  </div>
                </Col>
                {/* EMAIL INPUT */}
                <Col xs={6}>
                  <div className="mb-25">
                    <Controller
                      control={control}
                      name={`rsvp[${index}].email`}
                      rules={{
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                        },
                        maxLength: {
                          value: VALIDATIONS.EMAIL,
                          message: VALIDATIONS_TEXT.EMAIL,
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                        },
                      }}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Email Address"
                          placeholder="Email Address"
                          type="email"
                          required={true}
                          maxLength={VALIDATIONS.EMAIL}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    {errors.rsvp &&
                      errors.rsvp[index] &&
                      errors.rsvp[index].email && (
                        <small className="text-start d-block text-danger mt-1">
                          {errors.rsvp[index].email.message}
                        </small>
                      )}
                  </div>
                </Col>
                {/* CELL */}
                <Col xs={6}>
                  <div className="mb-25 d-flex">
                    <div>
                      <Controller
                        control={control}
                        name={`rsvp[${index}].cell`}
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATIONS_TEXT.FAMILY_COUNT_REQUIRED,
                          },
                          maxLength: {
                            value: 15,
                            message: 'Max length is 15'
                          }
                        }}
                        render={({ field }) => (
                          <CustomInput
                            {...field}
                            placeholder="Contact"
                            label="Contact"
                            // placeholder="Cell"
                            maxLength={15}
                            type="number"
                            required={true}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      {errors.rsvp &&
                        errors.rsvp[index] &&
                        errors.rsvp[index].cell && (
                          <small className="text-start d-block text-danger mt-1">
                            {errors.rsvp[index].cell.message}
                          </small>
                        )}
                    </div>

                    <div className="ms-3 mt-3" onClick={() => remove(index)}>
                      <DeleteIcon />
                    </div>
                  </div>
                </Col>
              </React.Fragment>
            ))}
          </Row>

          {/* NEXT STEP BUTTON */}
          <div className="mt-5 d-flex flex-column flex-sm-row align-items-sm-center ">
            <Button
              onClick={handlePreviousStep}
              className="btn-outline btn-dark"
              type="button"
            >
              <div className="circle">
                <ArrowLeft />
              </div>
              PREVIOUS STEP
            </Button>

            <Button
              onClick={handleNextStep}
              className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue"
              type="submit"
              disabled={!isDirty || !isValid || fields?.length == 0}
            >
              NEXT STEP
              <div className="circle">
                <ArrowIcon />
              </div>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RsvpandGreetingForm;
