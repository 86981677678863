import React, { createContext, useContext, useState } from 'react'
import { BrowserRouter } from "react-router-dom";
import Navigation from './navigation/Navigation';
import { ToastContainer } from 'react-toastify';
import { GetAuthUserLocalStorage } from './services/localStorage/localStorage';
import { create } from 'lodash';
import { SubscribeIcon } from './constants/svgs';

const fogotPassContext = createContext()
const uploadImageContext = createContext()
const uploadedImageContext = createContext()
const reloadMatchesContext = createContext()
const genderSelectedContext = createContext()
const balanceBudgetContext = createContext()
const weddingLoadingContext = createContext()
const weddingFileContexts = createContext()
const subscriptionPlanContext = createContext()
const subscribeplanDataContext = createContext()
const selectedPlanDetailsContext = createContext()
const showWarningModalContext = createContext();

const App = () => {
    const [forgotData, setForgotData] = useState([])
    const [uploadImage, setUploadImage] = useState([])
    const [uploadedImage, setUploadedImage] = useState([])
    const [reloadMatches, setReloadMatches] = useState(false)
    const [genderSelected, setGenderSelected] = useState('')
    const [balanceBudget, setBalanceBudget] = useState('')
    const [loading, setLoading] = useState(false)
    const [weddingFile, setWeddingFile] = useState(null)
    const [Subscribeplanname, setSubscribeplanname] = useState('')
    const [subscribeplanData, setSubscribeplanData] = useState([]);
    const [selectedPlanDetails, setSelectedPlanDetails] = useState();
    const [showModal, setShowModal] = useState(false);

    return (
        <fogotPassContext.Provider value={[forgotData, setForgotData]}>
            <uploadImageContext.Provider value={[uploadImage, setUploadImage]}>
                <uploadedImageContext.Provider value={[uploadedImage, setUploadedImage]}>
                    <reloadMatchesContext.Provider value={[reloadMatches, setReloadMatches]}>
                        <genderSelectedContext.Provider value={[genderSelected, setGenderSelected]}>
                            <balanceBudgetContext.Provider value={[balanceBudget, setBalanceBudget]}>
                                <weddingLoadingContext.Provider value={[loading, setLoading]}>
                                    <weddingFileContexts.Provider value={[weddingFile, setWeddingFile]}>
                                        <subscriptionPlanContext.Provider value={[Subscribeplanname, setSubscribeplanname]}>
                                            <subscribeplanDataContext.Provider value={[subscribeplanData, setSubscribeplanData]}>
                                                <selectedPlanDetailsContext.Provider value={[selectedPlanDetails, setSelectedPlanDetails]}>
                                                    <showWarningModalContext.Provider value={[showModal, setShowModal]}>
                                                        <BrowserRouter>
                                                            <ToastContainer />
                                                            <Navigation />
                                                        </BrowserRouter>
                                                    </showWarningModalContext.Provider>
                                                </selectedPlanDetailsContext.Provider>
                                            </subscribeplanDataContext.Provider>
                                        </subscriptionPlanContext.Provider>
                                    </weddingFileContexts.Provider>
                                </weddingLoadingContext.Provider>
                            </balanceBudgetContext.Provider>
                        </genderSelectedContext.Provider>
                    </reloadMatchesContext.Provider>
                </uploadedImageContext.Provider>
            </uploadImageContext.Provider>
        </fogotPassContext.Provider >
    )
}

export default App

export {
    fogotPassContext,
    uploadImageContext,
    uploadedImageContext,
    reloadMatchesContext,
    genderSelectedContext,
    balanceBudgetContext,
    weddingLoadingContext,
    weddingFileContexts,
    subscriptionPlanContext,
    subscribeplanDataContext,
    selectedPlanDetailsContext,
    showWarningModalContext,
}