import React, { useState } from 'react'
import SettingHeader from '../components/static/SettingHeader'
import { Col, Container, Row } from 'react-bootstrap'
import SettingSidebar from '../components/static/SettingSidebar'
import NotificationCard from '../components/static/NotificationCard'
import Assets from '../constants/images'

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([
        {
            title: "We released some new features",
            notification: "viewed your profile",
            time: "5 days ago",
            img: Assets.NotificationImg,
            status: "seen"
        },
        {
            title: "We released some new features",
            notification: "Don't forget to complete your profile, Add details about your wedding vision and preferences. This helps vendors tailor their services to your unique needs.",
            time: "5 days ago",
            img: Assets.Match12Img,
            status: "unseen",
        },
        {
            title: "We released some new features",
            notification: "viewed your profile",
            time: "5 days ago",
            img: Assets.NotificationImg,
            status: "seen"
        },
        {
            title: "We released some new features",
            notification: "viewed your profile",
            time: "5 days ago",
            img: Assets.NotificationImg,
            status: "seen"
        },
    ])

    return (
        <div className='static-pages notification-page'>
            <div className="d-none d-md-block">
                <SettingHeader />
            </div>

            <Container>
                <Row>
                    <Col md={3} lg={3} xl={3} className='d-none d-md-block'>
                        <SettingSidebar />
                    </Col>

                    <Col md={9} lg={9} xl={7}>
                        <div className="content py-5 py-md-5 ps-md-5">
                            <div className='mb-5 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between'>
                                <h2 className='color-blue fw-800'>Notifications</h2>
                                <div>
                                    <span><b>Brian Griffin</b> viewed your profile</span>
                                    <small className='d-block'>5 days ago</small>
                                </div>
                            </div>

                            <div className="notification-wrapper">
                                {
                                    notifications?.map((item, index) => (
                                        <NotificationCard data={item} key={index} />
                                    ))
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NotificationsPage