import React from "react";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { ShareIconGrey } from "../../constants/svgs";
import { Form, NavLink } from "react-bootstrap";
import Assets from "../../constants/images";
import { MdInsertLink } from "react-icons/md";

const VendorCard = ({ data, selectedVendors, handleChange }) => {
  return (
    <div className="vendor-card">
      <Form.Check
        className="custom-check"
        type="checkbox"
        onChange={(e) => handleChange(data)}
        id={`vendor-${data?._id}`}
        checked={selectedVendors?.some((item) => item?._id == data?._id)}
        label={
          <div className="vendor-card-box d-flex align-items-center justify-content-between">
            <div className="box d-flex align-items-center">
              <div>
                <img
                  style={{
                    borderRadius: "4px",
                    /* padding: 5px; */
                    width: "80px",
                    // width: "100%",
                    // height: "100%",
                    // borderRadius: "8px",
                    // objectFit: "cover",
                    // objectPosition: "center"
                  }}
                  src={
                    data?.image ? data?.image : Assets?.GeneralPlaceholderImg
                  }
                  alt=""
                />
                {/* src={Assets?.Match10Img} /> */}
              </div>

              <div className="ms-3">
                <h6>{data?.name}</h6>
                <span>{data?.description}</span>
              </div>
            </div>

            <div>
              <h6>${data?.price}</h6>
            </div>

            <div>
              {/* <span className='border-end d-inline-block me-3 pe-3'><MdInsertLink className='color-grey' size={22} /></span> */}
              <NavLink href={data?.link}>
                <span className="cursor"><MdInsertLink /></span>
              </NavLink>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default VendorCard;
