import React, { forwardRef } from 'react'
import { Form } from 'react-bootstrap'
import Select, { components } from 'react-select'
import styled from "styled-components"


const CustomSelect = forwardRef((props, ref) => {
    const {
        field,
        label = "",
        placeholder = "",
        required = false,
        isMulti = false,
        options = [],
        value = "",
        onChange,
        defaultValue,
        selected,
        defaultInputValue,
        selectedValue,
        disabled = false,
        isDisabled = false
    } = props

    const Control = (props) => {
        return (
            <>
                <Label className='react-select-floating-label' isFloating={props.isFocused || props.hasValue}>{placeholder}</Label>
                <components.Control {...props} />
            </>
        );
    };

    const Label = styled.label(function (_ref) {
        var isFloating = _ref.isFloating;
        return {
            left: isFloating ? '12px' : '9px',
            pointerEvents: 'none',
            position: 'absolute',
            transition: '0.2s ease all',
            MozTransition: '0.2s ease all',
            WebkitTransition: '0.2s ease all',
            zIndex: 1,
            top: isFloating ? '-12px' : '30%',
            fontSize: isFloating ? '14px' : '16px',
            backgroundColor: isFloating ? "#FFF" : 'transparent',
            padding: "0 5px",
        };
    });


    return (
        <Form.Group className="custom-select">
            <Select
                components={{ Control }}
                {...field}
                isDisabled={isDisabled}
                className="react-select-container w-100"
                classNamePrefix="react-select"
                // placeholder={placeholder}
                options={options}
                value={value}
                defaultValue={defaultValue}
                defaultInputValue={defaultInputValue}
                onChange={onChange}
                isMulti={isMulti}
                disabled={disabled}
                isOptionSelected={selected}
                selectedValue={selectedValue}
            />
        </Form.Group>
    )
})

export default CustomSelect