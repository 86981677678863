import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowIcon, ArrowLeft } from "../../constants/svgs";
import { useNavigate } from "react-router-dom";
import { showWarningModalContext } from "../../App";

const WarningModal = ({ showModal, setShowModal }) => {
    const navigate = useNavigate();
    const onSubmitModal = () => {
        navigate("/membership");
    };

    return (
        <Modal
            className="theme-popup logout-popup"
            show={showModal}
            onHide={() => {
                setShowModal(false)
            }}
            size='md'
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div>
                    <div className="header text-center">
                        <h2>WARNING</h2>
                        <p className="mt-2">Please update your subscription plan!</p>
                    </div>

                    <div className="mt-30 d-flex align-items-center justify-content-center ">
                        <Button
                            className="btn-outline btn-dark"
                            onClick={() => {
                                setShowModal(false)
                                navigate('/')
                            }
                            }
                        >
                            <div className="circle">
                                <ArrowLeft />
                            </div>
                            Cancel
                        </Button>

                        <Button onClick={onSubmitModal} className="btn-solid btn-blue ms-4">
                            Update
                            <div className="circle">
                                <ArrowIcon />
                            </div>
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default WarningModal;
