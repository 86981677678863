import React, { useContext, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import CustomInput from '../form/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { Button, Col, Row } from 'react-bootstrap'
import { ArrowIcon, ArrowLeft } from '../../constants/svgs'
import CustomSelect from '../form/CustomSelect'
// import { currencyOptions } from '../../utils/constants'
import { balanceBudgetContext } from '../../App'

const BudgetForm = ({ watch, control, formState, setValue, handleNextStep, handlePreviousStep }) => {
    const { errors, isDirty, isValid, isSubmitting } = formState
    const [balanceBudget, setBalanceBudget] = useContext(balanceBudgetContext)

    const [currencyOptions, setCurrencyOptions] = useState([]);

    useEffect(() => {
        fetchCurrencies();
    }, []);

    const fetchCurrencies = async () => {
        try {
            const response = await fetch(`https://openexchangerates.org/api/currencies.json?app_id=YOUR_API_KEY`);
            const data = await response.json();
            const options = Object.entries(data).map(([code, name]) => ({ value: code, label: `${name} (${code})` }));
            setCurrencyOptions(options);
        } catch (error) {
            console.error("Error fetching currencies:", error);
        }
    };
    return (
        <div className='budget-form'>
            <Row>
                <Col xs={12} xl={11} className='border-bottom pb-3 mb-4'>
                    <h3 className='fw-800 color-blue'>Enter Your Budget</h3>
                    <i className='italic-tag-line fw-900 mb-5 color-grey'>Specify your budget by the currency</i>
                </Col>

                <Col xs={12} xl={11}>
                    {/* CURRENCY INPUT */}
                    <div className="mb-25">
                        <Controller
                            control={control}
                            name="currency"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.CURRENCY_REQUIRED,
                                },
                            }}
                            render={({ field }) => (
                                <CustomSelect
                                    {...field}
                                    label="Select Currency"
                                    placeholder="Select Currency"
                                    options={currencyOptions}
                                    required={true}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.currency && <small className='text-start d-block text-danger mt-1'>{errors.currency.message}</small>}
                    </div>

                    {/* BUDGET INPUT */}
                    <div className="mb-25">
                        <Controller
                            control={control}
                            name="budget"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.BUDGET_REQUIRED,
                                },
                                maxLength: {
                                    value: 10,
                                    message: 'Max length is 10'
                                },
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Enter Your Budget"
                                    placeholder="Enter Your Budget"
                                    type="number"
                                    maxLength={21}
                                    required={true}
                                    value={field.value}
                                    // onChange={field.onChange}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.budget && <small className='text-start d-block text-danger mt-1'>{errors.budget.message}</small>}
                    </div>

                    {/* NEXT STEP BUTTON */}
                    <div className='mt-5 d-flex flex-column flex-sm-row align-items-sm-center '>
                        <Button onClick={handlePreviousStep} className="btn-outline btn-dark" type="button">
                            <div className="circle">
                                <ArrowLeft />
                            </div>
                            PREVIOUS STEP
                        </Button>

                        <Button onClick={handleNextStep} className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue" type="submit" disabled={!isDirty || !isValid}>
                            NEXT STEP
                            <div className="circle">
                                <ArrowIcon />
                            </div>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default BudgetForm