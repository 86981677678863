import React, { useContext, useEffect, useState } from "react";
import { home } from "../constants/content";
import IntroductionBanner from "../components/static/IntroductionBanner";
import { NavLink, useLocation } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import PlanCard from "../components/static/PlanCard";
import { MdChevronLeft } from "react-icons/md";
import PaymentForm from "../components/static/PaymentForm";
import { addToCard, getSubscriptionPlan } from "../services/payment";
import { selectedPlanDetailsContext, subscribeplanDataContext, subscriptionPlanContext } from "../App";

const PaymentPage = () => {
  const location = useLocation();
  const [Subscribeplanname, setSubscribeplanname] = useContext(
    subscriptionPlanContext
  );
  const [selectedPlanDetails, setSelectedPlanDetails] = useContext(selectedPlanDetailsContext);
  const [subscribeplanData, setSubscribeplanData] = useContext(
    subscribeplanDataContext
  );
  useEffect(() => {
    GetSubscriptionPlan();
  }, []);

  const GetSubscriptionPlan = async () => {
    const params = {
      name: Subscribeplanname,
    };
    const response = await getSubscriptionPlan(params);
    setSubscribeplanData(response?.data?.data);
  };

  const onSubmit = async (data) => {
    return false;
    const response = await addToCard(data);
  };

  return (
    <div className="static-pages payment-page">
      <IntroductionBanner
        title={home.title}
        description={home.description}
        withBtns={false}
      />

      <div className="content-wrapper">
        <Container>
          <Row>
            <Col xl={5} lg={12}>
              <div className="left-wrapper">
                <div className="left mb-4">
                  <h4 className="lh-0-ls-5">MEMBERSHIP</h4>
                  <h2 className="mt-3">Your Selected Plan</h2>
                </div>

                <PlanCard data={selectedPlanDetails} showBtn={false} />

                <NavLink to="/membership" className="mt-4 mb-5 mb-xl-0">
                  <div className="circle me-3">
                    <MdChevronLeft className="text-dark" />
                  </div>
                  Change Plan
                </NavLink>
              </div>
            </Col>

            <Col xl={{ span: 6, offset: 1 }} lg={12}>
              <div className="right-wrapper">
                <PaymentForm subscribeplanData={subscribeplanData} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PaymentPage;
