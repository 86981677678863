import React from 'react'
import Assets from '../../constants/images'
import { RedirectIcon } from '../../constants/svgs'
import { NavLink } from 'react-router-dom'

const NotificationCard = ({ data }) => {
    return (
        <div className='notification-card'>
            <div className={`wrapper d-flex align-items-center w-100 ${data?.status == 'unseen' ? "unseen" : ""}`}>
                <div className="img-wrapper">
                    <img src={data?.img} onError={(e) => e.target.src = Assets.GeneralPlaceholderImg} alt="" />
                </div>

                <div className="content w-100 ms-3 d-flex align-items-center justify-content-between">
                    <div>
                        <span><b>{data?.title}</b> {data?.notification}</span>
                        <small className='d-block'>{data?.time}</small>
                    </div>

                    <NavLink className="ms-2" to="#"><RedirectIcon /></NavLink>
                </div>
            </div>
        </div>
    )
}

export default NotificationCard