import React, { useState } from 'react'
import { TiTimes } from "react-icons/ti";
import { ArrowRightBlue } from '../../constants/svgs';
import { NavLink, useNavigate } from 'react-router-dom';
import DeleteConfirmPopup from '../popup/DeleteConfirmPopup';
import { deleteProgram, sendInvitation } from '../../services/projects';
import { error, success } from '../../constants/msg';
import ThemeModal from '../popup/ThemeModa';

const ProjectBox = ({ projects, ...props }) => {
    const [deletePopup, setDeletePopup] = useState(false)
    const updated_date = projects?.created_by
    const [showModal, setShowModal] = useState(false);
    const [projectId, setProjectId] = useState('')
    const navigate = useNavigate()
    const handleDelete = async () => {
        try {
            const response = await deleteProgram(projects?._id)
            success(response?.data?.message)
            setDeletePopup(false)
            props?.GetProjects()
        }
        catch (e) {
            setDeletePopup(true)
            error(e?.response?.data?.message)
        }
    }
    const onClickModalShow = (id) => {
        setShowModal(true)
        setProjectId(id)
    }
    const onSubmitModal = async () => {
        setShowModal(false)
        success("Invitation successfully sent")
        return false
        const res = await sendInvitation()
    }
    const onCardClick = (projects) => {
        navigate(`/update-project/${projects?._id}`)
    }
    return (
        <>
            <div className='project-box p-2' key={projects?._id}>
                <div className="times cursor" onClick={() => setDeletePopup(true)}>
                    <TiTimes />
                </div>

                <div className='content' >
                    <h4 className='mb-2'>{'Ceremony Project'}</h4>
                    <span
                        onClick={() => onCardClick(projects)}
                        style={{
                            cursor: 'pointer',
                            borderBottom: '1px solid red'
                        }}>

                        <span style={{
                            color: 'blue',
                            fontWeight: 600
                        }}>{projects?.personal_information ? projects?.personal_information?.groom_name : "..."}
                        </span>
                        <span style={{ color: 'blue' }}>&</span>
                        <span style={{
                            color: 'blue',
                            fontWeight: 600
                        }}>{projects?.personal_information ? projects?.personal_information?.bride_name : "..."}
                        </span>
                    </span>

                    <div className="circle mt-4 mb-2"
                        onClick={() => onClickModalShow(projects?._id)}>
                        <ArrowRightBlue />
                    </div>
                    <div className="">
                        Send Invitation

                    </div>
                </div>
            </div>
            <DeleteConfirmPopup deleteConfirmPopup={deletePopup} setDeleteConfirmPopup={setDeletePopup}
                handleDelete={handleDelete}
                txtFirst={"Delete Programs"} txtSecond={"Are you sure you want to Delete Program?"}
                cnfrmtxt={"Delete"}
            />
            <DeleteConfirmPopup
                deleteConfirmPopup={showModal}
                setDeleteConfirmPopup={setShowModal}
                txtFirst={"Sent Invitation and Tasks"}
                txtSecond={"You want sent Invitation?"}
                size={"sm"}
                cancelText={"Cancel"}
                cnfrmtxt={"Sent"}
                handleDelete={onSubmitModal}
            />
        </>
    )
}

export default ProjectBox