import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";
import SigninForm from "../components/authentication/SigninForm";
import AuthLayout from "../layouts/authentication/AuthLayout";
import SignupForm from "../components/authentication/SignupForm";
import ForgotPasswordForm from "../components/authentication/ForgotPasswordForm";
import VerificationCodeForm from "../components/authentication/VerificationCodeForm";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm";
import HomePage from "../pages/HomePage";
import StaticLayout from "../layouts/static/StaticLayout";
import AboutPage from "../pages/AboutPage";
import PlanPage from "../pages/PlanPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import ContactPage from "../pages/ContactPage";
import FaqPage from "../pages/FaqPage";
import PaymentPage from "../pages/PaymentPage";
import CompleteProfilePage from "../pages/CompleteProfilePage";
import FindMatchPage from "../pages/FindMatchPage";
import MemberProfilePage from "../pages/MemberProfilePage";
import MyProfilePage from "../pages/MyProfilePage";
import EditProfilePage from "../pages/EditProfilePage";
import MyMatchesPage from "../pages/MyMatchesPage";
import MyMembershipPage from "../pages/MyMembershipPage";
import UpdateMyPlanPage from "../pages/UpdateMyPlanPage";
import MyPaymentPage from "../pages/MyPaymentPage";
import MyMessagesPage from "../pages/MyMessagesPage";
import NotificationsPage from "../pages/NotificationsPage";
import PublicRoute from "./PublicRoute";
import ProtectedRoute, {
  ProtectedRouteCompleteProfile,
  ProtectedRouteForPaymentBasicFree,
} from "./ProtectedRoute";
import MyWeddingProjects from "../pages/MyWeddingProjects";
import CreateNewProject from "../pages/CreateNewProject";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // move to top on page change
    window.scrollTo(0, 0);
  }, [navigate, location]);

  return (
    <Routes>
      {/* ************* Authentication Routes ************* */}

      <Route element={<PublicRoute />}>
        <Route
          path="/signin"
          element={<AuthLayout children={<SigninForm />} />}
        ></Route>
        <Route
          path="/register"
          element={<AuthLayout children={<SignupForm />} />}
        ></Route>
        <Route
          path="/forgot-password"
          element={<AuthLayout children={<ForgotPasswordForm />} />}
        ></Route>
        <Route
          path="/otp-verification"
          element={<AuthLayout children={<VerificationCodeForm />} />}
        ></Route>
        <Route
          path="/reset-password"
          element={<AuthLayout children={<ResetPasswordForm />} />}
        ></Route>
      </Route>

      {/* ************* Static Page Routes ************* */}
      <Route
        path="/"
        element={<StaticLayout children={<HomePage />} />}
      ></Route>
      <Route
        path="/membership"
        element={<StaticLayout children={<PlanPage />} />}
      ></Route>


      <Route element={<ProtectedRouteCompleteProfile />}>
        <Route
          path="/complete-profile"
          element={<StaticLayout children={<CompleteProfilePage />} />}
        ></Route>
        <Route
          path="/setting/my-profile"
          element={<StaticLayout children={<MyProfilePage />} />}
        ></Route>
        <Route
          path="/setting/edit-profile"
          element={<StaticLayout children={<EditProfilePage />} />}
        ></Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path="/find-match"
          element={<StaticLayout children={<FindMatchPage />} />}
        ></Route>
        <Route element={<ProtectedRouteForPaymentBasicFree />}>
          <Route
            path="/member-profile/:id"
            element={<StaticLayout children={<MemberProfilePage />} />}
          ></Route>
          <Route
            path="/setting/my-matches"
            element={<StaticLayout children={<MyMatchesPage />} />}
          ></Route>
          <Route
            path="/setting/my-membership"
            element={<StaticLayout children={<MyMembershipPage />} />}
          ></Route>
          <Route
            path="/setting/update-my-plan"
            element={<StaticLayout children={<UpdateMyPlanPage />} />}
          ></Route>
          <Route
            path="/setting/payment"
            element={<StaticLayout children={<MyPaymentPage />} />}
          ></Route>
          <Route
            path="/setting/my-messages"
            element={<StaticLayout children={<MyMessagesPage />} />}
          ></Route>
          <Route
            path="/setting/notifications"
            element={<StaticLayout children={<NotificationsPage />} />}
          ></Route>
          <Route
            path="/my-wedding-projects"
            element={<StaticLayout children={<MyWeddingProjects />} />}
          ></Route>
          <Route
            path="/create-new-project"
            element={<StaticLayout children={<CreateNewProject />} />}
          ></Route>
          <Route
            path="/update-project/:id"
            element={<StaticLayout children={<CreateNewProject />} />}
          ></Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        {/* <Route
          path="/payment/add-card"
          element={<StaticLayout children={<PaymentPage />} />}
        ></Route> */}
        <Route
          path="/payment/plan/:id"
          element={<StaticLayout children={<PaymentPage />} />}
        ></Route>
      </Route>

      {/* static */}
      <Route
        path="/terms-and-conditions"
        element={<StaticLayout children={<TermsAndConditionsPage />} />}
      ></Route>
      <Route
        path="/privacy-policy"
        element={<StaticLayout children={<PrivacyPolicyPage />} />}
      ></Route>
      <Route
        path="/about"
        element={<StaticLayout children={<AboutPage />} />}
      ></Route>
      <Route
        path="/contact"
        element={<StaticLayout children={<ContactPage />} />}
      ></Route>
      <Route
        path="/faqs"
        element={<StaticLayout children={<FaqPage />} />}
      ></Route>

      {/* ************* Dashboard Routes ************* */}

      {/* ******* NOTE: PLACE ALL ROUTES BEFORE ERROR ROUTE ******* */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Navigation;
