import React from "react";
import Assets from "../../constants/images";
import ThemeNavbar from "../../components/navbar/ThemeNavbar";
import { UserWhiteIcon } from "../../constants/svgs";
import ThemeFooter from "../../components/footer/ThemeFooter";

const StaticLayout = ({ children }) => {
  return (
    <div className="static-layout">
      <ThemeNavbar
        logo={Assets.StaticLogo}
        layout="static"
        userIcon={<UserWhiteIcon />}
      />
      <div className="">{children}</div>
      <ThemeFooter />
    </div>
  );
};

export default StaticLayout;
