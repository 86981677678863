import React from 'react'
import IntroductionBanner from '../components/static/IntroductionBanner'
import { privacyPolicy } from '../constants/content'
import { Col, Container, Row } from 'react-bootstrap'

const PrivacyPolicyPage = () => {
    return (
        <div className='static-pages privacy-policy-page'>
            <IntroductionBanner
                title={privacyPolicy.title}
                description={privacyPolicy.description}
                withBtns={false}
            />

            <div className="content-wrapper">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className='content'>
                                <div className="block mb-4">
                                    <h4>Privacy Policy</h4>
                                    <p className='mt-2'>
                                        We take your privacy seriously. This privacy policy explains how we collect, use, and disclose personal information on our caregiver website.
                                        Please read this policy carefully before using our website.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Information We Collect</h4>
                                    <p className='mt-2'>
                                        We collect personal information that you voluntarily provide to us when you use our website, including your name, email address, phone number, and
                                        any other information you provide to us.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>How We Share Your Information</h4>
                                    <p className='mt-2'>
                                        We may share your personal information with third-party service providers who help us operate our website and provide you with our services. We may also share
                                        your personal information with law enforcement agencies or other third parties if we are required to do so by law or if we believe in good faith that such disclosure
                                        is necessary to comply with legal requirements or protect our rights, property, or safety.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Cookies and Other Tracking Technologies</h4>
                                    <p className='mt-2'>
                                        We use cookies and other tracking technologies to improve your experience on our website, to analyze our website traffic, and to personalize content and advertising.
                                        You can control cookies through your web browser settings.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Governing Law and Jurisdiction</h4>
                                    <p className='mt-2'>
                                        These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of Florida, without giving effect to any principles of conflicts of law.
                                        You agree that any action at law or in equity arising out of or relating to these Terms and Conditions shall be filed only in the state or federal courts located in
                                        United State, and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Security Measures</h4>
                                    <p className='mt-2'>
                                        We take reasonable measures to protect your personal information from unauthorized access, disclosure, and destruction. However, no data transmission over the internet or
                                        electronic storage system is completely secure, so we cannot guarantee the absolute security of your personal information.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Children's Privacy</h4>
                                    <p className='mt-2'>
                                        Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Your Rights</h4>
                                    <p className='mt-2'>
                                        You have the right to access, correct, and delete your personal information that we collect. You also have the right to withdraw your consent to our use of your personal information.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage