import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Assets from "../../constants/images";
import { ArrowIconBlack } from "../../constants/svgs";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { useNavigate } from "react-router-dom"

const FindMatch = () => {
  const navigate = useNavigate();
  const loggedInUser = GetAuthUserLocalStorage()

  return (
    <div className="find-match">
      <Container className="h-100">
        <Row className="h-100">
          <Col md={6} className="my-auto">
            <h4 className="lh-0-ls-5">FIND YOUR MATCH</h4>
            <h1 className="mt-3">
              Begin a Customized Quest to Discover Your Perfect Life Companion.
            </h1>
            <h4 className="mt-4">
              At Rishtey&amp;Shadi, we recognize the profound importance of
              finding a life partner. This understanding drives us to create a
              considerate and tailored method to assist you in finding your
              perfect match. Our platform surpasses expectations by employing
              sophisticated matchmaking algorithms and an in-depth comprehension
              of your preferences. We strive to connect you with individuals who
              align with your values, aspirations, and envision a shared future.
            </h4>

            <Button
              className="btn-outline btn-dark text-uppercase mt-4"
              type="button"
              onClick={() =>
                loggedInUser && loggedInUser?.isProfileCompleted ? navigate('/find-match') :
                  navigate("/complete-profile")}
            >
              <div className="circle">
                <ArrowIconBlack />
              </div>
              FIND YOUR MATCH
            </Button>
          </Col>

          <Col md={{ span: 5, offset: 1 }} className="mt-4 mt-md-0">
            <div className="mt-4 banner-wrapper">
              <img className="w-100" src={Assets.FindMatchImg} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FindMatch;
