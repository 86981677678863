import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import CustomInput from '../form/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { Button, Col, Row } from 'react-bootstrap'
import { ArrowIcon, ArrowLeft, DeleteIcon } from '../../constants/svgs'
import { IoMdAdd } from "react-icons/io"
import CustomSelect from '../form/CustomSelect'

const GuestListForm = ({ watch, control, formState, setValue, handleNextStep, handlePreviousStep }) => {
    const { errors, isDirty, isValid, isSubmitting } = formState
    const { fields, append, remove } = useFieldArray({ control, name: 'guests' })
    const [
        brideName,
        groomName,
        budget,
        programs,
        guestscounts,
        guests,
        collaboratedTeam,
        currency,
        religion,
        vendors,
        weddingTemplates,
    ] = watch([
        "brideName",
        "groomName",
        "budget",
        "programs",
        "guestscounts",
        "guests",
        "collaboratedTeam",
        "currency",
        "religion",
        "vendors",
        "weddingTemplates",
    ]);


    const [programCount, setProgramCount] = useState([])
    useEffect(() => {
        const res = programs?.length > 0 ? programs?.map((dt, index) => ({
            label: dt?.name,
            value: index++
        })) : ''
        setProgramCount(res)
    }, [])

    const addGuest = () => {
        append({ fname: '', lname: '', email: '', familycount: '' })
    }

    return (
        <div className='guest-list-form'>
            <Row>
                <Col xs={12} xl={11} className='border-bottom pb-3 mb-4'>
                    <h3 className='fw-800 color-blue'>Guest List</h3>
                    <i className='italic-tag-line fw-400 mb-5 color-grey'>Start adding your guest information for each program</i>
                </Col>

                <Col xs={12} xl={11}>
                    <div className="mb-25 d-flex align-items-center justify-content-between">
                        <div className=' d-flex align-items-center'>
                            <div className="add-cirlce" onClick={addGuest}>
                                <IoMdAdd size={22} />
                            </div>
                            <h6 className='ms-3 color-dark'>ADD GUEST</h6>
                        </div>

                        <div className=' d-flex align-items-center'>
                            <div className="guest-cirlce">
                                <h6>{fields?.length}</h6>
                            </div>
                            <h6 className='ms-3 color-dark'>Families added</h6>
                        </div>
                    </div>

                    <Row className={`mb-25 ${fields?.length > 0 ? "d-flex" : "d-none"}`}>
                        <Col xs={12}>
                            <span className='d-block w-100 border-bottom pb-3 mb-3'><b>Guests Details</b></span>
                        </Col>
                    </Row>

                    <Row>
                        {
                            fields.map((guest, index) => (
                                <React.Fragment>
                                    {/* NAME INPUT */}
                                    <Col xs={4} key={index}>
                                        <div className="mb-25">
                                            <Controller
                                                control={control}
                                                name={`guests[${index}].fname`}
                                                rules={{
                                                    minLength: {
                                                        value: VALIDATIONS.NAME_MIN,
                                                        message: VALIDATIONS_TEXT.NAME_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.NAME_MAX,
                                                        message: VALIDATIONS_TEXT.NAME_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        label="First Name"
                                                        placeholder="First Name"
                                                        type="text"
                                                        minLength={VALIDATIONS.NAME_MIN}
                                                        maxLength={VALIDATIONS.NAME_MAX}
                                                        required={true}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                )}
                                            />
                                            {errors.guests && errors.guests[index] && errors.guests[index].fname && <small className='text-start d-block text-danger mt-1'>{errors.guests[index].fname.message}</small>}
                                        </div>
                                    </Col>
                                    <Col xs={4} key={index}>
                                        <div className="mb-25">
                                            <Controller
                                                control={control}
                                                name={`guests[${index}].lname`}
                                                rules={{
                                                    minLength: {
                                                        value: VALIDATIONS.NAME_MIN,
                                                        message: VALIDATIONS_TEXT.NAME_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.NAME_MAX,
                                                        message: VALIDATIONS_TEXT.NAME_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        label="Last Name"
                                                        placeholder="Last Name"
                                                        type="text"
                                                        minLength={VALIDATIONS.NAME_MIN}
                                                        maxLength={VALIDATIONS.NAME_MAX}
                                                        required={true}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                )}
                                            />
                                            {errors.guests && errors.guests[index] && errors.guests[index].lname && <small className='text-start d-block text-danger mt-1'>{errors.guests[index].lname.message}</small>}
                                        </div>
                                    </Col>
                                    <Col xs={4} key={index}>
                                        <div className="mb-25">
                                            <Controller
                                                control={control}
                                                name={`guests[${index}].pname`}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.GENDER_REQUIRED,
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <CustomSelect
                                                        {...field}
                                                        label="Program"
                                                        placeholder="Program"
                                                        options={programCount}
                                                        required={true}
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            field.onChange(e); // Invoke the field.onChange function and pass the selected value
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    {/* EMAIL INPUT */}
                                    <Col xs={6}>
                                        <div className='mb-25'>
                                            <Controller
                                                control={control}
                                                name={`guests[${index}].email`}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.EMAIL,
                                                        message: VALIDATIONS_TEXT.EMAIL
                                                    },
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                    }
                                                }}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        label="Email Address"
                                                        placeholder="Email Address"
                                                        type="email"
                                                        required={true}
                                                        maxLength={VALIDATIONS.EMAIL}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                )}
                                            />
                                            {errors.guests && errors.guests[index] && errors.guests[index].email && <small className='text-start d-block text-danger mt-1'>{errors.guests[index].email.message}</small>}
                                        </div>

                                    </Col>



                                    <Col xs={4}>
                                        <div className="mb-25 d-flex">
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name={`guests[${index}].familycount`}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.FAMILY_COUNT_REQUIRED,
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <CustomInput
                                                            {...field}
                                                            label="Family count"
                                                            placeholder="Family count"
                                                            type="number"
                                                            required={true}
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                        />
                                                    )}
                                                />
                                                {errors.guests && errors.guests[index] && errors.guests[index].familycount && <small className='text-start d-block text-danger mt-1'>{errors.guests[index].familycount.message}</small>}
                                            </div>

                                            <div className='ms-3 mt-3' onClick={() => remove(index)}>
                                                <DeleteIcon />
                                            </div>
                                        </div>
                                    </Col>

                                </React.Fragment>
                            ))
                        }
                    </Row>

                    {/* NEXT STEP BUTTON */}
                    <div className='mt-5 d-flex flex-column flex-sm-row align-items-sm-center '>
                        <Button onClick={handlePreviousStep} className="btn-outline btn-dark" type="button">
                            <div className="circle">
                                <ArrowLeft />
                            </div>
                            PREVIOUS STEP
                        </Button>

                        <Button onClick={handleNextStep} className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue" type="submit" disabled={!isDirty || !isValid || fields?.length == 0}>
                            NEXT STEP
                            <div className="circle">
                                <ArrowIcon />
                            </div>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default GuestListForm