import Assets from "../constants/images";

export const home = {
  title:
    "Crafting Happily Ever After: Your Premier Destination for Online Wedding Planning and Discovering Your Perfect Match.",
  description: `
  Enter an extraordinary realm where the precision of wedding planning meets the expertise of
  matchmaking. Our groundbreaking platform combines the intricate elements of your envisioned
  wedding with the thrilling journey of discovering your ideal partner, all within one innovative space.
    `,
};

export const aboutUs = {
  title: "ABOUT US",
  subHeading: `
  Your Dream Wedding, Simplified Online
    `,
  description: `
  In today's digital era, online wedding planning is an essential tool for couples aiming for a smooth
  and hassle-free wedding experience. Step into the modern approach to wedding preparations
  through our detailed exploration of the advantages of virtual wedding planning. We kickstart your
  journey with an in-depth consultation, delving into your desires, visions, and dreams. Crafting an
  event that harmonizes perfectly with your uniqueness and taste is our specialty. Our 'Tailored
  Wedding Planning Hub' goes beyond just organizing; it's about crafting an extraordinary celebration
  that resonates deeply with your soul.
    `,
  img: Assets.AboutUsImg,
  btnTextOne: "",
  btnTextTwo: "",
};

export const whoWeAre = {
  title: "WHO WE ARE",
  subHeading: `Discover your perfect match, just for you on our premier matchmaking platform`,
  description: `
  At Rishtey and Shadi.com, we merge the art of matchmaking with the precision of online wedding planning. Backed by
  years of expertise, our platform seamlessly connects soulmates while streamlining the wedding planning process. Our
  intuitive interface simplifies matchmaking, allowing you to discover your perfect match effortlessly.
 
  Furthermore, our wedding ceremonial planner ensures every detail is meticulously arranged, resulting in unforgettable
  celebrations planned, that reflect your unique love story.

  Join us to experience the harmonious blend of experience, user-friendly design, and exceptional outcomes in creating your
  dream wedding.
    `,
  img: Assets.WhoWeAreImg,
  btnTextOne: "LEARN MORE",
  btnTextTwo: ""
};

export const about = {
  title: "Join us for personalized matchmaking and seamless wedding planning.",
  description: `
  Our intuitive matchmaking platform simplifies the search, ensuring a seamless and enjoyable experience. Find your perfect
  match efficiently and confidently with our proven track record of successful pairings. Our user-friendly platform ensures a
  smooth journey from matchmaking to wedding planning, culminating in unforgettable and personalized celebrations.
    `,
};

export const whyChooseUs = {
  title: "WHY CHOOSE US",
  subHeading: `
  Embrace our platform for a seamless journey from discovering your ideal match to creating your
  dream wedding - where heartfelt connections meet beautifully orchestrated wedding celebrations.
    `,
  description: `
  We are your trusted companion on the path to eternal love and unforgettable celebrations. With a
  seamless blend of sophisticated matchmaking and effortless wedding planning, we offer a unique
  experience tailored to your desires. Our intuitive interface simplifies finding your perfect match,
  while our expert wedding planners meticulously craft personalized ceremonies. We're committed to
  nurturing genuine connections and curating dream weddings, providing unparalleled support at
  every step. Join us to embark on a journey where love flourishes, and dream weddings become
  reality.
    `,
  img: "",
  btnTextOne: "Ceremonial Planner",
  btnTextTwo: "Find Your Match",
};

export const choosePlan = {
  title:
    "Discover Your Perfect Match: “We offer a range of Price Plans for Unparalleled Matchmaking Experiences!",
  description: `
  In the symphony of connections, our tiered plans resonate with the diverse cadences of individual
  souls, offering pathways to varied depths of understanding, connection, and harmonious unions.
    `,
};

export const privacyPolicy = {
  title: `Our commitment to protecting
        your privacy policy for
        our wedding planning Website.
    `,
  description: `
        At Rishtey and Shadi, we value your privacy and are 
        committed to protecting your personal information. 
        This privacy policy outlines how we collect, use, and protect 
        your personal information when you visit our caregiver website.
    `,
};

export const termsAndConditions = {
  title: `Terms and Conditions 
        your agreement to use
        Rishtey and Shadi Website.
    `,
  description: `
        By using our website, you agree to be bound by the following terms 
        and conditions. Please read these terms and conditions carefully 
        before using our website. If you do not agree to these terms and 
        conditions, you should not use our website. 
    `,
};

export const contactUs = {
  title:
    "We highly appreciate your inquiries, feedback, and the chance to support you on your path tofinding love and marriage.",
  description: `
  Contact Us! We’re here to help! To assist you with any questions or concerns you may have. Our
  team ensures your special day is perfect. Whether you're seeking guidance on wedding planning,
  matchmaking, or technical support, we're just an email away.
    `,
};

export const faqs = {
  title: `Frequently Asked Questions, find answers to common queries about our services.`,
  description: `In this journey of love and union, we anticipate your queries. To embrace your concerns, we&#39;ve
  crafted a sanctuary of answers. Our commitment is to guide and reassure, fostering a serene path to
  your cherished celebration.`,
};

export const payment = {
  title: `
        Choose the payment method that suits you best. We offer a variety of secure options.
    `,
  description: `
        Select your preferred payment method below to complete your purchase 
        securely and conveniently. We offer various options to suit your needs, 
        ensuring a seamless checkout experience. 
    `,
};

export const completeProfile = {
  title: `
      Craft Your Love Story: Begin with Your Personal Profile Creation
    `,
  description: `
  The "Profile Creation" section guides users through personalizing their matchmaking journey,
  providing steps to input personal details, preferences, photos, and unique interests, laying the
  foundation for meaningful connections and potential matches.
    `,
};

export const findMatch = {
  title: `
        Embark on a personal journey of discovery, where expressing your true self and connecting with potential partner.
    `,
  description: `
        Discover the essence of your Life Partner journey where expressing your true 
        self and connecting with potential matches define the path to finding a life partner. 
        Start your journey today, where the search for a life partner becomes a celebration of 
        individuality and genuine connection.
    `,
};
