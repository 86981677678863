import api from "../index";
import { apiUrl, constant } from "../../utils/constants";

export const Login = (data) => api.post(`${constant.VER}${constant.auth}${apiUrl.login}/`, data)
export const Signup = (data) => api.post(`${constant.VER}${constant.auth}${apiUrl.signup}/`, data)
export const VerifyOtp = (data) => api.post(`${constant.VER}${constant.auth}${apiUrl.verify_otp}/`, data)
export const ForgetPassword = (email) => api.post(`${constant.VER}${constant.auth}${apiUrl.forget_password}/`, email)
export const ResetPassword = (data) => api.post(`${constant.VER}${constant.auth}${apiUrl.reset_password}/`, data)
export const ChangePassword = (data) => api.post(`${constant.VER}${constant.auth}${apiUrl.change_password}/`, data)
export const Logout = (data) => api.post(`${constant.VER}${constant.auth}${apiUrl.logout}/`, data)
export const resendOTP = (data) => api.post(`${constant.VER}${constant.auth}${apiUrl.resend_otp}`, data)