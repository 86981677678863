import React, { useContext, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../form/CustomInput'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { NavLink, useNavigate } from 'react-router-dom'
import { ArrowIcon } from '../../constants/svgs'
import { error, success } from '../../constants/msg'
import { ResetPassword } from '../../services/auth/auth'
import { fogotPassContext } from '../../App'

const ResetPasswordForm = () => {
    const { handleSubmit, register, control, reset, watch, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' })
    const [forgotData, setForgotData] = useContext(fogotPassContext)
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const newPassword = watch("password")

    const onSubmit = async (data) => {
        setLoading(true)
        const newData = {
            'email': forgotData?.email,
            'password': data?.password,
            'confirm_password': data?.confirm_password
        }
        try {
            const res = await ResetPassword(newData)
            success(res?.data?.message)
            setTimeout(() => {
                navigate('/signin')
            }, 1200)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            error(e?.response?.data?.message);
        }
    }

    return (
        <div className='auth signup-form'>
            <h1>Reset Password</h1>
            <h3 className='mt-4'>
                Create your new password
                Your new password must be different
                from previous used passwords.
            </h3>

            <div>
                <Form className='mt-5' onSubmit={handleSubmit(onSubmit)}>
                    {/* PASSWORD INPUT */}
                    <div className='mb-25'>
                        <Controller
                            control={control}
                            name="password"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                            }}
                            render={({ field }) => (
                                <div className="password-input">
                                    <CustomInput
                                        {...field}
                                        label="New Password"
                                        placeholder="New Password"
                                        type={showPassword ? "text" : "password"}
                                        required={true}
                                        minLength={VALIDATIONS.PASSWORD_MIN}
                                        maxLength={VALIDATIONS.PASSWORD_MAX}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    <span className='eye-icon' onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                    </span>
                                </div>
                            )}
                        />
                        {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}
                    </div>

                    {/* CONFIRM PASSWORD INPUT */}
                    <div>
                        <Controller
                            control={control}
                            name="confirm_password"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                validate: (value) =>
                                    value === newPassword || "The Passwords do not match"
                            }}
                            render={({ field }) => (
                                <div className="password-input">
                                    <CustomInput
                                        {...field}
                                        label="Confirm New Password"
                                        placeholder="Confirm New Password"
                                        type={showConfirmPassword ? "text" : "password"}
                                        required={true}
                                        minLength={VALIDATIONS.PASSWORD_MIN}
                                        maxLength={VALIDATIONS.PASSWORD_MAX}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    <span className='eye-icon' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                        {showConfirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                    </span>
                                </div>
                            )}
                        />
                        {errors.confirm_password && <small className='text-start d-block text-danger mt-1'>{errors.confirm_password.message}</small>}
                    </div>

                    {/* BUTTON AND LINKS */}
                    <Button className="btn-solid btn-blue mt-30" type="submit" disabled={loading}>
                        RESET PASSWORD
                        <div className="circle">
                            <ArrowIcon />
                        </div>
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default ResetPasswordForm