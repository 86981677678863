import React, { useEffect, useState } from 'react'
import SettingHeader from '../components/static/SettingHeader'
import { Col, Container, Row } from 'react-bootstrap'
import SettingSidebar from '../components/static/SettingSidebar'
import MatchCard from '../components/static/MatchCard'
import PlanCard from '../components/static/PlanCard'
import { NavLink } from 'react-router-dom'
import { MdChevronLeft } from 'react-icons/md'
import { GetAuthUserLocalStorage, GetUserRoleLocalStorage } from '../services/localStorage/localStorage'

const UpdateMyPlanPage = () => {
    const user = GetAuthUserLocalStorage()
    const [plans, setPlans] = useState([
        {
            param: "Silver",
            plan: "Silver Plan",
            type: "50AED m / 120AED 3m / 250 AED 6m",
            features: [
                {
                    description: "View verified contact details",
                },
                {
                    description: "Partial Profile View",
                },
                {
                    description: "Direct Messages to partners (02 Weekly)",
                },
            ],
            btnText: "Update Now"
        },
        {
            param: "Gold",
            plan: "Gold Plan",
            type: "100AED m / 200AED 3m / 500 AED 6m",
            features: [
                {
                    description: "Connect instantly via Chat",
                },
                {
                    description: "View verified contact details",
                },
                {
                    description: "Profile details (Picture should be hidden)",
                },
                {
                    description: "Direct Messages to partners (20 Weekly)",
                },
                {
                    description: "Unlock access to advanced search",
                },
                {
                    description: "Profile details (Picture should be hidden)",
                },
                {
                    description: "Unlock 05 contact views",
                },
            ],
            btnText: "Update Now"
        },
        {
            param: "Platinum",
            plan: "Platinum Plan",
            type: "150AED m / 400AED 3m / 500 AED 6m",
            features: [
                {
                    description: "View verified contact details",
                },
                {
                    description: "Special offers from Partners",
                },
                {
                    description: "Direct Messages to partners",
                },
                {
                    description: "Unlock access to advanced search",
                },
                {
                    description:
                        "Appear on top of the list for 24 hours with 3 free spotlights",
                },
                {
                    description:
                        "Access full Profile (Picture should not be downloadable)",
                },
                {
                    description: "Connect instantly via Chat",
                },
                {
                    description: "Get up to 2x more matches daily",
                },
                {
                    description: "View up to 3x profiles daily",
                },
                {
                    description: "Unlock 20 contact views",
                },
            ],
            btnText: "Update Now"
        },
    ]);
    const tempPlan = ['Silver', 'Gold', 'Platinum']

    useEffect(() => {
        let plancheck = user?.subscription_plan?.plan_name
        if (plancheck) {
            let checkIndex = tempPlan.indexOf(plancheck)
            let updatedData = plans.slice(checkIndex + 1, plans?.length)
            setPlans(updatedData)
        }
    }, [])
    return (
        <div className='static-pages update-my-plan-page'>
            <div className="d-none d-md-block">
                <SettingHeader title="Membership" />
            </div>

            <div className="main-wrapper">
                <Container>
                    <Row>
                        <Col xl={12}>
                            <div className="content">
                                <h2 className='color-blue mb-4'>Update Your Plan</h2>
                                <p className='color-grey'>
                                    Choose, Update and Upgrade your plan:
                                </p>
                                <br></br>
                                <p className='color-grey'>
                                    At Rishtey & Shadi, we recognize the distinctiveness of every love story, including yours. Dive into
                                    our specially tailored subscription plans, crafted to align with your personal needs, making every
                                    phase of your matchmaking experience as smooth and delightful as the romance you&#39;re on the brink
                                    of finding. Choose the plan that resonates with your aspirations and embark on a captivating
                                    journey. The ideal love story you seek is merely a decision away.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        {
                            plans?.map((item, index) => (
                                <Col key={index} sm={6} lg={4} className='mb-4'>
                                    <PlanCard data={item} showBtn={true} btnText="UPDATE PLAN" link="/setting/payment" />
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default UpdateMyPlanPage