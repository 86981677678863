import React from 'react'
import { contactUs } from '../constants/content'
import IntroductionBanner from '../components/static/IntroductionBanner'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Assets from '../constants/images'
import ContactCard from '../components/static/ContactCard'

const ContactPage = () => {
    return (
        <div className='static-pages contact-page'>
            {/* <IntroductionBanner
                title={contactUs.title}
                description={contactUs.description}
                withBtns={false}
            /> */}

            <div className="contact">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="banner">
                                <Image className='w-100' src={Assets.ContactUsImg} />
                            </div>
                        </Col>

                        <Col xs={12} xl={10}>
                            <div className="titles">
                                <h4 className='lh-0-ls-5'>LET'S TALK</h4>
                                <h1 className='mt-3'>How Can We Assist You Today?</h1>
                                <h4 className='mt-3'>
                                    At Rishtey & Shadi, we believe that every great love story begins with a conversation. Whether
                                    you're curious about our wedding planning services, eager to explore our matchmaking options, or
                                    have a specific inquiry we offer compassionate guidance, weaving dreams into reality.
                                </h4>
                            </div>
                        </Col>
                    </Row>

                    <div className="mb-100">
                        <ContactCard />
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ContactPage