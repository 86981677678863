import React, { useState, useEffect } from "react";
import SettingHeader from "../components/static/SettingHeader";
import { Col, Container, Row } from "react-bootstrap";
import Search from "../components/chat/Search";
import ChatUsersList from "../components/chat/ChatUsersList";
import ChatHeader from "../components/chat/ChatHeader";
import MessagesWrapper from "../components/chat/MessagesWrapper";
import UploadedFiles from "../components/chat/UploadedFiles";
import MessageInput from "../components/chat/MessageInput";
import NoChatSelected from "../components/chat/NoChatSelected";
import { useForm } from "react-hook-form";
import socketIO from "socket.io-client";
import { GetTokenLocalStorage } from "../services/localStorage/localStorage";
import { uploadImageAPI } from "../services/uploadimage";

const MyMessagesPage = () => {
  const [socket, setSocket] = useState(null);
  const [room, setRoom] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [updateUser, setUpdate] = useState(0);

  const { control, reset, watch, setValue, formState: { errors } } = useForm({ mode: "onChange" });
  const [message, search] = watch(["message", "search"]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const newSocket = socketIO.connect("http://localhost:5000", {
      extraHeaders: {
        authorization: GetTokenLocalStorage(),
      },
    });
    setSocket(newSocket);
    console.log(newSocket, 'socket connection established');

    newSocket.emit("join_rooms");

    return () => {
      newSocket.disconnect();
      console.log('socket connection closed');
    };
  }, []);

  const sendMessage = async () => {
    let file = [];
    for (let index = 0; index < files.length; index++) {
      const item = files[index];
      console.log(files, 'files');

      const formData = new FormData();
      const key = `image`;
      formData.append(key, item);

      try {
        const res = await uploadImageAPI(formData);
        const responseImage = res?.data?.data?.filename;

        file.push({
          url: responseImage,
          type: res?.data?.data?.mimetype.split("/")[0],
        });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    const data = {
      room_id: room,
      message: message,
      message_type: files.length > 0 ? "file" : "text",
      file: file,
    };
    console.log(data, 'emitting message data');
    socket.emit("send_message", data);
    reset();
    setFiles([]);
  };

  return (
    <div className="static-pages my-messages-page">
      <div className="d-none d-md-block">
        <SettingHeader title="Messages" />
      </div>

      <Container>
        <Row>
          <Col xs={12} md={5} lg={4} xl={3} className="pe-md-0 mt-2">
            <div className="left-panel">
              <ChatUsersList
                socket={socket}
                setRoom={setRoom}
                room={room}
                setSelectedUser={setSelectedUser}
                updateUser={updateUser}
              />
            </div>
          </Col>

          <Col xs={12} md={7} lg={8} xl={9} className="px-md-0 mt-4 mt-md-0">
            <div className="mb-5 mb-md-0 right-panel">
              {room !== "" && <ChatHeader selectedUser={selectedUser} />}
              {room !== "" && (
                <MessagesWrapper
                  socket={socket}
                  room={room}
                  setUpdate={setUpdate}
                  updateUser={updateUser}
                  selectedUser={selectedUser}
                />
              )}
              {room !== "" && (
                <UploadedFiles files={files} setFiles={setFiles} />
              )}
              {room !== "" && (
                <MessageInput
                  control={control}
                  errors={errors}
                  files={files}
                  setFiles={setFiles}
                  sendMessage={sendMessage}
                />
              )}
              {room === "" && <NoChatSelected />}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyMessagesPage;
