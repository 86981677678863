import React, { useEffect, useState } from 'react'
import Assets from '../../constants/images'
import { GetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import moment from 'moment'
import { constant } from "../../utils/constants";
import { MessageLoader } from '../loader/Loader';

const ChatUsersList = ({ socket, setRoom, room, setSelectedUser, updateUser }) => {
    const [loading, setLoading] = useState(false);
    const [threads, setThreads] = useState([]);
    const localUser = GetAuthUserLocalStorage();

    const getThreads = async () => {
        await socket.emit('get_threads');
    };

    useEffect(() => {
        if (socket) {
            getThreads();
        }
    }, [socket, updateUser]);

    useEffect(() => {
        const handleGetThreads = (dt) => {
            setLoading(true);
            const users = dt?.data.map((dt) => ({
                user: localUser?._id === dt?.requested_to?._id ? dt?.requested_by : dt?.requested_to,
                last_message: dt?.last_message,
                _id: dt?._id,
                unread_count: dt?.unread_count
            }));
            setLoading(false);
            setThreads(users);
        };

        if (socket) {
            socket.on('_get_threads', handleGetThreads);
        }

        return () => {
            if (socket) {
                socket.off('_get_threads', handleGetThreads);
            }
        };
    }, [socket]);

    return (
        <div className='chat-users-list'>
            <ul>
                {!loading && threads.length > 0 ? (
                    threads.map((dt, ind) => (
                        <li
                            key={ind}
                            className={dt?._id === room ? 'active' : ''}
                            onClick={() => {
                                setSelectedUser(dt);
                                setRoom(dt?._id);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="d-flex align-items-center">
                                <div className="img-wrapper">
                                    <img
                                        src={dt?.user?.images?.length > 0 ? `${constant.BASE_URL}/${dt?.user.images[0]}` : Assets.GeneralPlaceholderImg}
                                        alt=""
                                        onError={(e) => (e.target.src = Assets.ProfilePlaceholder)}
                                    />
                                    <div className={`status ${dt?.user?.status}`}></div>
                                </div>
                                <div className="content ms-3 w-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className='name fw-700'>{dt?.user?.first_name}</p>
                                        <span className='time'>
                                            {moment(new Date(dt?.last_message?.createdAt)).fromNow() !== 'Invalid date' ? moment(new Date(dt?.last_message?.createdAt)).fromNow() : ''}
                                        </span>
                                    </div>
                                    <div className="mt-1 d-flex align-items-center justify-content-between">
                                        <span className='message'>{dt?.last_message?.message}</span>
                                        {dt?.unread_count > 0 ? <span className='counter '>{dt?.unread_count}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                        className=''
                    >
                        <MessageLoader />
                        <p>Loading..</p>
                    </div>
                )}
            </ul>
        </div>
    );
};

export default ChatUsersList;