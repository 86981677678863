import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const MissionVision = () => {
  return (
    <div className="mission-vision">
      <Container>
        <Row>
          <Col xl={5}>
            <div>
              <h1>Our Mission</h1>
              <h4 className="mt-3 mt-xl-4">
                Our mission is to unite hearts and foster enduring connections
                by discovering ideal match. We're dedicated to kindling love,
                creating lifelong bonds, and crafting beautiful tales of
                togetherness. Offering an accessible wedding ceremonial planner
                to create cherished moments, turning dreams into stunning
                realities. We endeavour to coordinate seamless, personalized
                wedding ceremonies, encapsulating each couple's distinct love
                story and forging timeless memories.
              </h4>
            </div>
          </Col>

          <Col xl={{ span: 5, offset: 2 }} className="mt-5 mt-xl-0">
            <div>
              <h1>Our Vision</h1>
              <h4 className="mt-3 mt-xl-4">
                At Rishtey and Shadi.com, our vision is to lead individuals to
                meaningful connections through desired matches, nurturing
                timeless love stories in a supportive community. We strive to
                curate unforgettable celebrations filled with love, offering an
                easy-to-use wedding ceremony planner to craft dream weddings
                that exceed expectations with personalized charm and guidance.
                We aim to be the guiding hand in crafting dream weddings that
                exceed expectations.
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MissionVision;
