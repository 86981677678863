import React, { useContext, useEffect } from 'react'
import IntroductionBanner from '../components/static/IntroductionBanner'
import AboutWhoWeAreAndChooseUs from '../components/static/AboutWhoWeAreAndChooseUs'
import { aboutUs, home } from '../constants/content'
import FindMatch from '../components/static/FindMatch'
import Services from '../components/static/Services'
import { DropDown } from '../services/dropdown'
import { countryContext, matchParamsContext, matchesDataContext, religionContext } from '../App'
import { GetMatches } from '../services/matches'

const HomePage = () => {


    return (
        <div className='static-pages home-page'>
            <IntroductionBanner
                title={home.title}
                description={home.description}
                withBtns={true}
            // showVideo={true}
            />
            <AboutWhoWeAreAndChooseUs
                title={aboutUs.title}
                subHeading={aboutUs.subHeading}
                description={aboutUs.description}
                btnTextOne={aboutUs.btnTextOne}
                btnTextTwo={aboutUs.btnTextTwo}
                img={aboutUs.img}
            />
            <FindMatch />
            <Services />
        </div>
    )
}

export default HomePage