import React, { useEffect, useState } from 'react'
import Assets from '../../constants/images'
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { constant } from "../../utils/constants";

const ChatHeader = ({ selectedUser }) => {
    let localUser = GetAuthUserLocalStorage()
    const [user, setUser] = useState({})
    useEffect(() => {
        setUser(selectedUser?.user)
    }, [selectedUser])

    return (
        <div className='chat-header'>
            <div className="d-flex align-items-center">
                <div className="img-wrapper">
                    <img src={user?.images && user?.images.length > 0 ? `${constant.BASE_URL}/${user?.images && user.images[0]}` : Assets.GeneralPlaceholderImg} alt="" onError={(e) => e.target.src = Assets.ProfilePlaceholder} />
                    <div className={`${selectedUser?.user?.status === "offline" ? 'offline' : 'online'}`}></div>
                </div>

                <div className="content ms-3">
                    <h4 className='fw-700 color-blue'>{user?.first_name} {user?.last_name}</h4>
                    <span className='fw-500 color-black'>{user?.age} - {user?.country}</span>
                    <div className='content-status d-flex'>
                        <span className={`${selectedUser?.user?.status === "offline" ? 'offline' : 'online'}`}></span>
                        <p>{selectedUser?.user?.status}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatHeader