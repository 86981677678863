import React from 'react'
import IntroductionBanner from '../components/static/IntroductionBanner'
import AboutWhoWeAreAndChooseUs from '../components/static/AboutWhoWeAreAndChooseUs'
import { about, whoWeAre, whyChooseUs } from '../constants/content'
import MissionVision from '../components/static/MissionVision'
import Testimonials from '../components/static/Testimonials'

const AboutPage = () => {
    return (
        <div className='static-pages home-page'>
            {/* <IntroductionBanner
                title={about.title}
                description={about.description}
                withBtns={false}
            /> */}
            <AboutWhoWeAreAndChooseUs
                title={whoWeAre.title}
                subHeading={whoWeAre.subHeading}
                description={whoWeAre.description}
                img={whoWeAre.img}
            />
            <MissionVision />
            <Testimonials />
            <AboutWhoWeAreAndChooseUs
                title={whyChooseUs.title}
                subHeading={whyChooseUs.subHeading}
                description={whyChooseUs.description}
                btnTextOne={whyChooseUs.btnTextOne}
                btnTextTwo={whyChooseUs.btnTextTwo}
                img={whyChooseUs.img}
            />
        </div>
    )
}

export default AboutPage