import React, { useContext, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../form/CustomInput'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { NavLink, useNavigate } from 'react-router-dom'
import { ArrowIcon } from '../../constants/svgs'
import { error, success } from '../../constants/msg'
import { SetAuthUserLocalStorage, SetTokenLocalStorage } from '../../services/localStorage/localStorage'
import { Login } from '../../services/auth/auth'
import { fogotPassContext } from '../../App'

const SigninForm = () => {
    const { handleSubmit, register, control, reset, watch, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' })
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [forgotData, setForgotData] = useContext(fogotPassContext)

    const navigate = useNavigate()

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const res = await Login(data)
            success(res?.data?.message)
            // data.type = "registration"
            setTimeout(() => {
                if (res?.data?.data?.isProfileCompleted) {
                    navigate('/find-match')
                }
                else {
                    navigate('/complete-profile')
                }
            }, 1200)
            setLoading(false)
            SetAuthUserLocalStorage(res?.data?.data)
            SetTokenLocalStorage(res?.data?.token)
        } catch (e) {
            setLoading(false)
            if (e?.response?.data?.message === 'Please verify you account.') {
                navigate('/otp-verification')
                error(e.response.data.message);
                const regEmail = {
                    'email':
                        data?.email
                }
                setForgotData(regEmail)
            }
            else {
                error(e.response.data.message);
            }
        }
    }
    return (
        <div className='auth signin-form'>
            <h1>Sign In</h1>
            <h3 className='mt-4'>
                Welcome back to Rishtey and Shadi.
                Enter your credentials to access your account.
            </h3>

            <div>
                <Form className='form-wrapper mt-5' onSubmit={handleSubmit(onSubmit)}>
                    {/* EMAIL INPUT */}
                    <div className='mb-25'>
                        <Controller
                            control={control}
                            name="email"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                },
                                maxLength: {
                                    value: VALIDATIONS.EMAIL,
                                    message: VALIDATIONS_TEXT.EMAIL
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                }
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Email Address"
                                    placeholder="Email Address"
                                    type="email"
                                    required={true}
                                    maxLength={VALIDATIONS.EMAIL}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}
                    </div>

                    {/* PASSWORD INPUT */}
                    <div>
                        <Controller
                            control={control}
                            name="password"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                            }}
                            render={({ field }) => (
                                <div className="password-input">
                                    <CustomInput
                                        {...field}
                                        label="Password"
                                        placeholder="Password"
                                        type={showPassword ? "text" : "password"}
                                        required={true}
                                        minLength={VALIDATIONS.PASSWORD_MIN}
                                        maxLength={VALIDATIONS.PASSWORD_MAX}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    <span className='eye-icon' onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                    </span>
                                </div>
                            )}
                        />
                        {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}
                    </div>

                    {/* BUTTON AND LINKS */}
                    <div>
                        <NavLink className="d-block mt-3 mb-5 text-end color-grey" to="/forgot-password">FORGOT PASSWORD?</NavLink>
                        <Button className="btn-solid btn-blue" type="submit" disabled={loading}>
                            SIGN IN
                            <div className="circle">
                                <ArrowIcon />
                            </div>
                        </Button>
                    </div>
                </Form>

                <div>
                    <span>Don’t have an account? &nbsp;</span>
                    <NavLink to="/register" className="color-dark">REGISTER YOUR ACCOUNT</NavLink>
                </div>
            </div>
        </div>
    )
}

export default SigninForm