import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowIcon, ArrowLeft } from "../../constants/svgs";
import { EmptyLocalStorage, GetAuthUserLocalStorage, GetTokenLocalStorage } from "../../services/localStorage/localStorage";
import { toast } from "react-toastify";
import { error, success } from "../../constants/msg";
import PaymentForm from "./cardInfo";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../services/auth/auth";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import PaymentFormModal from "./cardInfo";
const stripePromise = loadStripe(
  "pk_test_51OWwiZDRaumk2PQx6yrnaViJXeBY5FxHsrTMfz0XlHF9ISlD6iGPV3ON4syvkjplChkkem7b5JXAHxFa1tvBTAgM00Djz72rso"
);
const PaymentProcess = ({
  paymentPopup,
  setPaymentProcess,
  selectedPaymentPlan,
}) => {
  const [loadForms, setLoadForm] = useState(false);
  const [paymentForm, setPaymentForm] = useState(false);
  const user = GetAuthUserLocalStorage()

  return (
    <>
      {/* {loadForms ? (
        <Elements stripe={stripePromise}>
          <PaymentForm />
        </Elements>
      ) : null} */}
      <Elements stripe={stripePromise}>
        <Modal
          className="theme-popup logout-popup"
          show={paymentPopup}
          onHide={() => setPaymentProcess(false)}
          size="md"
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <div>
              {user && user?.subscription_plan?.plan_id === null ? '' :
                <div className="header text-center mb-4">
                  <span><b>Your Selected Pan: </b></span>
                  <span style={{
                    fontSize: '20px'
                  }}>{(user?.subscription_plan?.plan_name)?.toUpperCase()}</span>
                </div>
              }

              <div className="header text-center mb-4">
                <h2>Payment Procedure</h2>
                <h6 className="mt-1">for</h6>
                <h5 className="mt-1">{selectedPaymentPlan?.plan}</h5>
              </div>

              <div className="">
                <PaymentFormModal
                  paymentPopup={paymentPopup}
                  setPaymentProcess={setPaymentProcess}
                  stripePromise={stripePromise}
                />
                {/* <Button
                  className="btn-outline btn-dark"
                  onClick={() => setPaymentProcess(false)}
                >
                  <div className="circle">
                    <ArrowLeft />
                  </div>
                  CANCEL
                </Button>

                <Button
                  onClick={paymentsubmit}
                  className="btn-solid btn-blue ms-4"
                >
                  ADD CARD
                  <div className="circle">
                    <ArrowIcon />
                  </div>
                </Button> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Elements>
    </>
  );
};

export default PaymentProcess;
