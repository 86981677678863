import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import CustomInput from "../form/CustomInput";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../../constants/app-constants";
import { Button, Col, Row } from "react-bootstrap";
import { ArrowIcon, ArrowLeft, DeleteIcon } from "../../constants/svgs";
import { IoMdAdd } from "react-icons/io";
import CustomSelect from "../form/CustomSelect";

const SelectedVendorsDetailsForm = ({
  watch,
  control,
  formState,
  setValue,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { errors, isDirty, isValid, isSubmitting } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "eventPlanning",
  });
  const [guestCount, setGuestCount] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);

  const [
    brideName,
    groomName,
    budget,
    programs,
    guestscounts,
    vendors,
    eventPlanning,
    guests,
    collaboratedTeam,
    currency,
    religion,
    weddingTemplates,
  ] = watch([
    "brideName",
    "groomName",
    "budget",
    "programs",
    "guestscounts",
    "vendors",
    "eventPlanning",
    "guests",
    "collaboratedTeam",
    "currency",
    "religion",
    "weddingTemplates",
  ]);

  console.log(programs?.length, 'programsprograms');
  const addEventDetails = () => {
    append({ vendorname: "", eventname: "" });
  };
  useEffect(() => {
    const res = programs?.map((dt, index) => ({
      value: dt?.name,
      label: dt?.name,
    }));
    setGuestCount(res);
  }, [programs]);

  useEffect(() => {
    const res = vendors
      ?.filter((dt) => dt.type === "banquets")
      .map((dt, index) => ({
        value: dt?.type,
        label: dt?.name,
      }));
    setSelectedVendor(res);
  }, [vendors]);

  return (
    <div className="guest-list-form">
      <Row>
        <Col xs={12} xl={11} className='border-bottom pb-3 mb-4'>
          <h3 className='fw-800 color-blue'>EVENT PLANNING</h3>
          <i className='italic-tag-line fw-400 mb-5 color-grey'>Assign vendor for each program</i>
        </Col>

        <Col xs={12} xl={11}>
          <Row>
            <Col xs={12} xl={11}>
              <div className="mb-25 d-flex align-items-center justify-content-between">
                <div className=" d-flex align-items-center">
                  {/* <div className="add-cirlce" onClick={addGuestCount}> */}
                  <div
                    className={`add-cirlce ${programs?.length > 0
                      ? eventPlanning?.length === programs?.length
                        ? "d-none"
                        : ""
                      : ""
                      }`}
                    onClick={addEventDetails}
                  >
                    <IoMdAdd size={22} />
                  </div>
                  <h6 className="ms-3 color-dark">Vendor Assigned</h6>
                </div>

                <div className=" d-flex align-items-center">
                  <div className="guest-cirlce">
                    <h6>{fields?.length}</h6>
                  </div>
                  <h6 className="ms-3 color-dark">Event Added</h6>
                </div>
              </div>
            </Col>
          </Row>
          <Row className={`mb-25 ${fields?.length > 0 ? "d-flex" : "d-none"}`}>
            <Col xs={6}>
              <span className="d-block w-100 border-bottom pb-3 mb-3">
                <b>Vendor</b>
              </span>
            </Col>

            <Col xs={6}>
              <span className="d-block w-100 border-bottom pb-3 mb-3">
                <b>Event</b>
              </span>
            </Col>
          </Row>

          <Row>
            {fields.map((guest, index) => (
              <React.Fragment>
                {/* NAME INPUT */}
                <Col xs={6} key={index}>
                  <div className="mb-25">
                    <Controller
                      control={control}
                      name={`eventPlanning[${index}].vendorname`}
                      rules={{
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.GENDER_REQUIRED,
                        },
                      }}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          label="Vendor"
                          placeholder="Vendor"
                          options={selectedVendor}
                          required={true}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e); // Invoke the field.onChange function and pass the selected value
                          }}
                        />
                      )}
                    />
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="mb-25 d-flex">
                    <div style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name={`eventPlanning[${index}].eventname`}
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATIONS_TEXT.GENDER_REQUIRED,
                          },
                        }}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            label="Program"
                            placeholder="Program"
                            options={guestCount}
                            required={true}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e); // Invoke the field.onChange function and pass the selected value
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="ms-3 mt-3" onClick={() => remove(index)}>
                      <DeleteIcon />
                    </div>
                  </div>
                </Col>
              </React.Fragment>
            ))}
          </Row>

          {/* NEXT STEP BUTTON */}
          <div className="mt-5 d-flex flex-column flex-sm-row align-items-sm-center ">
            <Button
              onClick={handlePreviousStep}
              className="btn-outline btn-dark"
              type="button"
            >
              <div className="circle">
                <ArrowLeft />
              </div>
              PREVIOUS STEP
            </Button>

            <Button
              onClick={handleNextStep}
              className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue"
              type="submit"
              disabled={!isDirty || !isValid || fields?.length == 0}
            >
              NEXT STEP
              <div className="circle">
                <ArrowIcon />
              </div>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SelectedVendorsDetailsForm;
