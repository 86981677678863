import React, { useState, useEffect, useContext } from 'react'
import SettingHeader from '../components/static/SettingHeader'
import { Col, Container, Row } from 'react-bootstrap'
import SettingSidebar from '../components/static/SettingSidebar'
import CompleteProfileForm from '../components/static/CompleteProfileForm'
import MatchCard from '../components/static/MatchCard'
import Assets from '../constants/images'
import { getMyMatches } from '../services/matches'
import { reloadMatchesContext } from '../App'

const MyMatchesPage = () => {
    const [matches, setMatches] = useState([])
    const [reloadMatches, setReloadMatches] = useContext(reloadMatchesContext)

    useEffect(() => {
        GetMyMatches()
    }, [])

    useEffect(() => {
        if (reloadMatches) {
            GetMyMatches()
        }
    }, [reloadMatches])

    const GetMyMatches = async () => {
        const res = await getMyMatches()
        const response = res?.data?.data[0]?.favourite_profiles
        setMatches(response)
    }

    return (
        <div className='static-pages my-match-page'>
            <div className="d-none d-md-block">
                <SettingHeader />
            </div>

            <Container>
                <Row>
                    <Col md={3} lg={3} xl={3} className='d-none d-md-block'>
                        <SettingSidebar />
                    </Col>

                    <Col md={9} lg={9} xl={9}>
                        <div className="content py-5 py-md-5 ps-md-5">
                            <h2 className='color-blue mb-4'>My Matches</h2>
                            <Row>
                                {
                                    matches?.length > 0 ?
                                        matches?.map((item, index) => (
                                            <Col key={index} xs={12} sm={6} lg={4} xl={4} className='mb-50'>
                                                <MatchCard item={item} index={index} data={matches} setData={setMatches} />
                                            </Col>
                                        ))
                                        : <center>
                                            <h3>No Data Found</h3>
                                        </center>
                                }
                            </Row>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyMatchesPage