import React from 'react'
import { termsAndConditions } from '../constants/content'
import IntroductionBanner from '../components/static/IntroductionBanner'
import { Col, Container, Row } from 'react-bootstrap'

const TermsAndConditionsPage = () => {
    return (
        <div className='static-pages terms-and-conditions-page'>
            <IntroductionBanner
                title={termsAndConditions.title}
                description={termsAndConditions.description}
                withBtns={false}
            />

            <div className="content-wrapper">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className='content'>
                                <div className="block mb-4">
                                    <h4>Terms and Conditions</h4>
                                    <p className='mt-2'>
                                        Welcome to our Dara! Before using our website, please read these terms and conditions carefully. By using our website,
                                        you agree to be bound by these terms and conditions.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Introduction</h4>
                                    <p className='mt-2'>
                                        These terms and conditions govern your use of our website. By using our website, you accept these terms and conditions in full. If you disagree with these terms and
                                        conditions or any part of these terms and conditions, you must not use our website.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Intellectual Property</h4>
                                    <p className='mt-2'>
                                        The content on this website, including but not limited to, text, graphics, logos, images, and software, is protected by copyright and other intellectual property laws.
                                        You must not copy, distribute, reproduce, or modify any part of our website without our prior written consent.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>User Content</h4>
                                    <p className='mt-2'>
                                        You are solely responsible for the content that you post on our website. You represent and warrant that you own or have the necessary licenses, rights, consents,
                                        and permissions to use and authorize us to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all user content.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Disclaimers and Limitations of Liability</h4>
                                    <p className='mt-2'>
                                        We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website
                                        or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Indemnification</h4>
                                    <p className='mt-2'>
                                        You agree to indemnify and hold harmless our website and its affiliates, officers, agents, and employees from any claim or demand, including reasonable attorneys' fees,
                                        made by any third party due to or arising out of your breach of these terms and conditions, or your violation of any law or the rights of a third party.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Governing Law and Jurisdiction</h4>
                                    <p className='mt-2'>
                                        These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which our website is located, without giving effect
                                        to any principles of conflicts of law.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Modification of Terms</h4>
                                    <p className='mt-2'>
                                        We may revise these terms and conditions at any time without notice. By using our website, you agree to be bound by the current version of these terms and conditions.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Termination</h4>
                                    <p className='mt-2'>
                                        We reserve the right to terminate your access to our website, without notice or liability, for any reason whatsoever, including without limitation if you breach these terms and conditions.
                                    </p>
                                </div>

                                <div className="block mb-4">
                                    <h4>Contact Us</h4>
                                    <p className='mt-2'>
                                        If you have any questions about these Terms and Conditions, please contact us at info@rishteyandshadi.com.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage