import React, { useState, useEffect, useContext } from "react";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../../constants/app-constants";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../form/CustomInput";
import CustomSelect from "../form/CustomSelect";
import { ArrowIcon } from "../../constants/svgs";
import { Button, Form } from "react-bootstrap";
import CustomFileUploader from "../form/CustomFileUploader";
import { DropDown } from "../../services/dropdown";
import { genderSelectedContext } from "../../App";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";

const BasicInfoForm = ({ control, formState, setValue, profileData, handleStep, country, religion }) => {
  const { errors, isDirty, isValid, isSubmitting } = formState;
  const [genderSelected, setGenderSelected] = useContext(genderSelectedContext);
  const [gender, setGender] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    GetLanguageDropDown();
    GetGenderDropDown();
    GetMaritalStatusDropDown();
  }, []);


  const GetLanguageDropDown = async () => {
    const params = {
      type: "language",
    };
    const res = await DropDown(params);
    const response = res?.data?.data?.map((dt) => ({
      value: dt?._id,
      label: dt?.name,
    }));
    setLanguage(response);
  };
  const GetGenderDropDown = async () => {
    const params = {
      type: "gender",
    };
    const res = await DropDown(params);
    const response = res?.data?.data?.map((dt) => ({
      value: dt?._id,
      label: dt?.name,
    }));
    setGender(response);
  };

  const GetMaritalStatusDropDown = async () => {
    const params = {
      type: "martial-status",
    };
    const res = await DropDown(params);
    const response = res?.data?.data?.map((dt) => ({
      value: dt?._id,
      label: dt?.name,
    }));
    setMaritalStatus(response);
  };


  return (
    <div className="basic-info-form">
      {/* FILE UPLOADED INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="image"
          // rules={{
          //   required: {
          //     value: true,
          //     message: VALIDATIONS_TEXT.IMAGE_REQUIRED,
          //   },
          // }}
          render={({ field }) => (
            <CustomFileUploader
              {...field}
              name="image"
              value={field.value}
              onChange={field.onChange}
              setValue={setValue}
              multiple={true}
              accept="image/*"
            />
          )}
        />
        {errors.image && (
          <small className="text-start d-block text-danger mt-1">
            {errors.image.message}
          </small>
        )}
      </div>

      {/* AGE INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="age"
          defaultValue={profileData?.age ? profileData?.age : ''}
          rules={{
            required: {
              value: true,
              message: VALIDATIONS_TEXT.AGE_REQUIRED,
            },
            pattern: {
              value: /^(1[89]|[2-9]\d|\d{3,})$/,
              message: VALIDATIONS_TEXT.AGE,
            },
          }}
          render={({ field }) => (
            <CustomInput
              {...field}
              label="Age"
              placeholder="Age *"
              type="number"
              required={true}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {errors.age && (
          <small className="text-start d-block text-danger mt-1">
            {errors.age.message}
          </small>
        )}
      </div>

      {/* GENDER INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="gender"
          rules={{
            required: {
              value: true,
              message: VALIDATIONS_TEXT.GENDER_REQUIRED,
            },
          }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              label="Gender"
              placeholder="Gender *" 
              options={gender}
              required={true}
              value={field.value}
              onChange={(e) => {
                setGenderSelected(e["label"]);
                field.onChange(e); // Invoke the field.onChange function and pass the selected value
              }}
            />
          )}
        />
        {errors.gender && (
          <small className="text-start d-block text-danger mt-1">
            {errors.gender.message}
          </small>
        )}
      </div>

      {/* MOTHER TOUNGE INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="motherToungh"
          rules={{
            required: {
              value: true,
              message: VALIDATIONS_TEXT.SPOKEN_LANGUAGE_REQUIRED,
            },
          }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              label="Mother Toungh"
              placeholder="Mother Toungh *"
              options={language}
              required={true}
              value={field.value}
              onChange={field.onChange}
              isMulti={true}
            />
          )}
        />
        {errors.motherToungh && (
          <small className="text-start d-block text-danger mt-1">
            {errors.motherToungh.message}
          </small>
        )}
      </div>

      {/* RELIGION INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="religion"
          rules={{
            required: {
              value: true,
              message: VALIDATIONS_TEXT.RELIGION_REQUIRED,
            },
          }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              label="Religion"
              placeholder="Religion *"
              options={religion}
              required={true}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {errors.religion && (
          <small className="text-start d-block text-danger mt-1">
            {errors.religion.message}
          </small>
        )}
      </div>

      {/* CAST INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="cast"
          defaultValue={profileData?.cast ? profileData?.cast : ''}
          rules={{
            minLength: {
              value: VALIDATIONS.CAST_MIN,
              message: VALIDATIONS_TEXT.CAST_MIN,
            },
            maxLength: {
              value: VALIDATIONS.CAST_MAX,
              message: VALIDATIONS_TEXT.CAST_MAX,
            },
            required: {
              value: true,
              message: VALIDATIONS_TEXT.CAST_REQUIRED,
            },
          }}
          render={({ field }) => (
            <CustomInput
              {...field}
              label="Cast"
              placeholder="Cast *"
              type="text"
              minLength={VALIDATIONS.CAST_MIN}
              maxLength={VALIDATIONS.CAST_MAX}
              required={true}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {errors.cast && (
          <small className="text-start d-block text-danger mt-1">
            {errors.cast.message}
          </small>
        )}
      </div>

      {/* MARTIAL STATUS INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="martialStatus"
          rules={{
            required: {
              value: true,
              message: VALIDATIONS_TEXT.MARTIAL_STATUS_REQUIRED,
            },
          }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              label="Martial Status"
              placeholder="Martial Status *"
              options={maritalStatus}
              required={true}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {errors.martialStatus && (
          <small className="text-start d-block text-danger mt-1">
            {errors.martialStatus.message}
          </small>
        )}
      </div>

      {/* COUNTRY INPUT */}
      <div className="mb-25">
        <Controller
          control={control}
          name="country"
          rules={{
            required: {
              value: true,
              message: VALIDATIONS_TEXT.COUNTRY_REQUIRED,
            },
          }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              label="Country"
              placeholder="Country *"
              options={country}
              required={true}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {errors.country && (
          <small className="text-start d-block text-danger mt-1">
            {errors.country.message}
          </small>
        )}
      </div>

      {/* NEXT STEP BUTTON */}
      <div className="mt-5">
        <Button
          onClick={handleStep}
          className="btn-solid btn-blue"
          type="submit"
          disabled={!isDirty || !isValid}
        >
          NEXT STEP
          <div className="circle">
            <ArrowIcon />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default BasicInfoForm;
