import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ArroWhiteIcon } from "../../constants/svgs";
import { useNavigate } from "react-router-dom";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import VideoUrl from '../../assets/img/video.mp4'

const IntroductionBanner = ({ title, description, withBtns, showVideo }) => {
  const navigate = useNavigate();
  const loggedInUser = GetAuthUserLocalStorage()

  return (
    <div className="introduction-banner">
      {/* {
        showVideo &&
        <video src={VideoUrl} autoPlay={true} loop={true} muted={true} controls={false} className="video-banner"></video>
      } */}
      <Container style={{ position: 'relative', zIndex: 2 }} className="h-100">
        <Row className="h-100">
          <Col lg={9} xl={9} className="my-auto">
            <div className="content">
              <h1>{title}</h1>
              <h4 className="mt-4">{description}</h4>
            </div>

            <div
              className={`btn-wrapper d-flex flex-column flex-lg-row align-item-center mt-4 ${withBtns ? "d-block" : "d-none"
                }`}
            >
              <Button
                onClick={() =>
                  loggedInUser && loggedInUser?.isProfileCompleted ? navigate('/find-match') :
                    navigate("/complete-profile")}
                className="btn-outline btn-white mt-3 mt-lg-0 text-uppercase"
                type="button"
              >
                <div className="circle">
                  <ArroWhiteIcon />
                </div>
                Find your match
              </Button>

              <Button
                className="btn-outline btn-white text-uppercase ms-lg-3 mt-3 mt-lg-0"
                type="button"
                onClick={() => navigate("/my-wedding-projects")}
              >
                <div className="circle">
                  <ArroWhiteIcon />
                </div>
                Ceremonial Planner
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IntroductionBanner;
