import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Assets from "../../constants/images";
import { usePDF } from "react-to-pdf";
import { result } from "lodash";
import * as htmlToImage from 'html-to-image';
import { uploadImageAPI } from "../../services/uploadimage";
import { constant } from "../../utils/constants";
import { weddingFileContexts } from "../../App";
import html2canvas from 'html2canvas';


const WeddingCardInvitatoion = ({ watch, control, formState, setValue }) => {
  const componentRef = useRef();

  const styles = {
    cardContainer: {
      textAlign: "center",
      padding: "40px 20px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      cursor: 'pointer',
      backgroundColor: 'white'
    },
    logo: {
      width: "50%",
      height: "auto",
      marginBottom: "20px",
    },
    invitationtext: {},
    heading: {
      fontSize: "24px",
      margin: "10px 0",
    },
    text: {
      fontSize: "16px",
      margin: "5px 0",
    },
    printButton: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    bottomsection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    rsvpSection: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "start",
      marginTop: "20px",
      padding: "10px",
    },
    ceremonydetails: {
      alignItems: "center",
      marginTop: "20px",
      padding: "10px",
    },
    rsvpHeading: {
      fontSize: "18px",
      margin: "10px 0",
    },
  };

  const [
    brideName,
    groomName,
    budget,
    programs,
    guestscounts,
    vendors,
    eventPlanning,
    guests,
    collaboratedTeam,
    currency,
    religion,
    rsvp,
    weddingTemplates,
  ] = watch([
    "brideName",
    "groomName",
    "budget",
    "programs",
    "guestscounts",
    "vendors",
    "eventPlanning",
    "guests",
    "collaboratedTeam",
    "currency",
    "religion",
    "rsvp",
    "weddingTemplates",
  ]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero for single digit minutes
    return hours + ":" + minutes + " " + ampm;
  };

  const [pngUrls, setPngUrls] = useState([]);
  const [weddingFile, setWeddingFile] = useContext(weddingFileContexts)

  useEffect(() => {
    const generateJPEGs = async () => {
      const urls = await Promise.all(
        programs.map(async (dt, index) => {
          const jpeg = await htmlToImage.toBlob(document.getElementById(`pdf-content-${index}`));
          const url = URL.createObjectURL(jpeg);
          return url;
        })
      );
      setWeddingFile(urls);
      console.log(urls, 'urls');
    };

    generateJPEGs();
  }, [programs]);




  const downloadJPG = (index) => {
    const element = document.getElementById(`pdf-content-${index}`);
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.download = `my-image-${index}.jpeg`;
      link.href = canvas.toDataURL('image/jpeg', 0.9); // The second parameter is the quality (0-1)
      link.click();
    });
  };
  return (
    <>
      <div>
        {programs &&
          programs.map((dt, index) => {
            const banquetEvent = eventPlanning.find(
              (event) => event.eventname.value === dt.name
            );
            const banquetName = banquetEvent
              ? banquetEvent.vendorname.label
              : "Unknown Banquet";

            const RsvpEvent = rsvp.filter(
              (event) => event?.programname?.label === dt?.name
            );
            // const rsvpName = RsvpEvent
            //   ? RsvpEvent.vendorname.label
            //   : "Unknown Banquet";

            return (
              <div key={index} id={`pdf-content-${index}`} style={styles.cardContainer} className="mb-4"
                onClick={() => downloadJPG(index)}

              >
                <img
                  src={Assets?.RingLogo}
                  alt="Company Logo"
                  style={styles.logo}
                  className="mt-5"
                />
                <p style={styles.invitationtext}>You are cordially invited at</p>
                <h1 style={styles.heading}>
                  {brideName} & {groomName}
                </h1>
                <h1 style={styles.heading}>{`${dt?.name} Ceremony`}</h1>
                <p style={styles.text}>Date: {formatDate(dt?.date)}</p>
                <p style={styles.text}>Time: {formatTime(dt?.date)}</p>
                {/* Displaying the values for debugging */}
                {banquetEvent && (
                  <p>Event Name: {banquetEvent.eventname.value}</p>
                )}
                <p style={styles.text}>Venue: {banquetName}</p>
                {/* Add more content as needed */}
                <div style={styles.bottomsection}>
                  <div style={styles.rsvpSection}>
                    <h2 style={styles.rsvpHeading}>Awaiting To Welcome:</h2>
                    {RsvpEvent && RsvpEvent?.length > 0
                      ? RsvpEvent?.map((data) => {
                        return (
                          <>
                            <p>
                              Name:<b> {data?.name}</b>
                            </p>
                            <p>Email: {data?.email}</p>
                            <p>Cell: {data?.cell}</p>
                          </>
                        );
                      })
                      : ""}

                    {/* Add more details or form fields as needed */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

    </>

  );
};

export default WeddingCardInvitatoion;