import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const Loader = ({ style }) => {
    return (
        <div className={`loader ${style ? style : "white"}`}></div>
    )
}
const MessageLoader = () => {
    return (
        <>
            <ThreeDots
                visible={true}
                height="80"
                width="80"
                ariaLabel="comment-loading"
                wrapperStyle={{}}
                wrapperClass="comment-wrapper"
                color="#2C3E57"
                backgroundColor="#F4442E"
            />
        </>
    )
}

export {
    Loader,
    MessageLoader
}