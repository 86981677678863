import React, { useContext, useEffect, useState } from "react";
import SettingHeader from "../components/static/SettingHeader";
import { Button, Col, Container, Row } from "react-bootstrap";
import CreateProjectForm from "../components/static/CreateProjectForm";
import { useForm } from "react-hook-form";
import {
  DownloadIconBtn,
  EyeIconGreen,
  WhatsappIconBtn,
} from "../constants/svgs";
import {
  balanceBudgetContext,
  weddingFileContexts,
  weddingLoadingContext,
} from "../App";
import { useParams } from "react-router-dom";
import { GetUserById } from "../services/userProfile";
import { getProjectById } from "../services/projects";
import { constant, currencyOptions } from "../utils/constants";
const moment = require('moment');

const CreateNewProject = () => {
  const hookForm = useForm({ mode: "onChange" });
  const { setValue, watch, trigger } = hookForm;
  const [
    brideName,
    groomName,
    budget,
    programs,
    guestscounts,
    vendors,
    guests,
    collaboratedTeam,
    currency,
    religion,
    weddingTemplates,
  ] = watch([
    "brideName",
    "groomName",
    "budget",
    "programs",
    "guestscounts",
    "vendors",
    "guests",
    "collaboratedTeam",
    "currency",
    "religion",
    "weddingTemplates",
  ]);
  const [weddingProgram, setWeddingProgram] = useState([]);
  const [weddingProgramCount, setWeddingProgramCount] = useState([]);

  useEffect(() => {
    setWeddingProgram(programs);
  }, [programs]);

  useEffect(() => {
    setWeddingProgramCount(guestscounts);
  }, [guestscounts]);

  const familyCountSum =
    guests && guests.length > 0
      ? guests.reduce((sum, item) => {
        // Convert familycount to a number and add to the sum
        return sum + parseInt(item.familycount, 10);
      }, 0)
      : 0;
  // const [brideName, groomName, budget, prorambudget, programs, guests, collaboratedTeam] = watch(['brideName', 'groomName', 'budget', 'prorambudget', 'programs', 'guests', 'collaboratedTeam'])
  const [loading, setLoading] = useContext(weddingLoadingContext);
  console.log(loading, 'loading here 31333');
  const [activeStep, setActiveStep] = useState(1);
  const [pdf, setPdf] = useState("");
  const [balanceBudget, setBalanceBudget] = useState([]);
  const [guestCount, setGuestCount] = useState([])
  const [remainingGuest, setRemainingGuest] = useState([])
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const [weddingFile, setWeddingFile] = useContext(weddingFileContexts);
  const [project, setProject] = useState([]);
  const { id } = useParams();
  console.log(remainingGuest, 'remainingGuestremainingGuest');
  useEffect(() => {
    if (id) {
      GetProjectById();
    }
  }, [id]);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    try {
      const response = await fetch(`https://openexchangerates.org/api/currencies.json?app_id=YOUR_API_KEY`);
      const data = await response.json();
      const options = Object.entries(data).map(([code, name]) => ({ value: code, label: `${name} (${code})` }));
      setCurrencyOptions(options);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };
  const GetProjectById = async () => {
    const response = await getProjectById(id);
    setProject(response?.data?.data);
    setPdf(response?.data?.data?.plan_file)
    setWeddingFile(response?.data?.data?.wedding_card);
  };
  const defaultCurrncy = currencyOptions.find(
    (cur) => cur.value === project?.currency
  );
  const programData = project?.programs?.map((dt) => ({
    name: dt.name,
    budget: dt?.budget,
    date: moment(dt?.date)
  }));
  const defaultGuests = project?.Guests?.map((dt) => ({
    email: dt?.email_address,
    familycount: dt?.family_count,
    fname: dt?.first_name,
    lname: dt?.last_name,
    pname: { value: dt?.program_name, label: dt?.program_name }
  }));
  const defaultGuestsTotalCounts = project?.guest_count?.map((dt) => ({
    name: { value: dt?.program_name, label: dt?.program_name },
    familycount: dt?.family_count
  }))
  const defaultEventPlanning = project?.event_plan?.map((dt) => ({
    eventname: { value: dt?.program, label: dt?.program },
    vendorname: { value: dt?.vendors_name, label: dt?.vendors_name }
  }))
  const defaultColabratedTeam = project?.collaborated_team?.map((dt) => ({
    name: dt?.name,
    email: dt?.email_address,
    task: dt?.assigned_task,
    fname: dt?.first_name,
    lname: dt?.last_name,
    pname: { value: dt?.program_name, label: dt?.program_name },
  }));
  const defaultRsvp = project?.rsvp?.map((dt) => ({
    name: dt?.name,
    email: dt?.email,
    cell: dt?.contact,
    programname: { value: dt?.program_name, label: dt?.program_name },
  }));

  const defaultVendors = project?.vendors?.map((_id) => ({ _id }));
  console.log(project, 'proooooo2111');
  useEffect(() => {
    if (id && project) {
      setValue("brideName", project?.personal_information?.bride_name);
      setValue("groomName", project?.personal_information?.groom_name);
      setValue("currency", defaultCurrncy);
      setValue("budget", project?.budget);
      setValue("religion", { label: project?.religion });
      setValue("programs", programData);
      setValue("guestscounts", defaultGuestsTotalCounts);
      setValue("collaboratedTeam", defaultColabratedTeam);
      setValue("vendors", defaultVendors);
      setValue('guests', defaultGuests)
      setValue("eventPlanning", defaultEventPlanning)
      setValue("rsvp", defaultRsvp)

      trigger(["brideName", "groomName", "currency", "budget", "religion", "programs", "guestscounts", "collaboratedTeam", "vendors", "guests", "eventPlanning", "rsvp"]);
    }
  }, [id, project, setValue, trigger]);

  const handleWhatsAppShare = (pdflink) => {
    const pdfUrl = constant.BASE_URL + pdflink; // Replace 'https://example.com/' with the actual URL of your PDF file
    const whatsappLink = `whatsapp://send?text=${encodeURIComponent(
      "Project: " + pdfUrl
    )}`;

    const link = document.createElement("a");
    link.href = whatsappLink;
    link.target = "_blank"; // Open in a new tab
    link.click();
  };

  const handleDownload = (pdflink) => {
    const pdfUrl = `${"http://localhost:5000/" + pdflink}`;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "project.pdf"; // specify the filename
    link.target = "_blank"; // open link in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [steps, setSteps] = useState([
    {
      id: "1",
      step: "01",
      title: "Personal Information",
    },
    {
      id: "2",
      step: "02",
      title: "Budget",
    },
    {
      id: "3",
      step: "03",
      title: "Programs",
    },
    {
      id: "4",
      step: "04",
      title: "Guest Count",
    },
    {
      id: "5",
      step: "05",
      title: "Vendors",
    },
    {
      id: "6",
      step: "06",
      title: "Event Planning",
    },

    {
      id: "7",
      step: "07",
      title: "Guest list",
    },
    {
      id: "8",
      step: "08",
      title: "Collaborated Team",
    },
    {
      id: "9",
      step: "09",
      title: "RSVP Managment",
    },

    {
      id: "10",
      step: "10",
      title: "Ceremony card templates",
    },
    {
      id: "11",
      step: "11",
      title: "Overview",
    },
  ]);



  useEffect(() => {
    const res = guestscounts?.map((dt) => ({
      label: dt?.name['label'],
      totalCount: dt?.familycount
    }))
    setGuestCount(res)
  }, [guestscounts])


  const totalMemberComeInEvent = () => {
    let jsons = [...guests];
    let alloweds = [...guestCount];

    // Reset coming values
    alloweds.forEach((item) => item.coming = 0);

    for (let i = 0; i < jsons.length; i++) {
      let eventNameOfJson = jsons[i].pname?.label;
      let eventIndexOfAllowed = alloweds.findIndex((item) => item?.label === eventNameOfJson);

      if (eventIndexOfAllowed !== -1) {
        alloweds[eventIndexOfAllowed].coming += parseInt(jsons[i].familycount);
      }
    }

    setRemainingGuest(alloweds);
  };
  useEffect(() => {
    if (guests?.length > 0 && guestCount?.length > 0) {
      totalMemberComeInEvent();
    }
  }, [guests, guestCount, activeStep]);

  return (
    <div className="static-pages create-new-project">
      <div className="d-none d-md-block">
        <SettingHeader title="Create New Wedding Project" />
      </div>

      <div className="main-wrapper">
        <Container>
          <Row>
            <Col xs={12} xl={3}>
              <div className="step-form-wrapper">
                {steps?.map((item, index) => (
                  <div
                    key={index}
                    className={`step ${activeStep == item?.id ? "active" : ""
                      } d-flex align-items-center pb-3 pb-md-0 mb-5`}
                  >
                    <div className="circle">
                      <h6>{item?.step}</h6>
                    </div>
                    <h6 className="text-uppercase ms-3">{item?.title}</h6>
                  </div>
                ))}
              </div>
            </Col>

            <Col xs={12} xl={6} className="mt-4 mt-xl-0">
              <CreateProjectForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                hookForm={hookForm}
                setPdf={setPdf}
                setBalanceBudget={setBalanceBudget}
                project={project}
              />
            </Col>

            <Col xs={12} xl={3} className="mt-4 mt-xl-0">
              <h3 className="fw-700 color-blue border-bottom pb-3 mb-3">
                Quick summary
              </h3>
              <div
                className={`btns-wrapper mb-50 ${activeStep == steps?.length || id || loading ? "d-block" : "d-none"
                  }`}
              >
                <Button
                  className="btn-whatsapp w-100 py-2 d-flex align-items-center justify-content-center mb-4"
                  disabled={!loading}
                  onClick={() => handleWhatsAppShare(pdf)}
                >
                  SHARE ON WHATSAPP
                  <span className="ms-3">
                    <WhatsappIconBtn />
                  </span>
                </Button>
                <Button
                  className="btn-download w-100 py-2 d-flex align-items-center justify-content-center"
                  onClick={() => handleDownload(pdf)}
                >
                  DOWNLOAD PROJECT
                  <span className="ms-3">
                    <DownloadIconBtn />
                  </span>
                </Button>
              </div>

              <ul>
                <div className="border-bottom pb-3 mb-3">
                  <li className="d-flex align-items-center justify-content-between mb-1">
                    <span>Bride’s Name</span>
                    <span>
                      <b>{brideName || "-"}</b>
                    </span>
                  </li>

                  <li className="d-flex align-items-center justify-content-between">
                    <span>Groom's Name</span>
                    <span>
                      <b>{groomName || "-"}</b>
                    </span>
                  </li>
                </div>

                <div className="border-bottom pb-3 mb-3">
                  <li className="d-flex align-items-center justify-content-between">
                    <span>Budget</span>
                    <span>
                      <b>{budget || "-"}</b>
                    </span>
                  </li>
                </div>
                {id ? (
                  <div className="border-bottom pb-3 mb-3">
                    <li className="d-flex align-items-center justify-content-between">
                      <span>Balance Budget</span>
                      <span>
                        <b>
                          {project?.budget && project?.total_budget
                            ? project?.budget - project?.total_budget
                            : ""}
                        </b>
                      </span>
                    </li>
                  </div>
                ) : (
                  ""
                )}
                {loading ? (
                  <div className="border-bottom pb-3 mb-3">
                    <li className="d-flex align-items-center justify-content-between">
                      <span>Balance Budget</span>
                      <span>
                        <b>
                          {balanceBudget?.budget && balanceBudget?.total_budget
                            ? balanceBudget?.budget -
                            balanceBudget?.total_budget
                            : ""}
                        </b>
                      </span>
                    </li>
                  </div>
                ) : (
                  ""
                )}
                {weddingProgram && weddingProgram.length > 0
                  ? weddingProgram.map((dt) => (
                    <div className="border-bottom pb-3 mb-3" key={dt?.id}>
                      <li className="d-flex align-items-center justify-content-between">
                        <span>
                          <span>
                            Program: <b>{dt?.name || "-"}</b>
                          </span>
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            -Budget
                          </span>
                        </span>
                        <span>
                          <b>{dt?.budget || "-"}</b>
                        </span>
                      </li>
                    </div>
                  ))
                  : ""}
                {weddingProgramCount && weddingProgramCount.length > 0
                  ? weddingProgramCount.map((dt) => (
                    <div className="border-bottom pb-3 mb-3" key={dt?.id}>
                      <li className="d-flex align-items-center justify-content-between">
                        <span>
                          <span>
                            Program: <b>{dt?.name?.label || "-"} </b>
                          </span>
                          <span
                            style={{
                              color: "#7d9ef2",
                            }}
                          >
                            -Guests Count
                          </span>
                        </span>
                        <span>
                          <b>{dt?.familycount || "-"}</b>
                        </span>
                      </li>
                    </div>
                  ))
                  : ""}
                {remainingGuest && remainingGuest.length > 0
                  ? remainingGuest.map((dt, index) => (
                    <div className="border-bottom pb-3 mb-3" key={index}>
                      <li className="d-flex align-items-center justify-content-between">
                        <span>
                          <span>
                            Program: <b>{dt?.label || "-"} </b>
                          </span>
                          <span
                            style={{
                              color: "#22bb33",
                            }}
                          >
                            - Remaining
                          </span>
                        </span>
                        <span>
                          <b>{dt?.totalCount - dt?.coming}</b>
                        </span>
                      </li>
                    </div>
                  ))
                  : ""}

                {/* <div className="border-bottom pb-3 mb-3">
                                    <li className="d-flex align-items-center justify-content-between">
                                        <span>Total Guests</span>
                                        <span>
                                            <b className="color-dark me-2">{familyCountSum} Guests</b>
                                            <EyeIconGreen />
                                        </span>
                                    </li>
                                </div> */}

                <div className="border-bottom pb-3 mb-3">
                  <li className="d-flex align-items-center justify-content-between">
                    <span>Guests List</span>
                    <span>
                      <b className="color-dark me-2">
                        {guests?.length || 0} Guests
                      </b>
                      <EyeIconGreen />
                    </span>
                  </li>
                </div>
                {/*  */}
                <div className="border-bottom pb-3 mb-3">
                  <li className="d-flex align-items-center justify-content-between">
                    <span>Collaborated Team</span>
                    <span>
                      <b className="color-dark me-2">
                        {collaboratedTeam?.length || 0} Tasks
                      </b>
                      <EyeIconGreen />
                    </span>
                  </li>
                </div>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CreateNewProject;
