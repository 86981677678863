import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  ArroWhiteIcon,
  BehanceIcon,
  DribbleIcon,
  InstagramIcon,
  SubscribeIcon,
  TwitterIcon,
} from "../../constants/svgs";
import Assets from "../../constants/images";
import { NavLink, useNavigate } from "react-router-dom";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../../constants/app-constants";
import CustomInput from "../form/CustomInput";
import { Controller, useForm } from "react-hook-form";

const ThemeFooter = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();

  const onSubmit = (data) => { };

  return (
    <footer className="theme-footer">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="top-box text-center pb-5">
              <h4 className="text-white lh-0-ls-5 ">GET IN TOUCH</h4>
              <h1 className="mt-3">
                Crafting Love Stories and Perfect Moments Together. Let Us Help
                You find Your Perfect Match and Plan Your Dream Wedding with
                Ease. Explore, Connect, and Celebrate Love with Rishtey and
                Shadi.com.
              </h1>

              <Button
                className="btn-outline btn-white text-uppercase mx-auto mt-4"
                type="button"
                onClick={() => navigate('/contact')}
              >
                <div className="circle">
                  <ArroWhiteIcon />
                </div>
                LET'S TALK
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="pt-90">
          {/* FIRST COLUMN */}
          <Col xs={12} xl={3} className="mb-4">
            <div className="logo">
              <img src={Assets.StaticLogo} alt="" />
            </div>

            <div className="mt-3 social-icon-wrapper">
              <ul>
                <li className="mb-3">
                  <NavLink to="/" className="d-flex align-items-center">
                    <div className="circle">
                      <InstagramIcon />
                    </div>
                    <small className="ms-3">Instagram</small>
                  </NavLink>
                </li>

                <li className="mb-3">
                  <NavLink to="/" className="d-flex align-items-center">
                    <div className="circle">
                      <TwitterIcon />
                    </div>
                    <small className="ms-3">Twitter</small>
                  </NavLink>
                </li>

                <li className="mb-3">
                  <NavLink to="/" className="d-flex align-items-center">
                    <div className="circle">
                      <DribbleIcon />
                    </div>
                    <small className="ms-3">Dribble</small>
                  </NavLink>
                </li>

                <li className="mb-3">
                  <NavLink to="/" className="d-flex align-items-center">
                    <div className="circle">
                      <BehanceIcon />
                    </div>
                    <small className="ms-3">Behance</small>
                  </NavLink>
                </li>
              </ul>
            </div>
          </Col>

          {/* SECOND COLUMN */}
          <Col xs={12} xl={3} className="mb-4">
            <h4 className="mb-5">GET IN TOUCH</h4>
            <ul>
              <li>+1 012-345-6099</li>
              <li>hello@rishteynshadi.com</li>
              <li>41 Peabody Street, Abc Avenue Chicago, USA</li>
            </ul>
          </Col>

          {/* THIRD COLUMN */}
          <Col xs={12} xl={3} className="mb-4">
            <h4 className="mb-5">QUICK LINKS</h4>
            <div className="d-flex">
              <ul>
                <li>
                  <NavLink to="/">HOME</NavLink>
                </li>
                <li>
                  <NavLink to="/about">ABOUT US </NavLink>
                </li>
                <li>
                  <NavLink to="/contact">CONTACT US</NavLink>
                </li>
                <li>
                  <NavLink to="/membership">MEMBERSHIP</NavLink>
                </li>
              </ul>

              <ul className="ms-5">
                <li>
                  <NavLink to="/faqs">FAQ'S</NavLink>
                </li>
                <li>
                  <NavLink to="/terms-and-conditions">
                    TERMS AND CONDITIONS
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
                </li>
              </ul>
            </div>
          </Col>
          {/* commented by Faizan */}
          {/* FOURTH COLUMN */}
          {/* <Col xs={12} xl={3} className="mb-4">
            <h4 className="mb-5">NEWSLETTER</h4>
            <h3>Our Insights to Your Inbox.</h3>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="subscribe-input mt-3">
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                    },
                    maxLength: {
                      value: VALIDATIONS.EMAIL,
                      message: VALIDATIONS_TEXT.EMAIL,
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                    },
                  }}
                  render={({ field }) => (
                    <div className="subscribe-input">
                      <CustomInput
                        {...field}
                        label="Subscribe"
                        placeholder="Subscribe"
                        type="email"
                        required={true}
                        maxLength={VALIDATIONS.EMAIL}
                        value={field.value}
                        onChange={field.onChange}
                        disabled={false}
                      />
                      <Button className="btn-subscribe" type="submit">
                        <SubscribeIcon />
                      </Button>
                    </div>
                  )}
                />
                {errors.email && (
                  <small className="text-start d-block text-danger mt-1">
                    {errors.email.message}
                  </small>
                )}
              </div>
            </Form>
          </Col> */}
        </Row>

        <Row>
          <Col xs={12} className="text-center">
            <div className="copy-right">
              <p className="text-capitalize">
                Copyright © 2023 RISHTEY AND SHADI
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default ThemeFooter;
