import React from 'react'
import { Button, Modal } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'
import { EmptyLocalStorage, GetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { error, success } from '../../constants/msg'
import { ArrowIcon, ArrowLeft } from '../../constants/svgs'
import { DeleteUserById } from '../../services/userProfile'

const DeleteConfirmPopup = ({ deleteConfirmPopup, cnfrmtxt, setDeleteConfirmPopup, handleDelete, ...props }) => {
    const navigate = useNavigate()



    return (
        <Modal
            className='theme-popup delete-confirm-popup'
            show={deleteConfirmPopup}
            onHide={() => setDeleteConfirmPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div>
                    <div className="header text-center">
                        <h2>{props?.txtFirst}</h2>
                        <p className='mt-2'>{props?.txtSecond}</p>
                    </div>

                    <div className='mt-30 d-flex align-items-center justify-content-center '>
                        <Button className="btn-outline btn-dark" onClick={() => setDeleteConfirmPopup(false)}>
                            <div className="circle">
                                <ArrowLeft />
                            </div>
                            CANCEL
                        </Button>

                        <Button onClick={handleDelete} className="btn-solid btn-blue ms-4">
                            {cnfrmtxt}
                            <div className="circle">
                                <ArrowIcon />
                            </div>
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteConfirmPopup