import React, { useEffect, useState } from 'react'
import SettingHeader from '../components/static/SettingHeader'
import { Col, Container, Row } from 'react-bootstrap'
import SettingSidebar from '../components/static/SettingSidebar'
import MatchCard from '../components/static/MatchCard'
import PlanCard from '../components/static/PlanCard'
import { NavLink } from 'react-router-dom'
import { MdChevronLeft } from 'react-icons/md'
import { GetAuthUserLocalStorage } from '../services/localStorage/localStorage'

const MyMembershipPage = () => {
    const user = GetAuthUserLocalStorage()

    const [plans, setPlans] = useState([
        {
            param: "Basic",
            plan: "Basic Plan",
            type: "Free",
            features: [
                {
                    description: "Can only send the match making request",
                },
                {
                    description: "See the number of requests",
                },
            ],
            btnText: 'Upon Sign Up'
        },
        {
            param: "Silver",
            plan: "Silver Plan",
            type: "50AED m / 120AED 3m / 250 AED 6m",
            features: [
                {
                    description: "View verified contact details",
                },
                {
                    description: "Partial Profile View",
                },
                {
                    description: "Direct Messages to partners (02 Weekly)",
                },
            ],
            btnText: "Buy Now"
        },
        {
            param: "Gold",
            plan: "Gold Plan",
            type: "100AED m / 200AED 3m / 500 AED 6m",
            features: [
                {
                    description: "Connect instantly via Chat",
                },
                {
                    description: "View verified contact details",
                },
                {
                    description: "Profile details (Picture should be hidden)",
                },
                {
                    description: "Direct Messages to partners (20 Weekly)",
                },
                {
                    description: "Unlock access to advanced search",
                },
                {
                    description: "Profile details (Picture should be hidden)",
                },
                {
                    description: "Unlock 05 contact views",
                },
            ],
            btnText: "Buy Now"
        },
        {
            param: "Platinum",
            plan: "Platinum Plan",
            type: "150AED m / 400AED 3m / 500 AED 6m",
            features: [
                {
                    description: "View verified contact details",
                },
                {
                    description: "Special offers from Partners",
                },
                {
                    description: "Direct Messages to partners",
                },
                {
                    description: "Unlock access to advanced search",
                },
                {
                    description:
                        "Appear on top of the list for 24 hours with 3 free spotlights",
                },
                {
                    description:
                        "Access full Profile (Picture should not be downloadable)",
                },
                {
                    description: "Connect instantly via Chat",
                },
                {
                    description: "Get up to 2x more matches daily",
                },
                {
                    description: "View up to 3x profiles daily",
                },
                {
                    description: "Unlock 20 contact views",
                },
            ],
            btnText: "Buy Now"
        },
    ]);

    useEffect(() => {
        let plancheck = user?.subscription_plan?.plan_name
        if (plancheck) {
            let userPlan = plans.filter((plan) => plan.param === plancheck)
            console.log(userPlan);
            setPlans(userPlan)
        }
    }, [])
    return (
        <div className='static-pages my-membership-page'>
            <div className="d-none d-md-block">
                <SettingHeader />
            </div>

            <Container>
                <Row>
                    <Col md={3} lg={3} xl={3} className='d-none d-md-block'>
                        <SettingSidebar />
                    </Col>

                    <Col md={9} lg={9} xl={9}>
                        <div className="content py-5 py-md-5 ps-md-5">
                            <h2 className='color-blue mb-4'>My Membership</h2>
                            <Row className='mt-4'>
                                <Col sm={12} md={9} lg={7} xl={6} className='mb-4'>
                                    {plans?.map((dt) => (
                                        <PlanCard data={dt} showBtn={false} />

                                    ))}
                                    <NavLink to="/setting/update-my-plan" className="change-plan mt-4 mb-5 mb-xl-0">
                                        <div className="circle me-3">
                                            <MdChevronLeft className='text-dark' />
                                        </div>
                                        Change Plan
                                    </NavLink>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyMembershipPage