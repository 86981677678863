import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import CustomInput from "../form/CustomInput";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../../constants/app-constants";
import { Button, Col, Row } from "react-bootstrap";
import {
  ArrowIcon,
  ArrowLeft,
  DeleteIcon,
  EyeIconGreen,
  UploadIconBlue,
} from "../../constants/svgs";
import { IoMdAdd } from "react-icons/io";
import CustomFileUploader from "../form/CustomFileUploader";
import WeddingCard from "./WeddingCard";
import Assets from "../../constants/images";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { weddingFileContexts, weddingLoadingContext } from "../../App";
import { constant } from "../../utils/constants";
import html2canvas from "html2canvas";

const OverviewForm = ({
  watch,
  control,
  formState,
  setValue,
  handleNextStep,
  handlePreviousStep,
  project
}) => {
  const { errors, isDirty, isValid, isSubmitting } = formState;
  const [
    brideName,
    groomName,
    budget,
    programs,
    guestscounts,
    vendors,
    eventPlanning,
    guests,
    collaboratedTeam,
    currency,
    religion,
    rsvp,
    weddingTemplates,
  ] = watch([
    "brideName",
    "groomName",
    "budget",
    "programs",
    "guestscounts",
    "vendors",
    "eventPlanning",
    "guests",
    "collaboratedTeam",
    "currency",
    "religion",
    "rsvp",
    "weddingTemplates",
  ]);
  const [loading, setLoading] = useContext(weddingLoadingContext);
  const [weddingFile, setWeddingFile] = useContext(weddingFileContexts);
  console.log(loading, 'loading');
  let url = weddingFile;
  // let parts = url.split("-");
  let fileName = "parts[parts.length - 1]"; // Taking the last part after the last "-"
  const familyCountSum =
    guests && guests.length > 0
      ? guests.reduce((sum, item) => {
        // Convert familycount to a number and add to the sum
        return sum + parseInt(item.familycount, 10);
      }, 0)
      : 0;
  const navigate = useNavigate();

  const [weddingProgram, setWeddingProgram] = useState([]);
  const [weddingProgramCount, setWeddingProgramCount] = useState([]);

  useEffect(() => {
    setWeddingProgram(programs);
  }, [programs]);
  const { id } = useParams()

  useEffect(() => {
    setWeddingProgramCount(guestscounts);
  }, [guestscounts]);
  const onClickUpdate = () => {
    if (id) {
      navigate("/my-wedding-projects");
      setLoading(false);
    }
  }
  const handleDownload = (pdflink) => {
    const pdfUrl = constant?.BASE_URL + "/" + project?.plan_file;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "project.pdf"; // specify the filename
    link.target = "_blank"; // open link in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadJPG = (index) => {
    const element = document.getElementById(`pdf-content-${index}`);
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.download = `my-image-${index}.jpeg`;
      link.href = canvas.toDataURL('image/jpeg', 0.9); // The second parameter is the quality (0-1)
      link.click();
    });
  };
  const handleDownloadTemplate = (pdflink, index) => {
    const pdfUrl = constant?.BASE_URL + "/" + pdflink;
    console.log(pdflink, index, pdfUrl)
  };

  return (
    <div className="wedding-card-form">
      <Row>
        <Col xs={12} xl={11}>
          <h3 className="fw-800 border-bottom pb-3 mb-4 color-blue">
            Overview
          </h3>
        </Col>

        <Col xs={12} xl={11}>
          <ul>
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between mb-1">
                <h4 className="color-grey">Bride’s Name</h4>
                <h4>{brideName}</h4>
              </li>

              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Groom's Name</h4>
                <h4>{groomName}</h4>
              </li>
            </div>
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Currency</h4>
                <h4> {currency?.label}</h4>
              </li>
            </div>
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Budget</h4>
                <h4>{budget}</h4>
              </li>
            </div>
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Religion</h4>
                <h4>{religion && religion?.label}</h4>
              </li>
            </div>
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Program</h4>
                <h4>
                  {/* {programs && programs?.map((item) => item?.name + " / ")} */}
                  <h4 className="me-2">{programs?.length} Programs &nbsp;</h4>
                </h4>
              </li>
            </div>
            {weddingProgram && weddingProgram.length > 0
              ? weddingProgram.map((dt) => (
                <div className="border-bottom pb-3 mb-3" key={dt?.id}>
                  <li className="d-flex align-items-center justify-content-between">
                    <span>
                      <h4 className="color-grey"> Program: <b>{dt?.name || "-"}</b></h4>
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        -Budget
                      </span>
                    </span>
                    <span>
                      <b>{dt?.budget || "-"}</b>
                    </span>
                  </li>
                </div>
              ))
              : ""}
            {weddingProgramCount && weddingProgramCount.length > 0
              ? weddingProgramCount.map((dt) => (
                <div className="border-bottom pb-3 mb-3" key={dt?.id}>
                  <li className="d-flex align-items-center justify-content-between">
                    <span>
                      <h4 className="color-grey">
                        Program: <b>{dt?.name["label"] || "-"} </b>
                      </h4>
                      <span
                        style={{
                          color: "#7d9ef2",
                        }}
                      >
                        -Guests Count
                      </span>
                    </span>
                    <span>
                      <b>{dt?.familycount || "-"}</b>
                    </span>
                  </li>
                </div>
              ))
              : ""}
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Guests</h4>
                <h4 className="me-2">
                  {`${guests?.length} ${guests?.length > 1 ? "Families" : "Family"
                    }`}
                  &nbsp;
                </h4>
              </li>
            </div>
            {/* 
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Total Guests</h4>
                <h4 className="me-2">{familyCountSum} &nbsp;</h4>
              </li>
            </div> */}
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Collaborated Team</h4>
                <h4 className="me-2">
                  {collaboratedTeam?.length} Tasks &nbsp;
                </h4>
              </li>
            </div>
            {/* <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Vendors</h4>
                <h4 className="me-2">{vendors?.length} Vendors &nbsp;</h4>
              </li>
            </div> */}
            <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Vendors</h4>
                <h4 className="me-2">
                  {vendors?.length} Vendors
                  {/* {vendors && vendors?.map((item) => item?.name + " / ")} */}
                </h4>
              </li>
            </div>

            {project && project?.plan_file ? <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">DOWNLOAD PROJECT FILE</h4>
                <div onClick={handleDownload} style={{
                  cursor: 'pointer',
                  color: 'blue'
                }}>
                  <h4 className="me-2">
                    Click Here..
                    {/* {vendors && vendors?.map((item) => item?.name + " / ")} */}
                  </h4>

                </div>
              </li>
            </div> : ''}


            {/* {project && project?.wedding_card ? project?.wedding_card?.map((dt, index) => (
              <div className="border-bottom pb-3 mb-3">
                <li className="d-flex align-items-center justify-content-between">
                  <h4 className="color-grey">DOWNLOAD TEMPLATE {dt?.plan_name}</h4>
                  <div onClick={() => handleDownloadTemplate(dt?.url, index)} style={{
                    cursor: 'pointer',
                    color: 'blue'
                  }}>
                    <h4 className="me-2">
                      Click Here..
                      {vendors && vendors?.map((item) => item?.name + " / ")}
                    </h4>

                  </div>
                </li>
              </div>
            ))
              : ''} */}


            {/* same h4 inside */}
            {/* <h4>{weddingTemplates && weddingTemplates?.map((item) => item?.title + ' / ')}</h4> */}

            {/* <div className="border-bottom pb-3 mb-3">
              <li className="d-flex align-items-center justify-content-between">
                <h4 className="color-grey">Wedding Templates</h4>
                {<h4>{fileName}</h4>}
              </li>
            </div> */}
          </ul>

          {/* NEXT STEP BUTTON */}
          <div className="mt-5 d-flex flex-column flex-sm-row align-items-sm-center ">
            {loading ? (
              <>
                <Button
                  className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue"
                  type="submit"
                  disabled={!isDirty || !isValid}
                  onClick={() => {
                    navigate("/my-wedding-projects");
                    setLoading(!loading);
                  }}
                >
                  VIEW PROJECT
                  <div className="circle">
                    <ArrowIcon />
                  </div>
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handlePreviousStep}
                  className="btn-outline btn-dark"
                  type="button"
                >
                  <div className="circle">
                    <ArrowLeft />
                  </div>
                  PREVIOUS STEP
                </Button>
                <Button
                  className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue"
                  type="submit"
                  disabled={!isDirty || !isValid}
                // onClick={onClickUpdate}
                >
                  {loading ? "Loader...." : id ? "UPDATE PROJECT" : "CREATE PROJECT"}
                  <div className="circle">
                    <ArrowIcon />
                  </div>
                </Button>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div >
  );
};

export default OverviewForm;
