import React, { useState } from 'react'
import SettingHeader from '../components/static/SettingHeader'
import { Col, Container, Row } from 'react-bootstrap'
import SettingSidebar from '../components/static/SettingSidebar'
import MatchCard from '../components/static/MatchCard'
import PlanCard from '../components/static/PlanCard'
import { NavLink, useLocation } from 'react-router-dom'
import { MdChevronLeft } from 'react-icons/md'
import PaymentForm from '../components/static/PaymentForm'

const MyPaymentPage = () => {
    const location = useLocation()

    return (
        <div className='static-pages my-payment-page'>
            <div className="d-none d-md-block">
                <SettingHeader title="Payment Method" />
            </div>

            <Container>
                <Row>
                    <Col xs={12}>
                        <div className='main-wrapper'>
                            <Row>
                                <Col xs={12} md={10} lg={8} xl={5}>
                                    <div className="left-wrapper">
                                        <div className="left mb-4">
                                            <h4 className='lh-0-ls-5 color-grey'>MEMBERSHIP</h4>
                                            <h2 className='mt-3 color-blue'>Your Selected Plan</h2>
                                        </div>

                                        <PlanCard data={location?.state?.data} showBtn={false} />

                                        <NavLink to="/setting/update-my-plan" className="mt-4 mb-5 mb-xl-0 change-plan">
                                            <div className="circle me-3">
                                                <MdChevronLeft className='text-dark' />
                                            </div>
                                            Change Plan
                                        </NavLink>
                                    </div>
                                </Col>

                                <Col xs={12} lg={12} xl={{ span: 6, offset: 1 }} >
                                    <div className="right-wrapper">
                                        <PaymentForm />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyPaymentPage