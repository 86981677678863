import React, { useContext, useState } from "react";
import { ArrowIconBlack, TickIcon } from "../../constants/svgs";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PaymentProcess from "../popup/PaymentProcess";
import { selectedPlanDetailsContext, subscriptionPlanContext } from "../../App";
import { GetTokenLocalStorage } from "../../services/localStorage/localStorage";

const PlanCard = ({ data, index, showBtn, btnText, link }) => {
  const navigate = useNavigate()
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState([]);
  const [Subscribeplanname, setSubscribeplanname] = useContext(
    subscriptionPlanContext
  );
  const [selectedPlanDetails, setSelectedPlanDetails] = useContext(selectedPlanDetailsContext);


  const token = GetTokenLocalStorage()

  const handleNavigate = (data) => {
    // if (index === 0) {
    //   navigate("/");
    //   // setSubscribeplanname(data?.param);
    // }
    if (!token) {
      navigate('/signin')
    }
    else {
      setPaymentPopup(true);
      setSelectedPaymentPlan(data);
      setSubscribeplanname(data?.param);
      setSelectedPlanDetails(data)
    }
  };

  return (
    <div className="plan-card">
      <div className="header">
        <h3>{data?.plan}</h3>
        <h4 className="mt-1">{data?.type}</h4>
      </div>

      <div className="features">
        <ul>
          {data?.features?.map((item, index) => (
            <li key={index} className="d-flex align-items-center mb-2">
              <div className="icon">
                <TickIcon />
              </div>
              <p className="ms-3 mt-1">{item?.description}</p>
            </li>
          ))}
        </ul>

        <Button
          className={`btn-outline btn-dark text-uppercase mt-4 mb-3 ${showBtn ? "d-flex" : "d-none"
            }`}
          type="button"
          onClick={() => handleNavigate(data)}
        >
          <div className="circle">
            <ArrowIconBlack />
          </div>
          {data?.btnText}
        </Button>
      </div>
      <PaymentProcess
        paymentPopup={paymentPopup}
        setPaymentProcess={setPaymentPopup}
        selectedPaymentPlan={selectedPaymentPlan}
      />
    </div>
  );
};

export default PlanCard;
