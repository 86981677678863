import React, { useRef, useState, useEffect } from 'react'
import { IoAttachSharp } from "react-icons/io5"
import { IoMdSend } from "react-icons/io"
import { Controller } from 'react-hook-form'
import CustomTextarea from '../form/CustomTextarea'
import { VALIDATIONS_TEXT } from '../../constants/app-constants'
import { CiMicrophoneOn } from "react-icons/ci"
import { BsStopCircle } from "react-icons/bs"

const MessageInput = ({ control, errors, files, setFiles, sendMessage }) => {
    const mediaRecorder = useRef(null)
    const [audioChunks, setAudioChunks] = useState([])
    const [isRecording, setIsRecording] = useState(false)
    const [timer, setTimer] = useState(0)
    const [isRunning, setIsRunning] = useState(false)

    const handleFileChange = (e) => {
        setFiles([...files, ...e.target.files])
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60).toString().padStart(2, "0");
        const seconds = (time % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    }

    const startTimer = () => {
        setIsRunning(true);
        setTimer(0);
    }

    const stopTimer = () => {
        setIsRunning(false);
        setTimer(0);
    }

    const startRecording = async () => {
        setIsRecording(true)
        const streamData = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        })
        const media = new MediaRecorder(streamData, { type: "audio/webm" })
        //set the MediaRecorder instance to the mediaRecorder ref
        mediaRecorder.current = media
        //invokes the start method to start the recording process
        mediaRecorder.current.start()
        let localAudioChunks = []
        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return
            if (event.data.size === 0) return
            localAudioChunks.push(event.data)
        }
        setAudioChunks(localAudioChunks)
    }

    const stopRecording = () => {
        //stops the recording instance
        mediaRecorder.current.stop()
        mediaRecorder.current.onstop = () => {
            //creates a blob file from the audiochunks data
            const audioBlob = new Blob(audioChunks, { type: "audio/mp3" })
            //creates a playable URL from the blob file.
            const audioUrl = URL.createObjectURL(audioBlob)
            const file = blobToFile(audioBlob)
            setFiles([...files, file])
            setAudioChunks([])
            setIsRecording(false)
        }
    }

    const blobToFile = (audioBlob) => {
        const file = new File([audioBlob], "audio.mp3", { type: "audio/mp3" });
        return file;
    }

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isRunning])


    return (
        <div className='message-input'>
            <div className="d-flex align-items-center input-wrapper">
                {/* FILE UPLOADER */}
                <label className='icon-wrapper cursor' htmlFor="file">
                    <input className='d-none' multiple type="file" id='file' onChange={handleFileChange} />
                    <IoAttachSharp size={28} className='icon attachement' />
                </label>

                {/* MESSAGE INPUT */}
                <div className='w-100'>
                    <Controller
                        control={control}
                        name="message"
                        rules={{
                            required: {
                                value: false,
                                message: VALIDATIONS_TEXT.MESSAGE_REQUIRED,
                            },
                        }}
                        render={({ field }) => (
                            <CustomTextarea
                                {...field}
                                label="Enter your message"
                                placeholder="Enter your message"
                                type="text"
                                required={false}
                                value={field.value}
                                onChange={field.onChange}
                                disabled={false}
                            />
                        )}
                    />
                    {errors.message && <small className='text-start d-block text-danger mt-1'>{errors.message.message}</small>}
                </div>

                {/* RECORDING AND SEND MESSAGE */}
                <div className="d-flex align-items-center ms-3 ">
                    <div className="me-3">
                        {isRecording ?
                            <span className='d-flex align-items-center' onClick={() => {
                                stopRecording()
                                stopTimer()
                            }}>
                                <span className='text-grey me-2'>{formatTime(timer)}</span>
                                <BsStopCircle size={28} className='icon' />
                            </span>
                            :
                            <span className='d-flex align-items-center' onClick={() => {
                                startRecording()
                                startTimer()
                            }}>
                                <CiMicrophoneOn size={28} className='icon' />
                            </span>
                        }

                    </div>

                    <div onClick={sendMessage} className="send-button">
                        <IoMdSend className='icon' />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MessageInput