import React, { useState } from 'react'
import { faqs } from '../constants/content'
import IntroductionBanner from '../components/static/IntroductionBanner'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { MdRemoveCircleOutline } from "react-icons/md";

const FaqPage = () => {
    const [data, setData] = useState([
        {
            title: "How does your matchmaking service work?",
            description: `Our matchmaking process involves personalized profiling, compatibility assessments, and
            expert AI guidance works in the background to help you find your ideal match.`
        },
        {
            title: "How do I sign up for your matchmaking services?",
            description: `Signing up is easy! Simply visit our website, fill out the registration form, and our website
            instructions will guide you through the next steps. It’s simple and easy!`
        },
        {
            title: "Can I choose specific preferences for my match?",
            description: `Absolutely! You can specify preferences such as age, location, interests, values, and more
            to help us find a compatible match for you.`
        },
        {
            title: "What kind of support do you provide during the matchmaking process?",
            description: `Based on the plan you have subscribed to, our dedicated team offers continuous support,
            advice, and guidance at every step, ensuring a smooth and personalized matchmaking
            experience.`
        },
        {
            title: "How long does the matchmaking process usually take?",
            description: `The duration varies based on individual preferences and compatibility. We aim to find
            meaningful matches efficiently while prioritizing quality over speed.`
        },
        {
            title: "What if I'm not satisfied with the match provided?",
            description: `Your satisfaction is crucial to us. Based on the subscribed plan features, We offer
            reassessment and fine-tuning based on your feedback to ensure we find the right match for
            you.`
        },
        {
            title: "Can I access your services internationally?",
            description: `Yes, our services are available globally. We cater to clients worldwide, providing seamless
            matchmaking and wedding planning assistance across borders.`
        },
        {
            title: "What happens after I&#39;ve found a match through your service?",
            description: `Once a match is made, you&#39;ll have the opportunity to communicate and get to know each
            other better. We offer guidance and support throughout the initial stages of interaction.`
        },
        {
            title: "Is my information kept confidential?",
            description: `Yes, we prioritize the confidentiality and privacy of our clients. Your information is
            securely stored and only shared with your consent and for matchmaking purposes.`
        },


    ])

    const [ceremonyData, setCeremonyData] = useState([
        {
            title: "What services do you offer as an online wedding ceremony planner?",
            description: `We provide a comprehensive online platform equipped with various digital tools and
            resources to assist you in planning every aspect of your wedding ceremony.`
        },
        {
            title: "What kind of tools are available once I subscribe to your platform?",
            description: `Upon subscription, you&#39;ll gain access to a suite of tools including budget trackers, guest
            list managers, seating arrangement planners, vendor directories, and more, facilitating
            seamless wedding planning from start to finish.`
        },
        {
            title: "How do I use the online tools provided for planning my wedding?",
            description: `Our platform is designed to be user-friendly. After subscribing, you&#39;ll receive access to an
            intuitive interface where you can explore and utilize the tools easily, with step-by-step
            guidance if needed.`
        },
        {
            title: "Can I personalize my wedding plans using your online tools?",
            description: `Absolutely! Our tools are customizable to accommodate your unique preferences,
            allowing you to tailor every aspect of your wedding plans to reflect your vision and style.`
        },
        {
            title: "Is there any guidance or support available while using your platform?",
            description: `Yes, we offer comprehensive tutorials, FAQs, and customer support to assist you in
            navigating the platform and utilizing the tools effectively for your wedding planning needs.`
        },
        {
            title: "Are there any restrictions or limitations to the tools available on your platform?",
            description: `Our platform is equipped with a diverse range of tools to cover various aspects of
            wedding planning. However, should you have specific queries or suggestions, we encourage
            you to reach out to us for further assistance and guidance.`
        },
    ])

    return (
        <div className='static-pages faq-page'>
            <IntroductionBanner
                title={faqs.title}
                description={faqs.description}
                withBtns={false}
            />

            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="accordion-wrapper">
                            <h1 className='mb-5'
                                style={{
                                    color: '#2C3E57'
                                }}
                            >Matchmaking</h1>
                            <Accordion>
                                {
                                    data?.map((item, index) => (
                                        <Accordion.Item key={index} eventKey={index}>
                                            <Accordion.Header>{item?.title}</Accordion.Header>
                                            <Accordion.Body>{item?.description}</Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="accordion-wrapper">
                            <h1 className='mb-5'
                                style={{
                                    color: '#2C3E57'
                                }}

                            >Wedding Ceremony Planner</h1>
                            <Accordion>
                                {
                                    ceremonyData?.map((item, index) => (
                                        <Accordion.Item key={index} eventKey={index}>
                                            <Accordion.Header>{item?.title}</Accordion.Header>
                                            <Accordion.Body>{item?.description}</Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default FaqPage