import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import PlanCard from "./PlanCard";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";

const ChoosePlan = () => {
  const [plans, setPlans] = useState([
    // {
    //   param: "Basic",
    //   plan: "Basic Plan",
    //   type: "Free",
    //   features: [
    //     {
    //       description: "Can only send the match making request",
    //     },
    //     {
    //       description: "See the number of requests",
    //     },
    //   ],
    //   btnText: 'GET FOR FREE'
    // },
    {
      param: "Silver",
      plan: "Silver Plan",
      type: "50AED m / 120AED 3m / 250 AED 6m",
      features: [
        {
          description: "View verified contact details",
        },
        {
          description: "Partial Profile View",
        },
        {
          description: "Direct Messages to partners (02 Weekly)",
        },
      ],
      btnText: "Buy Now"
    },
    {
      param: "Gold",
      plan: "Gold Plan",
      type: "100AED m / 200AED 3m / 500 AED 6m",
      features: [
        {
          description: "Connect instantly via Chat",
        },
        {
          description: "View verified contact details",
        },
        {
          description: "Profile details (Picture should be hidden)",
        },
        {
          description: "Direct Messages to partners (20 Weekly)",
        },
        {
          description: "Unlock access to advanced search",
        },
        {
          description: "Profile details (Picture should be hidden)",
        },
        {
          description: "Unlock 05 contact views",
        },
      ],
      btnText: "Buy Now"
    },
    {
      param: "Platinum",
      plan: "Platinum Plan",
      type: "150AED m / 400AED 3m / 500 AED 6m",
      features: [
        {
          description: "View verified contact details",
        },
        {
          description: "Special offers from Partners",
        },
        {
          description: "Direct Messages to partners",
        },
        {
          description: "Unlock access to advanced search",
        },
        {
          description:
            "Appear on top of the list for 24 hours with 3 free spotlights",
        },
        {
          description:
            "Access full Profile (Picture should not be downloadable)",
        },
        {
          description: "Connect instantly via Chat",
        },
        {
          description: "Get up to 2x more matches daily",
        },
        {
          description: "View up to 3x profiles daily",
        },
        {
          description: "Unlock 20 contact views",
        },
      ],
      btnText: "Buy Now"
    },
    // {
    //     plan: "Wedding Planner Only",
    //     type: "100 AED Monthly",
    //     features: [
    //         {
    //             description: "Access to all the wedding planning categories"
    //         }
    //     ]
    // },
  ]);

  const [basicPlan, setBasicPlan] = useState([
    {
      param: "Basic",
      plan: "Basic Plan",
      type: "Free",
      features: [
        {
          description: "Can only send the match making request",
        },
        {
          description: "See the number of requests",
        },
      ],
      btnText: 'GET FOR FREE'
    },
  ]

  )
  const user = GetAuthUserLocalStorage()

  return (
    <div className="choose-plan">
      <Container className="h-100">
        <Row className="h-100">
          <Col xs={12} className="my-auto">
            <div className="titles">
              <h4 className="lh-0-ls-5">MEMBERSHIP</h4>
              <h1 className="mt-3">Choose Your Plan</h1>
              <h4 className="mt-4">
                At Rishtey, Shadi, we acknowledge the uniqueness of every love
                story and your distinct preferences. Delve into our thoughtfully
                crafted pricing plans tailored to fulfil your specific
                requirements. We aim to ensure that every phase of your
                matchmaking and wedding preparation is as effortless and
                delightful as the love story you're yet to unfold. Choose the
                plan that aligns with your desires and embark on this enchanting
                journey. Your ideal love tale awaits just a step away.
              </h4>
            </div>
          </Col>
        </Row>
        {user?.subscription_plan?.payment === null ?
          <>
            <Row className="h-100 mt-5">
              <Col xs={12} className="my-auto">
                {basicPlan?.map((dt) => (
                  <div className="titles">
                    <h4 className="lh-0-ls-5">Current Plan <b>(upon sign up)</b></h4>
                    <h1 className="mt-3 mb-3">{dt?.plan}</h1>
                    <ul>
                      {dt?.features?.map((item) => (
                        <li className="mb-3">{item?.description}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Col>
            </Row>

          </>
          : ''
        }

        <Row className="mt-4">
          {plans?.map((item, index) => (
            <Col key={index} sm={6} lg={4} className="mb-4">
              <PlanCard
                index={index}
                data={item}
                showBtn={true}
                // btnText={"Buy Now"}
                link="/payment"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ChoosePlan;
