import React from "react";
import { ArrowIconBlack } from "../../constants/svgs";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AboutWhoWeAreAndChooseUs = ({
  title,
  subHeading,
  description,
  btnTextOne,
  btnTextTwo,
  img = "",
}) => {
  const navigate = useNavigate()
  const LearnMore = () => {
    navigate('/about')
  }
  return (
    <div className="about-who-we-are-choose-us">
      <Container className="h-100">
        <Row className="h-100">
          <Col xs={12} className="my-auto">
            <h4 className="lh-0-ls-5">{title}</h4>
            <h1 className="mt-3">{subHeading}</h1>
            <h4 className="mt-4">{description}</h4>

            <div className="d-flex flex-column flex-md-row align-items-md-center" >
              {btnTextOne ?
                <Button
                  className="btn-outline btn-dark text-uppercase mt-4"
                  type="button"
                  onClick={() => navigate('/my-wedding-projects')}
                >
                  <div className="circle">
                    <ArrowIconBlack />
                  </div>
                  {btnTextOne}
                </Button>
                : ''}
              {btnTextTwo ?
                <Button
                  className={`ms-md-3 btn-solid btn-blue text-uppercase mt-4 ${btnTextTwo ? "d-flex" : "d-none"} `}
                  type="button"
                  onClick={() => navigate('/find-match')}
                >
                  <div className="circle me-3">
                    <ArrowIconBlack />
                  </div>
                  {btnTextTwo}
                </Button>
                : ''}
            </div>

            <div
              className={`mt-4 banner-wrapper ${img ? "d-block" : "d-none"}`}
            >
              <img className="w-100" src={img} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutWhoWeAreAndChooseUs;
