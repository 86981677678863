import React from 'react'
import IntroductionBanner from '../components/static/IntroductionBanner'
import { choosePlan } from '../constants/content'
import ChoosePlan from '../components/static/ChoosePlan'

const PlanPage = () => {

    return (
        <div className='static-pages plan-page'>
            {/* <IntroductionBanner
                title={choosePlan.title}
                description={choosePlan.description}
                withBtns={false}
            /> */}
            <ChoosePlan />
        </div>
    )
}

export default PlanPage