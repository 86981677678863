import React, { useContext, useEffect, useState } from "react";
import IntroductionBanner from "../components/static/IntroductionBanner";
import { findMatch } from "../constants/content";
import MatchFilterCard from "../components/static/MatchFilterCard";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../components/form/CustomSelect";
import MatchCard from "../components/static/MatchCard";
import Assets from "../constants/images";
import { GetMatches } from "../services/matches";
import { reloadMatchesContext } from "../App";
import { Prev } from "react-bootstrap/esm/PageItem";
import { Loader } from "../components/loader/Loader";
import Spinner from 'react-bootstrap/Spinner';
import LoaderLoading from "../components/Spinner";
import { RxReset } from "react-icons/rx";

const FindMatchPage = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const sortBy = watch(["sortBy"]);
  const [matches, setMatches] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [reloadMatches, setReloadMatches] = useContext(reloadMatchesContext);
  const [loading, setLoading] = useState(false);
  const [matchLoading, setMatchLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState({
    minAge: "",
    maxAge: "",
    country: "",
    religion: "",
  });
  useEffect(() => {
    if (reloadMatches) {
      GetMatchesOfUser();
      setReloadMatches(false);
    }
  }, [reloadMatches]);

  useEffect(() => {
    GetMatchesOfUser();
  }, [searchParams, page]);

  const onPageChange = () => {
    setLoading(true);
    setPage(page + 1);
    setLoading(false);
  };
  const GetMatchesOfUser = async () => {
    setMatchLoading(true)
    const params = {
      min_age: searchParams?.minAge,
      max_age: searchParams?.maxAge,
      country: searchParams?.country["label"] || "",
      religion:
        searchParams?.religion === ""
          ? ""
          : (searchParams?.religion["label"])
            .split(" ")
            .join("-")
          || "",
      page: page,
    };
    const res = await GetMatches(params);
    setMatchLoading(false)
    const response = res?.data?.data;
    if (page === 1) {
      setMatches(response);

    } else {
      setMatches((prev) => [...prev, ...response]);
    }
    setPagination(res?.data?.pagination);
  };

  const onSubmit = (data) => {
    setPage(1)
    setSearchParams(
      {
        minAge: data?.ageMin || '',
        maxAge: data?.ageMax || '',
        country: data?.country || '',
        religion: data?.religion || '',
      }
    )
  };
  const handleParamsReset = () => {
    reset('')
    setSearchParams({
      minAge: "",
      maxAge: "",
      country: "",
      religion: "",
    })
  };

  return (
    <div className="static-pages find-match-page">
      <IntroductionBanner
        title={findMatch.title}
        description={findMatch.description}
        withBtns={false}
      />

      <div className="filter-wrapper">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <MatchFilterCard
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              control={control}
              errors={errors}
              handleParamsReset={handleParamsReset}
            />
          </Form>
        </Container>
      </div>

      <div className="match-wrapper">
        <Container>
          {/* SORT INPUT */}
          <Row className="mb-30">
            <Col sm={4} lg={4} className="my-auto">
              <span>
                Showing <b>{pagination?.total_documents}</b> out of{" "}
                <b>{pagination?.total_pages}</b>
              </span>
            </Col>

            <Col
              className="mt-4 mt-sm-0"
              sm={{ span: 6, offset: 2 }}
              lg={{ span: 4, offset: 4 }}
            >
              {/* <div className='d-flex align-items-center justify-content-between'>
                                <label>Sort By: </label>
                                <div className='w-75'>
                                    <Controller
                                        control={control}
                                        name="sortBy"
                                        rules={{
                                            required: {
                                                value: false,
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomSelect
                                                {...field}
                                                label="Most Recent"
                                                placeholder="Most Recent"
                                                options={[
                                                    { label: "Most Recent", value: "mostRecent" },
                                                ]}
                                                required={false}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                    {errors.sortBy && <small className='text-start d-block text-danger mt-1'>{errors.sortBy.message}</small>}
                                </div>
                            </div> */}
            </Col>
          </Row>

          {/* CARD INPUT */}
          <Row>
            {matches?.length > 0 ? (
              matchLoading ? <LoaderLoading /> :
                matches?.map((item, index) => (
                  <Col
                    key={item?._id}
                    xs={12}
                    sm={6}
                    lg={4}
                    xl={3}
                    className="mb-50"
                  >
                    <MatchCard
                      matchLoading={matchLoading}
                      item={item}
                      index={index}
                      data={matches}
                      setData={setMatches}
                    />
                  </Col>
                ))
            ) : (
              <center>
                <h3>No Match Found</h3>
              </center>
            )}
            {pagination?.total_documents > 10 ? (
              <Col xs={12}>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="d-flex justify-content-center">
                    <Button
                      className="btn-solid btn-blue"
                      disabled={pagination?.total_pages === page ? true : false}
                      onClick={onPageChange}
                    >
                      Load More
                    </Button>
                  </div>
                )}
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FindMatchPage;
