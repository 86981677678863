import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { ArrowIcon, BehanceIcon, DribbleIcon, EmailIcon, InstagramIcon, LocationIcon, PhoneIcon, TwitterIcon } from '../../constants/svgs'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../form/CustomInput'
import { Controller, useForm } from 'react-hook-form'
import CustomTextarea from '../form/CustomTextarea'
import { NavLink, useNavigate } from 'react-router-dom'
import { error, success } from '../../constants/msg'
import { contactUs } from '../../constants/content'
import { contact_us } from '../../services/contactus'

const ContactCard = () => {
    const { handleSubmit, register, control, reset, watch, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' })
    const navigate = useNavigate()
    // const [loading, setLoading] = useState(false)
    const onSubmit = async (data) => {
        try {
            const res = await contact_us(data)
            success('Thanks for Contacting us we will get back to you soon')
            setTimeout(() => {
                navigate('/contact')
            }, 1200)
        }
        catch (e) {
            error(e?.response?.data?.message)
        }
    }

    return (
        <div className='contact-card'>
            <Row>
                <Col xs={12} xl={4} className='mb-4 mb-xl-0'>
                    <div className="left">
                        <h3 className='mb-4'><b className='ruda'>Get in touch with us connect <br /> with our  support team.</b></h3>
                        <div className='d-flex align-items-center border-top py-4'>
                            <div>
                                <EmailIcon />
                            </div>
                            <div className='ms-3'>
                                <h4>Email:</h4>
                                <h3>info@daraapp.com</h3>
                            </div>
                        </div>

                        <div className='d-flex align-items-center border-top py-4'>
                            <div>
                                <PhoneIcon />
                            </div>
                            <div className='ms-3'>
                                <h4>Call:</h4>
                                <h3>(704) 123 - 9875</h3>
                            </div>
                        </div>

                        <div className='d-flex align-items-center border-top py-4'>
                            <div>
                                <LocationIcon />
                            </div>
                            <div className='ms-3'>
                                <h4>Location:</h4>
                                <h3>4517 Washington, Manchester
                                    Kentucky 39495, Poland
                                </h3>
                            </div>
                        </div>

                        <div className='social-link border-top pt-4'>
                            <h3 className='mb-4'><b className='ruda'>FOLLOW US:</b></h3>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="wrapper">
                                    <div className="circle">
                                        <InstagramIcon />
                                    </div>
                                    <small className='ruda text-uppercase mt-2'>Instagram</small>
                                </div>

                                <div className="wrapper">
                                    <div className="circle">
                                        <TwitterIcon />
                                    </div>
                                    <small className='ruda text-uppercase mt-2'>Twitter</small>
                                </div>

                                <div className="wrapper">
                                    <div className="circle">
                                        <DribbleIcon />
                                    </div>
                                    <small className='ruda text-uppercase mt-2'>Dribble</small>
                                </div>

                                <div className="wrapper">
                                    <div className="circle">
                                        <BehanceIcon />
                                    </div>
                                    <small className='ruda text-uppercase mt-2'>Behance</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} xl={8}>
                    <div className="right">
                        <h3 className='mb-4'><b className='ruda'>Empowering lives through exceptional online Matchmaking and wedding services send us a quick message.</b></h3>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                {/* FULL NAME INPUT */}
                                <Col xs={12} sm={6}>
                                    <div className='mb-25'>
                                        <Controller
                                            control={control}
                                            name="full_name"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.FULL_NAME_REQUIRED,
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.FULL_NAME_MIN,
                                                    message: VALIDATIONS_TEXT.FULL_NAME_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.FULL_NAME_MAX,
                                                    message: VALIDATIONS_TEXT.FULL_NAME_MAX
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    label="Full Name"
                                                    placeholder="Full Name"
                                                    type="text"
                                                    required={true}
                                                    minLength={VALIDATIONS.FULL_NAME_MIN}
                                                    maxLength={VALIDATIONS.FULL_NAME_MAX}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    disabled={false}
                                                />
                                            )}
                                        />
                                        {errors.full_name && <small className='text-start d-block text-danger mt-1'>{errors.full_name.message}</small>}
                                    </div>
                                </Col>

                                {/* EMAIL INPUT */}
                                <Col xs={12} sm={6}>
                                    <div className='mb-25'>
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL
                                                },
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    label="Email Address"
                                                    placeholder="Email Address"
                                                    type="email"
                                                    required={true}
                                                    maxLength={VALIDATIONS.EMAIL}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    disabled={false}
                                                />
                                            )}
                                        />
                                        {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}
                                    </div>
                                </Col>

                                {/* PHONE INPUT */}
                                <Col xs={12} sm={6}>
                                    <div className='mb-25'>
                                        <Controller
                                            control={control}
                                            name="phone"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.PHONE_MIN,
                                                    message: VALIDATIONS_TEXT.PHONE_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.PHONE_MAX,
                                                    message: VALIDATIONS_TEXT.PHONE_MAX
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    label="Phone"
                                                    placeholder="Phone"
                                                    type="text"
                                                    required={true}
                                                    minLength={VALIDATIONS.PHONE_MIN}
                                                    maxLength={VALIDATIONS.PHONE_MAX}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    disabled={false}
                                                />
                                            )}
                                        />
                                        {errors.phone && <small className='text-start d-block text-danger mt-1'>{errors.phone.message}</small>}
                                    </div>
                                </Col>

                                {/* SUBJECT INPUT */}
                                <Col xs={12} sm={6}>
                                    <div className='mb-25'>
                                        <Controller
                                            control={control}
                                            name="subject"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SUBJECT_REQUIRED,
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.SUBJECT_MIN,
                                                    message: VALIDATIONS_TEXT.SUBJECT_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.SUBJECT_MAX,
                                                    message: VALIDATIONS_TEXT.SUBJECT_MAX
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    label="Subject"
                                                    placeholder="Subject"
                                                    type="text"
                                                    required={true}
                                                    minLength={VALIDATIONS.SUBJECT_MIN}
                                                    maxLength={VALIDATIONS.SUBJECT_MAX}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    disabled={false}
                                                />
                                            )}
                                        />
                                        {errors.subject && <small className='text-start d-block text-danger mt-1'>{errors.subject.message}</small>}
                                    </div>
                                </Col>

                                {/* MESSAGE INPUT */}
                                <Col xs={12} sm={12}>
                                    <div className='mb-25'>
                                        <Controller
                                            control={control}
                                            name="message"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.MESSAGE_REQUIRED,
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.MESSAGE_MIN,
                                                    message: VALIDATIONS_TEXT.MESSAGE_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.MESSAGE_MAX,
                                                    message: VALIDATIONS_TEXT.MESSAGE_MAX
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomTextarea
                                                    {...field}
                                                    label="Message"
                                                    placeholder="Message"
                                                    type="text"
                                                    required={true}
                                                    minLength={VALIDATIONS.MESSAGE_MIN}
                                                    maxLength={VALIDATIONS.MESSAGE_MAX}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    disabled={false}
                                                />
                                            )}
                                        />
                                        {errors.message && <small className='text-start d-block text-danger mt-1'>{errors.message.message}</small>}
                                    </div>
                                </Col>

                                {/* SUBMIT BUTTON */}
                                <Col xs={12}>
                                    <Button className="btn-solid btn-blue mt-4" type="submit" >
                                        SUBMIT
                                        <div className="circle">
                                            <ArrowIcon />
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ContactCard