import React, { useState } from 'react'

const ErrorPage = () => {
    const [json] = useState([
        {
            fname: "asdas",
            lname: "asd",
            familycount: 20,
            pname: {
                label: "Valima",
                value: 0,
            }
        },
        {
            fname: "asdas",
            lname: "asd",
            familycount: 25,
            pname: {
                label: "Barat",
                value: 0,
            }
        },
        {
            fname: "asdas",
            lname: "asd",
            familycount: 20,
            pname: {
                label: "Valima",
                value: 0,
            }
        },
        {
            fname: "asdas",
            lname: "asd",
            familycount: 10,
            pname: {
                label: "Barat",
                value: 0,
            }
        }
    ])

    const [allowed] = useState([
        {
            label: "Barat",
            totalcount: 200,
        },
        {
            label: "Valima",
            totalcount: 100,
        }
    ])

    const totalMemberComeInEvent = () => {
        let jsons = [...json]
        let alloweds = [...allowed]

        for (let i = 0; i < jsons.length; i++) {
            let eventNameOfJson = jsons[i].pname.label
            let eventIndexOfAllowed = alloweds.findIndex((item) => item.label == eventNameOfJson)

            alloweds[eventIndexOfAllowed].coming = (alloweds[eventIndexOfAllowed].coming || 0) + jsons[i].familycount

        }

        console.log(alloweds)
    }

    totalMemberComeInEvent()
    return (
        <div>404 Not Found</div>
    )
}

export default ErrorPage