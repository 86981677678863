import React, { forwardRef, useState, useContext } from "react";
import { UploadIcon } from "../../constants/svgs";
import { createImagePreview } from "../../utils/helper";
import Assets from "../../constants/images";
import { FaTimes } from "react-icons/fa";
import { uploadImageContext } from "../../App";
import { constant } from "../../utils/constants";

const CustomFileUploader = forwardRef((props, ref) => {
  const [uploadImage, setUploadImage] = useContext(uploadImageContext);
  const {
    field,
    name,
    value,
    onChange,
    setValue,
    multiple = false,
    accept,
    disabled = false,
  } = props;

  const handleRemoveImg = (index) => {
    const files = { ...value };
    uploadImage.splice(index, 1);
    delete files[index];
    if (Object.keys(files).length === 0) {
      setValue(name, undefined);
    } else {
      setValue(name, files);
    }
  };

  return (
    <div className="custom-file-uploader">
      <label id="file" className="custom-file-uploader-wrapper">
        <input
          {...field}
          type="file"
          id="file"
          onChange={(e) => {
            onChange(e.target.files);
            const filesArray = Array.from(e.target.files);
            setUploadImage((prevImages) => [...prevImages, ...filesArray]);
          }}
          className="d-none"
          multiple={multiple}
          accept={accept}
          disabled={disabled}
        />
        <div className="icon-wrapper mb-3">
          <UploadIcon />
        </div>
        <span className="color-black">
          <span>Browse file</span> to upload or drag & drop media here
        </span>
      </label>

      <div className="images-box">
        {uploadImage &&
          Object.entries(uploadImage)?.map(([key, file]) => (
            <>
              < div className="img-wrapper me-3 mt-3" key={key} >
                <div className="times-icon" onClick={() => handleRemoveImg(key)}>
                  <FaTimes className="icon" />
                </div>
                <img
                  src={
                    // uploadImage.length > 0
                    //   ? `${constant.BASE_URL}/${file}`
                    //   : createImagePreview(file)
                    createImagePreview(file)
                  }
                  onError={() => Assets.GeneralPlaceholderImg}
                  alt=""
                />
              </div>
            </>
          ))}
      </div>
    </div >
  );
});

export default CustomFileUploader;
