import React, { useState, useEffect } from 'react'
import SettingHeader from '../components/static/SettingHeader'
import { Col, Container, Row } from 'react-bootstrap'
import SettingSidebar from '../components/static/SettingSidebar'
import Assets from '../constants/images'
import { GetAuthUserLocalStorage } from '../services/localStorage/localStorage'
import { GetUserById } from '../services/userProfile'
import { constant } from '../utils/constants'
import CustomCircularProgressbar from '../components/general/CustomCircularProgressbar'

const MyProfilePage = () => {
    const user = GetAuthUserLocalStorage()
    const [profileData, setProfileData] = useState([])
    const splited_data = user?.height ? user?.height?.toString().split('.') : ''
    return (
        <div className='static-pages my-profile-page'>
            <div className="d-none d-md-block">
                <SettingHeader />
            </div>

            <Container>
                <Row>
                    <Col md={3} lg={3} xl={3} className='d-none d-md-block'>
                        <SettingSidebar />
                    </Col>
                    <Col md={9} lg={9} xl={9}>
                        <div className="content py-5 py-md-5 ps-md-5">
                            <h2 className='color-blue mb-4'>My Profile</h2>

                            <Row>
                                <Col lg={12} xl={4}>
                                    <div className="box">
                                        <div className="profile-wrapper mb-3">
                                            <img src={user?.images && user?.images?.length > 0 ? `${constant.BASE_URL}/${user?.images[0]}` : Assets.GeneralPlaceholderImg} alt="" onError={(e) => e.target.src = Assets.GeneralPlaceholderImg} onError={(e) => e.target.src = Assets.GeneralPlaceholderImg} alt="" />
                                        </div>

                                        <div className="d-flex align-items-center flex-wrap">
                                            {
                                                user && user?.images?.length > 0 ?
                                                    user?.images.map((item, index) => {
                                                        return (
                                                            <div className="img-wrapper me-2 mb-2" key={index}>
                                                                <img src={`${constant.BASE_URL}/${item}`} onError={(e) => e.target.src = Assets.GeneralPlaceholderImg} alt="" />
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                </Col>

                                <Col xl={8} className='mt-3 mt-xl-0'>
                                    <div>
                                        <h3 className='mb-1'><b>{user?.first_name + ' ' + user?.last_name}</b> <sub>({user?.age || '--'} Years)</sub></h3>
                                        <h3 className='mb-1 fw-normal'>{user?.gender || ''}</h3>
                                        <h3 className='fw-normal'>{user?.country || ''}</h3>
                                    </div>
                                    {
                                        user && user?.mother_tongue && user?.mother_tongue?.length > 0 ?
                                            <>


                                                <div className="list py-4 my-4">
                                                    <Row>
                                                        <Col xs={12}>
                                                            <Row>
                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Mother Tongue</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>
                                                                        {user?.mother_tongue?.length > 0 ? (
                                                                            <h4 className='ps-0'>
                                                                                {user?.mother_tongue?.join(',')}
                                                                            </h4>
                                                                        ) : ''}
                                                                    </h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Cast</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.cast}</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Religion</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.religion}</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Martial Status</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.marital_status}</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Spoken Language</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>
                                                                        {user?.spoken_language?.length > 0 ?
                                                                            user?.spoken_language?.join(', ')
                                                                            : ''}
                                                                    </h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Physical Status</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.physical_status}</h4>
                                                                </Col>

                                                                {/* <Col xs={12} sm={6} className='mb-4'>
                                                    <h4 className='color-grey'>Body Type</h4>
                                                </Col>

                                                <Col xs={12} sm={6} className='mb-4'>
                                                    <h4 className='ps-0 ps-sm-3'>Normal</h4>
                                                </Col>

                                                <Col xs={12} sm={6} className='mb-4'>
                                                    <h4 className='color-grey'>Medical</h4>
                                                </Col>

                                                <Col xs={12} sm={6} className='mb-4'>
                                                    <h4 className='ps-0 ps-sm-3'>N/A</h4>
                                                </Col> */}

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Height</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{splited_data.length > 0 ? splited_data[0] + "''" + (splited_data[1] ? `'${splited_data[1]}` : 0) : ''}</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Complexion</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.complexion}</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Drinking Habit</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.drinking_habit || '-'}</h4>
                                                                </Col>

                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Eating Habit</h4>
                                                                </Col>
                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.eating_habit || '-'}</h4>
                                                                </Col>
                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Preferred Country</h4>
                                                                </Col>
                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.preferredCountry || '-'}</h4>
                                                                </Col>
                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='color-grey'>Preferred Religion</h4>
                                                                </Col>
                                                                <Col xs={12} sm={6} className='mb-4'>
                                                                    <h4 className='ps-0 ps-sm-3'>{user?.preferredReligion || '-'}</h4>
                                                                </Col>

                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <h2 className='color-blue mb-4'>About Me</h2>
                                                <p>
                                                    {user?.about}
                                                </p>
                                            </>
                                            :
                                            <div className='mt-5 mb-5 complete_profile text-danger'>
                                                <p>
                                                    First Complete Your Profile!
                                                </p>
                                                <div>
                                                    <CustomCircularProgressbar />
                                                </div>
                                            </div>


                                    }



                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyProfilePage