import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { VALIDATIONS_TEXT } from "../../constants/app-constants";
import CustomCheck from "../form/CustomCheck";
import { subscribeplanDataContext } from "../../App";

const TenureCard = ({ control, errors }) => {
  const [subscribeplanData, setSubscribeplanData] = useContext(
    subscribeplanDataContext
  );
  const [tenures, setTenures] = useState([]);

  useEffect(() => {
    // Update tenures when subscribeplanData changes
    setTenures([
      {
        id: "per_month",
        duration: "One Month",
        price: `${
          subscribeplanData ? subscribeplanData[0]?.price?.per_month : "-"
        } AED`,
      },
      {
        id: "per_three_month",
        duration: "Three Month",
        price: `${
          subscribeplanData && subscribeplanData[0]
            ? subscribeplanData[0]?.price?.per_three_month
            : "-"
        } AED`,
      },
      {
        id: "per_six_month",
        duration: "Six Month",
        price: `${
          subscribeplanData && subscribeplanData[0]
            ? subscribeplanData[0]?.price?.per_six_month
            : "-"
        } AED`,
      },
    ]);
  }, [subscribeplanData]);

  return (
    <div className="tenure-card pb-4 mb-4">
      <h3 className="mb-4">Select Plan Tenure</h3>
      <ul>
        {tenures?.map((item, index) => (
          <li
            key={index}
            className="mb-3 pb-3 d-flex align-items-center justify-content-between"
          >
            <div className="radios">
              <Controller
                control={control}
                name="tenure"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.TENURE_REQUIRED,
                  },
                }}
                render={({ field }) => (
                  <CustomCheck
                    {...field}
                    name="tenure"
                    label={item?.duration}
                    type="radio"
                    value={item.id}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <h4>{item?.price}</h4>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TenureCard;
