const Assets = {
    AuthLogo: require("../assets/img/auth-logo.png"),
    RingLogo: require("../assets/img/cardtemplogo.png"),
    StaticLogo: require("../assets/img/static-logo.png"),
    AuthImg: require("../assets/img/auth-img.png"),
    AboutUsImg: require("../assets/img/about-us.png"),
    ContactUsImg: require("../assets/img/contact-us.png"),
    FindMatchImg: require("../assets/img/find-match.png"),
    WhoWeAreImg: require("../assets/img/who-we-are.png"),
    PaypalImg: require("../assets/img/paypal.png"),
    MasterCardImg: require("../assets/img/master.png"),
    VisaCardImg: require("../assets/img/visa.png"),
    GeneralPlaceholderImg: require("../assets/img/general-placeholder.png"),
    Match1Img: require("../assets/img/match1.png"),
    Match2Img: require("../assets/img/match2.png"),
    Match3Img: require("../assets/img/match3.png"),
    Match4Img: require("../assets/img/match4.png"),
    Match5Img: require("../assets/img/match5.png"),
    Match6Img: require("../assets/img/match6.png"),
    Match7Img: require("../assets/img/match7.png"),
    Match8Img: require("../assets/img/match8.png"),
    Match9Img: require("../assets/img/match9.png"),
    Match10Img: require("../assets/img/match10.png"),
    Match11Img: require("../assets/img/match11.png"),
    Match12Img: require("../assets/img/match12.png"),
    Match13Img: require("../assets/img/match13.png"),
    Match14Img: require("../assets/img/match14.png"),
    Match15Img: require("../assets/img/match15.png"),
    Match16Img: require("../assets/img/match16.png"),
    Match17Img: require("../assets/img/match17.png"),
    NotificationImg: require("../assets/img/notification.png"),
    WeddingCard: require("../assets/img/wedding-card.png"),
    Vendor1: require("../assets/img/vendor1.png"),
    Vendor2: require("../assets/img/vendor2.png"),
    Vendor3: require("../assets/img/vendor3.png"),
    Vendor4: require("../assets/img/vendor4.png"),

}

export default Assets 