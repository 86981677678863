import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import CustomInput from '../form/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { Button, Col, Row, Form } from 'react-bootstrap'
import { ArrowIcon, ArrowLeft, DeleteIcon } from '../../constants/svgs'
import CustomSelect from '../form/CustomSelect'
import { IoMdAdd } from "react-icons/io"
import { DropDown } from '../../services/dropdown'
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment/moment'

const ProgramForm = ({ watch, control, formState, setValue, handleNextStep, handlePreviousStep }) => {
    // var DateTimeField = require('react-bootstrap-datetimepicker');
    const [selectedDate, setSelectedDate] = useState(new Date);
    const { errors, isDirty, isValid, isSubmitting } = formState
    const [programs, religion] = watch(['programs', 'religion'])
    const [selectedPrograms, setSelectedPrograms] = useState([])
    const { fields, append, remove } = useFieldArray({ control, name: 'programs' })
    const [religionData, setReligionData] = useState([]);
    const [programsList, setProgramsList] = useState([])
    const handleDateChange = (index, date) => {
        // Update the form value with the selected date
        setValue(`programs[${index}].date`, date);
    };
    const renderInput = (props, openCalendar, closeCalendar) => {
        const handleClick = () => {
            openCalendar();
            // You can perform additional actions when the "OK" button is clicked
        }
    }
    const addPrograms = () => {
        append({ name: '', budget: '', date: '' })
    }
    useEffect(() => {
        GetReligionDropDown()
    }, [])
    useEffect(() => {
        if (religion) {
            GetEventsReligionWiseDropDown()
        }
    }, [religion])


    const GetReligionDropDown = async () => {
        const params = {
            type: "religion",
        };
        const res = await DropDown(params);
        const response = res?.data?.data?.map((dt) => ({
            value: dt?._id,
            label: dt?.name,
        }));
        setReligionData(response);
    };

    const GetEventsReligionWiseDropDown = async () => {
        const params = {
            type: "event",
            religion: religion?.label.split(' ').join('-'),
        };
        const res = await DropDown(params);
        const response = res?.data?.data
        setProgramsList(response);
    };

    const handleProgramChange = (programData) => {

        let tempPrograms = [...selectedPrograms]
        const index = tempPrograms.findIndex(program => program?.id == programData?._id);

        if (index !== -1) {
            tempPrograms.splice(index, 1)
            setSelectedPrograms(tempPrograms)
        }
        else {
            tempPrograms.push({
                id: programData?._id,
                name: programData?.name,
                budget: ""
            })
            setSelectedPrograms(tempPrograms)
        }
    }

    const handleBudgetChange = (e, programData) => {
        let tempPrograms = [...selectedPrograms]
        const index = tempPrograms.findIndex(program => program?.id == programData?._id);
        if (index != -1) {
            tempPrograms[index].budget = e.target.value
            setSelectedPrograms(tempPrograms)
        }
    }

    const isOtherRequired = () => {
        const index = selectedPrograms.findIndex(program => program?.name?.toLowerCase() == 'other')
        if (index != -1) {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        setValue('programs', selectedPrograms)
    }, [selectedPrograms])

    useEffect(() => {
        if (programs?.length > 0) {
            setSelectedPrograms(programs)
        }
    }, [])
    let inputProps = {
        placeholder: 'Select Date & Time',
    };
    return (
        <div className='program-form guest-list-form'>
            <Row>
                <Col xs={12} xl={11} className='border-bottom pb-3 mb-4'>
                    <h3 className='fw-800 color-blue'>Ceremony Programs</h3>
                    <i className='italic-tag-line fw-400 mb-5 color-grey'>Enter the ceremonial program details</i>
                </Col>
                <Col xs={12} xl={11}>
                    {/* RELIGION INPUT */}
                    <div className="mb-25">
                        <Controller
                            control={control}
                            name="religion"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.RELIGION_REQUIRED,
                                },
                            }}
                            render={({ field }) => (
                                <CustomSelect
                                    {...field}
                                    placeholder="Select Religion"
                                    label="Select Religion"
                                    options={religionData}
                                    required={true}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.religion && <small className='text-start d-block text-danger mt-1'>{errors.religion.message}</small>}
                    </div>
                    <Row>
                        <Col xs={12} xl={11}>
                            <div className={`mb-25 d-flex align-items-center justify-content-between`} >
                                <div className={`d-flex align-items-center`}>
                                    <div className={`add-cirlce ${fields?.length > 0 ? fields?.length === 3 ? 'd-none' : '' : ''}`} onClick={addPrograms}>
                                        <IoMdAdd size={22} />
                                    </div>
                                    <h6 className={`ms-3 color-dark ${fields?.length > 0 ? fields?.length === 3 ? 'd-none' : '' : ''}`}>ADD PROGRAMS</h6>
                                </div>
                                <div className=' d-flex align-items-center'>
                                    <div className="guest-cirlce">
                                        <h6>{fields?.length}</h6>
                                    </div>
                                    <h6 className='ms-3 color-dark'>Program Added</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* CHECKBOXES */}
                    <Row className={`mb-25 ${fields?.length > 0 ? "d-flex" : "d-none"}`}>
                        <Col xs={12}>
                            <span className='d-block w-100 border-bottom pb-3 mb-3'><b>Program Details</b></span>
                        </Col>
                    </Row>
                    <Row>
                        {
                            fields.map((programs, index) => (
                                <React.Fragment>
                                    {/* NAME INPUT */}
                                    <Col xs={12} key={index} className="mb-4 dd-flex flex-column flex-sm-row align-items-sm-center">
                                        <Row>
                                            <Col xs={6}>
                                                <div className="mb-2">
                                                    <Controller
                                                        control={control}
                                                        name={`programs[${index}].name`}
                                                        rules={{
                                                            minLength: {
                                                                value: VALIDATIONS.NAME_MIN,
                                                                message: VALIDATIONS_TEXT.NAME_MIN
                                                            },
                                                            maxLength: {
                                                                value: 20,
                                                                message: VALIDATIONS_TEXT.NAME_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <CustomInput
                                                                {...field}
                                                                label="Program Name"
                                                                placeholder="Program Name"
                                                                type="text"
                                                                minLength={VALIDATIONS.NAME_MIN}
                                                                maxLength={20}
                                                                required={true}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                            />
                                                        )}
                                                    />
                                                    {errors.programs && errors.programs[index] && errors.programs[index].name && <small className='text-start d-block text-danger mt-1'>{errors.programs[index].name.message}</small>}
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name={`programs[${index}].budget`}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.BUDGET_REQUIRED,
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Max length is 10'
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <CustomInput
                                                                {...field}
                                                                label="Total Budget"
                                                                placeholder="Total Budget"
                                                                type="number"
                                                                required={true}
                                                                maxLength={10}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                            />
                                                        )}
                                                    />
                                                    {errors.programs && errors.programs[index] && errors.programs[index].budget && <small className='text-start d-block text-danger mt-1'>{errors.programs[index].budget.message}</small>}
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="d-flex" key={programs.id}>
                                            <div>
                                                <Controller
                                                    control={control}
                                                    name={`programs[${index}].date`}
                                                    render={({ field }) => (
                                                        <DateTime
                                                            inputProps={inputProps}
                                                            closeOnSelect
                                                            onChange={(date) => handleDateChange(index, date)}
                                                            isValidDate={(currentDate) =>
                                                                currentDate.isAfter(moment().subtract(1, 'days'))
                                                            }
                                                            value={field.value}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='ms-3 mt-1' onClick={() => remove(index)}>
                                                <DeleteIcon />
                                            </div>
                                        </div>
                                    </Col>

                                </React.Fragment>
                            ))
                        }
                    </Row>

                    {/* NEXT STEP BUTTON */}
                    <div className='mt-5 d-flex flex-column flex-sm-row align-items-sm-center '>
                        <Button onClick={handlePreviousStep} className="btn-outline btn-dark" type="button">
                            <div className="circle">
                                <ArrowLeft />
                            </div>
                            PREVIOUS STEP
                        </Button>

                        <Button onClick={handleNextStep} className="ms-sm-4 mt-3 mt-sm-0 btn-solid btn-blue" type="submit" disabled={!isDirty || !isValid || fields?.length == 0}>
                            NEXT STEP
                            <div className="circle">
                                <ArrowIcon />
                            </div>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ProgramForm