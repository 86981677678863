import React from 'react'
import { Modal } from "react-bootstrap"
import Assets from '../../constants/images'
import { NavLink, useNavigate } from 'react-router-dom'
import { ArrowProfileIcon } from '../../constants/svgs'
import { LiaTimesSolid } from "react-icons/lia";
import CustomCircularProgressbar from '../general/CustomCircularProgressbar'
import { GetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { constant } from '../../utils/constants'

const ProfilePopup = ({ profilePopup, setProfilePopup, handleLogoutPopup, handleDeletePopup }) => {
    const user = GetAuthUserLocalStorage()
    const navigate = useNavigate()

    const closeModal = () => {
        setProfilePopup(false)
    }

    return (
        <Modal
            className='theme-popup profile-popup'
            show={profilePopup}
            onHide={closeModal}
            size="sm"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div>
                    <div className="profile">
                        <div className='text-end'>
                            <LiaTimesSolid size={22} className='icon' onClick={closeModal} />
                        </div>

                        <div className="d-flex justify-content-between mt-2">
                            <div className="img-wrapper"
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate('/setting/my-profile')}
                            >
                                <img src={user && user?.images && user?.images.length > 0 ? constant.BASE_URL + '/' + user?.images[0] : Assets.GeneralPlaceholderImg} onError={(e) => e.target.src = Assets.GeneralPlaceholderImg} alt="" />
                            </div>

                            <div className='d-flex align-items-center justify-content-center flex-column progress-wrapper' >
                                <CustomCircularProgressbar />
                                <small className='color-grey'>Profile Complete</small>
                            </div>

                        </div>
                        <h6 className='mt-2'>{user?.first_name}</h6>
                    </div>

                    <div className="link-wrapper">
                        <ul>
                            {
                                user?.isProfileCompleted ? '' :
                                    <li className='d-flex align-items-center justify-content-between my-2 pt-2'>
                                        <NavLink onClick={closeModal} to="/complete-profile">Complete Profile</NavLink>
                                        <ArrowProfileIcon />
                                    </li>
                            }

                            <li className='d-flex align-items-center justify-content-between my-2 pt-2'>
                                <NavLink onClick={closeModal} to="/setting/my-profile">My Profile</NavLink>
                                <ArrowProfileIcon />
                            </li>

                            <li className='d-flex align-items-center justify-content-between my-2 pt-2'>
                                <NavLink onClick={closeModal} to="/setting/my-profile">Edit Profile</NavLink>
                                <ArrowProfileIcon />
                            </li>

                            <li className='d-flex align-items-center justify-content-between my-2 pt-2'>
                                <NavLink onClick={closeModal} to="/setting/my-matches">My Matches</NavLink>
                                <ArrowProfileIcon />
                            </li>

                            <li className='d-flex align-items-center justify-content-between my-2 pt-2'>
                                <NavLink onClick={closeModal} to="/setting/my-messages">My Messages</NavLink>
                                <ArrowProfileIcon />
                            </li>

                            <li className='d-flex align-items-center justify-content-between my-2 pt-2'>
                                <NavLink onClick={closeModal} to="/setting/my-membership">Your Membership</NavLink>
                                <ArrowProfileIcon />
                            </li>

                            <li className='d-flex align-items-center justify-content-between my-2 pt-2'>
                                <NavLink onClick={closeModal} to="/setting/notifications">Notifications</NavLink>
                                <ArrowProfileIcon />
                            </li>

                            <li className='d-flex align-items-center justify-content-between mt-2 pt-2' onClick={handleLogoutPopup}>
                                <span>Log Out</span>
                                <ArrowProfileIcon />
                            </li>

                            <li className='d-flex align-items-center justify-content-between mt-2 pt-2' onClick={handleDeletePopup}>
                                <span
                                    style={{
                                        color: 'red'
                                    }}
                                >Delete Account</span>
                                <ArrowProfileIcon />
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ProfilePopup