import React, { useEffect, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import PersonalInformationForm from "./PersonalInformationForm";
import BudgetForm from "./BudgetForm";
import ProgramForm from "./ProgramForm";
import GuestListForm from "./GuestListForm";
import CollaboratedTeamForm from "./CollaboratedTeamForm";
import WeddingCardForm from "./WeddingCardForm";
import OverviewForm from "./OverviewForm";
import VendorsForm from "./VendorsForm";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { createProject, updateProject } from "../../services/projects";
import { error, success } from "../../constants/msg";
import { weddingFileContexts, weddingLoadingContext } from "../../App";
import GuestCountForm from "./GuestsCount";
import SelectedVendorsDetails from "./SelectedVendorsDetails";
import SelectedVendorsDetailsForm from "./SelectedVendorsDetails";
import RsvpandGreetingForm from "./RsvpandGreetingForm";
import { uploadImageAPI } from "../../services/uploadimage";

const CreateProjectForm = ({
  activeStep,
  setActiveStep,
  hookForm,
  project,
  ...props
}) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState,
  } = hookForm;
  const [
    brideName,
    groomName,
    budget,
    programs,
    guestscounts,
    vendors,
    guests,
    collaboratedTeam,
    currency,
    religion,
    weddingTemplates,
  ] = watch([
    "brideName",
    "groomName",
    "budget",
    "programs",
    "guestscounts",
    "vendors",
    "guests",
    "collaboratedTeam",
    "currency",
    "religion",
    "weddingTemplates",
  ]);
  const [loading, setLoading] = useContext(weddingLoadingContext);
  const [weddingFile, setWeddingFile] = useContext(weddingFileContexts);

  const [InvitationFiles, setInvitations] = useState([]);
  const LoggedInUserData = GetAuthUserLocalStorage();
  const navigate = useNavigate();
  const { id } = useParams()

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };


  const handleNextStepInvitation = async () => {
    setActiveStep(activeStep + 1);

    const tempData = [];
    for (let index = 0; index < weddingFile.length; index++) {
      const item = weddingFile[index];
      const formData = new FormData();

      const key = `image`;

      // Convert blob URL to blob
      const response = await fetch(item);
      const blob = await response.blob();

      // Create a file from the blob
      const file = new File([blob], `image-${index}.png`, { type: 'image/png' });

      formData.append(key, file);

      try {
        const res = await uploadImageAPI(formData);
        const responseImage = res?.data?.data?.filename;
        tempData.push({ 'plan_name': programs[index]?.name, 'url': responseImage });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
    setInvitations(tempData);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = async (data) => {
    const dataArr = [];
    const createData = {};
    createData["created_by"] = LoggedInUserData?._id;
    createData["personal_information"] = {
      bride_name: data?.brideName,
      groom_name: data?.groomName,
    };
    createData["currency"] = data?.currency?.value;
    createData["budget"] = data?.budget;
    createData["religion"] = data?.religion?.label;
    createData["programs"] = data?.programs;
    createData["vendors"] = data?.vendors?.map((dt) => dt?._id) || [];
    createData["guest_count"] = data?.guestscounts?.map((dt) => {
      return {
        program_name: dt?.name?.label,
        family_count: dt?.familycount
      }
    })
    createData["Guests"] = data?.guests?.map((dt) => {
      return {
        program_name: dt?.pname?.label,
        email_address: dt?.email,
        family_count: dt?.familycount,
        first_name: dt?.fname,
        last_name: dt?.lname
      };
    });
    createData["event_plan"] = data?.eventPlanning?.map((dt) => {
      return {
        program: dt?.eventname?.label,
        vendors_name: dt?.vendorname?.label,
      };
    });
    createData["wedding_card"] = InvitationFiles
    createData["rsvp"] = data?.rsvp?.map((dt) => {
      return {
        name: dt?.name,
        email: dt?.email,
        program_name: dt?.programname?.label,
        contact: dt?.cell,
      };
    });

    createData["collaborated_team"] = data?.collaboratedTeam?.map((dt) => {
      return {
        email_address: dt?.email,
        assigned_task: dt?.task,
        first_name: dt?.fname,
        last_name: dt?.lname,
        program_name: dt?.pname?.label,
      };
    });
    dataArr.push(createData);

    try {
      let response;

      if (!id) {
        // Create new project
        response = await createProject(createData);
      } else {
        // Update existing project
        response = await updateProject(id, createData);
      }

      // Handle success
      success(response?.data?.message);
      props?.setPdf(response?.data?.data?.plan_file);
      props?.setBalanceBudget(response?.data?.data);
      setLoading(true);
    } catch (error) {
      console.error('API error:', error);

      // Handle error
      error(error?.response?.data?.message || 'An error occurred');
    }
  };

  const stepForm = {
    1: (
      <PersonalInformationForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    2: (
      <BudgetForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    3: (
      <ProgramForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    4: (
      <GuestCountForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    5: (
      <VendorsForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    6: (
      <SelectedVendorsDetailsForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    7: (
      <GuestListForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        getValues={getValues}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    8: (
      <CollaboratedTeamForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    9: (
      <RsvpandGreetingForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    10: (
      <WeddingCardForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStepInvitation}
        handlePreviousStep={handlePreviousStep}
        project={project}
      />
    ),
    11: (
      <OverviewForm
        watch={watch}
        control={control}
        formState={formState}
        setValue={setValue}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
        project={project}
      />
    ),
  };

  return (
    <div className="create-project-form">
      <Form onSubmit={handleSubmit(onSubmit)}>{stepForm[activeStep]}</Form>
    </div>
  );
};

export default CreateProjectForm;
