import React, { useContext, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../form/CustomInput'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { NavLink, useNavigate } from 'react-router-dom'
import { ArrowIcon } from '../../constants/svgs'
import { error, success } from '../../constants/msg'
import { Signup } from '../../services/auth/auth'
import { SetAuthUserLocalStorage, SetLocalStorageSignUpEmail, SetTokenLocalStorage, SetTokenLocalStorageSignUpEmail } from '../../services/localStorage/localStorage'
import { fogotPassContext } from '../../App'
import CustomCheck from '../form/CustomCheck'

const SignupForm = () => {
    const { handleSubmit, register, control, reset, watch, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' })
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [forgotData, setForgotData] = useContext(fogotPassContext)
    const password = watch("password")
    const navigate = useNavigate()

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const res = await Signup(data)
            success(res?.data?.message)
            // data.type = "registration"
            setTimeout(() => {
                navigate('/otp-verification', { state: 'register' })
            }, 1200)
            setLoading(false)
            const regEmail = {
                'email':
                    res?.data?.data?.email
            }
            setForgotData(regEmail)

        } catch (e) {
            setLoading(false)
            error(e.response.data.message
            );
        }
    }

    return (
        <div className='auth signup-form'>
            <h1>Register Your Account</h1>
            <h3 className='mt-4'>
                Give us some of your information to get access to Rishtey & Shadi
                Fill the information below to register
            </h3>

            <div>
                <Form className='form-wrapper mt-5' onSubmit={handleSubmit(onSubmit)}>
                    {errors.is_agreed && <small className='text-start d-block text-danger mt-1 mb-3'>{errors.is_agreed.message}</small>}

                    <Row>
                        {/* FIRST NAME INPUT */}

                        <Col sm={6}>
                            <div className='mb-25'>
                                <Controller
                                    control={control}
                                    name="first_name"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.FIRST_NAME_REQUIRED,
                                        },
                                        minLength: {
                                            value: VALIDATIONS.FIRST_NAME_MIN,
                                            message: VALIDATIONS_TEXT.FIRST_NAME_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.FIRST_NAME_MAX,
                                            message: VALIDATIONS_TEXT.FIRST_NAME_MAX
                                        },
                                    }}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="First Name"
                                            placeholder="First Name"
                                            type="text"
                                            required={true}
                                            minLength={VALIDATIONS.FIRST_NAME_MIN}
                                            maxLength={VALIDATIONS.FIRST_NAME_MAX}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {errors.first_name && <small className='text-start d-block text-danger mt-1'>{errors.first_name.message}</small>}
                            </div>
                        </Col>

                        {/* LAST NAME INPUT */}
                        <Col sm={6}>
                            <div className='mb-25'>
                                <Controller
                                    control={control}
                                    name="last_name"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.LAST_NAME_REQUIRED,
                                        },
                                        minLength: {
                                            value: VALIDATIONS.LAST_NAME_MIN,
                                            message: VALIDATIONS_TEXT.LAST_NAME_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.LAST_NAME_MAX,
                                            message: VALIDATIONS_TEXT.LAST_NAME_MAX
                                        },
                                    }}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Last Name"
                                            placeholder="Last Name"
                                            type="text"
                                            required={true}
                                            minLength={VALIDATIONS.LAST_NAME_MIN}
                                            maxLength={VALIDATIONS.LAST_NAME_MAX}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {errors.last_name && <small className='text-start d-block text-danger mt-1'>{errors.last_name.message}</small>}
                            </div>
                        </Col>

                        {/* EMAIL INPUT */}
                        <Col sm={6}>
                            <div className='mb-25'>
                                <Controller
                                    control={control}
                                    name="email"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    }}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Email Address"
                                            placeholder="Email Address"
                                            type="email"
                                            required={true}
                                            maxLength={VALIDATIONS.EMAIL}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}
                            </div>
                        </Col>

                        {/* PASSWORD INPUT */}
                        <Col sm={6}>
                            <div className='mb-25'>
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                                        },
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        },
                                        pattern: {
                                            value: /^(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                                            message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className="password-input">
                                            <CustomInput
                                                {...field}
                                                label="Password"
                                                placeholder="Password"
                                                type={showPassword ? "text" : "password"}
                                                required={true}
                                                minLength={VALIDATIONS.PASSWORD_MIN}
                                                maxLength={VALIDATIONS.PASSWORD_MAX}
                                                value={field.value}
                                                onChange={field.onChange}

                                            />
                                            <span className='eye-icon' onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                            </span>
                                        </div>
                                    )}
                                />
                                {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}
                            </div>
                        </Col>

                        {/* CONFIRM PASSWORD INPUT */}
                        <Col sm={12}>
                            <div className='mb-25'>
                                <Controller
                                    control={control}
                                    name="confirm_password"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                                        },
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        },
                                        validate: (value) =>
                                            value === password || "The Passwords do not match"
                                    }}
                                    render={({ field }) => (
                                        <div className="password-input">
                                            <CustomInput
                                                {...field}
                                                label="Confirm Password"
                                                placeholder="Confirm Password"
                                                type={showConfirmPassword ? "text" : "password"}
                                                required={true}
                                                minLength={VALIDATIONS.PASSWORD_MIN}
                                                maxLength={VALIDATIONS.PASSWORD_MAX}
                                                value={field.value}
                                                onChange={field.onChange}

                                            />
                                            <span className='eye-icon' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                {showConfirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                            </span>
                                        </div>
                                    )}
                                />
                                {errors.confirm_password && <small className='text-start d-block text-danger mt-1'>{errors.confirm_password.message}</small>}
                            </div>
                        </Col>
                        {errors.is_agreed && <small className='text-start d-block text-danger mt-1 mb-3'>{errors.is_agreed.message}</small>}

                        {/* AGREEMENT */}
                        <Col sm={12}>
                            <div className="checkbox">
                                <Controller
                                    control={control}
                                    name="is_agreed"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Please Agree the terms and conditions',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <CustomCheck
                                            {...field}
                                            name="agree"
                                            label={<>
                                                By clicking the button, I accept the <a href="terms-and-conditions" target='_blank'>Terms of Use of the Service</a> and
                                                its <a href="terms-and-conditions" target='_blank'>Privacy Policy</a> , as well as consent to the processing of personal data
                                            </>}
                                            type="checkbox"
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />

                            </div>
                        </Col>

                        {/* BUTTON AND LINKS */}
                        <Col xs={12}>
                            <Button className="btn-solid btn-blue mt-30" type="submit" disabled={loading}>
                                CREATE NEW ACCOUNT
                                <div className="circle">
                                    <ArrowIcon />
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <div className='mb-3'>
                    <span>Already have an account? &nbsp;</span>
                    <NavLink to="/signin" className="color-dark">SIGN IN</NavLink>
                </div>
            </div>
        </div>
    )
}

export default SignupForm