import React, { useContext, useEffect, useState } from "react";
import SettingHeader from "../components/static/SettingHeader";
import { Col, Container, Row } from "react-bootstrap";
import SettingSidebar from "../components/static/SettingSidebar";
import CompleteProfileForm from "../components/static/CompleteProfileForm";
import { GetUserById } from "../services/userProfile";
import { GetAuthUserLocalStorage } from "../services/localStorage/localStorage";
import CustomCircularProgressbar from "../components/general/CustomCircularProgressbar";
import { uploadImageContext } from "../App";
import { Loader } from "../components/loader/Loader";

const EditProfilePage = () => {
    const [activeStep, setActiveStep] = useState(1);
    const userId = GetAuthUserLocalStorage()?._id;
    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState([]);
    const [uploadImage, setUploadImage] = useContext(uploadImageContext);
    const user = GetAuthUserLocalStorage();

    useEffect(() => {
        if (userId) {
            GetByUserId();
        }
    }, [userId]);

    const GetByUserId = async () => {
        setLoading(true)
        const res = await GetUserById(userId);
        const response = res?.data?.data;
        setProfileData(response);
        // setUploadImage(response?.images);
        setLoading(false)
    };

    return (
        <div className="static-pages edit-profile-page">
            <div className="d-none d-md-block">
                <SettingHeader />
            </div>

            <Container>
                <Row>
                    <Col md={3} lg={3} xl={3} className="d-none d-md-block">
                        <SettingSidebar />
                    </Col>

                    <Col md={9} lg={9} xl={7}>
                        <div className="content py-5 py-md-5 ps-md-5">
                            <h2 className="color-blue mb-4">Edit Profile</h2>
                            {loading ?

                                <Loader /> :
                                profileData &&
                                    profileData?.mother_tongue &&
                                    profileData?.mother_tongue?.length > 0 ? (
                                    <>
                                        <div className="step-form-wrapper mt-4 mt-xl-0">
                                            <div className="header mb-30">
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <div
                                                            className={`step ${activeStep == 1 ? "active" : ""
                                                                } d-flex align-items-center justify-content-md-center border-right pb-3 pb-md-0`}
                                                        >
                                                            <div className="circle">
                                                                <h6>01</h6>
                                                            </div>
                                                            <h6 className="text-uppercase ms-3">
                                                                Basic information
                                                            </h6>
                                                        </div>
                                                    </Col>

                                                    <Col xs={12} md={6}>
                                                        <div
                                                            className={`step ${activeStep == 2 ? "active" : ""
                                                                } d-flex align-items-center justify-content-md-center mt-3 mt-md-0`}
                                                        >
                                                            <div className="circle">
                                                                <h6>02</h6>
                                                            </div>
                                                            <h6 className="text-uppercase ms-3">
                                                                personal information
                                                            </h6>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="steps-form">
                                                <Row>
                                                    <Col xs={12}>
                                                        <CompleteProfileForm
                                                            profileData={profileData}
                                                            loading={loading}
                                                            activeStep={activeStep}
                                                            setActiveStep={setActiveStep}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="mt-5 mb-5 complete_profile text-danger">
                                        <p>First Complete Your Profile!</p>
                                        <div>
                                            <CustomCircularProgressbar />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default EditProfilePage;
