import React, { useEffect, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { act } from "react-dom/test-utils";
import { useForm } from "react-hook-form";
import BasicInfoForm from "./BasicInfoForm";
import PersonalInfoForm from "./PersonalInfoForm";
import { useNavigate, useParams } from "react-router-dom";
import { createProfile, updateProfile } from "../../services/userProfile";
import { error, success } from "../../constants/msg";
import { uploadImageContext, uploadedImageContext } from "../../App";
import { uploadImageAPI } from "../../services/uploadimage";
import {
  GetAuthUserLocalStorage,
  SetAuthUserLocalStorage,
} from "../../services/localStorage/localStorage";
import { constant } from "../../utils/constants";
import { DropDown } from "../../services/dropdown";

const CompleteProfileForm = ({
  activeStep,
  setActiveStep,
  profileData,
  loading,
  ...props
}) => {
  const Location = window.location.pathname
  const user = GetAuthUserLocalStorage();
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState,
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [religion, setReligion] = useState([]);

  const GetCountryDropDown = async () => {
    const params = {
      type: "country",
    };
    const res = await DropDown(params);
    const response = res?.data?.data?.map((dt) => ({
      value: dt?._id,
      label: dt?.name,
    }));
    setCountry(response);
  };

  const GetReligionDropDown = async () => {
    const params = {
      type: "religion",
    };
    const res = await DropDown(params);
    const response = res?.data?.data?.map((dt) => ({
      value: dt?._id,
      label: dt?.name,
    }));
    setReligion(response);
  };
  useEffect(() => {
    GetCountryDropDown();
    GetReligionDropDown();
  }, [])
  const [uploadImage, setUploadImage] = useContext(uploadImageContext);
  const [uploadedImage, setUploadedImage] = useContext(uploadedImageContext);
  const createImagePreview = (file) => {
    return URL.createObjectURL(file);
  };

  const handleNextStep = async () => {
    setActiveStep(activeStep + 1);

    const tempData = [];
    for (let index = 0; index < uploadImage.length; index++) {
      const item = uploadImage[index];

      const formData = new FormData();

      const key = `image`;
      // const url = URL.createObjectURL(item);

      formData.append(key, item);

      try {
        const res = await uploadImageAPI(formData);
        const responseImage = res?.data?.data?.filename;

        tempData.push(responseImage);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
    setUploadedImage(tempData);
  };


  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const onUpdateSubmit = async (data) => {
    const dataArry = [];
    const createData = {};

    const mother_tongue = data["motherToungh"]?.map((obj) => obj.label);
    const uniqueMoherTongue = [...new Set(mother_tongue)];
    const spokenLanguage = data["spokenLanguage"]?.map((obj) => obj.label);
    const uniqueSpokenLang = [...new Set(spokenLanguage)];
    createData["images"] = uploadedImage;
    createData["age"] = data?.age;
    createData["gender"] = data?.gender?.label;
    createData["about"] = data?.about;
    createData["country"] = data?.Country;
    createData["cast"] = data?.cast;
    createData["complexion"] = data?.complexion?.label;
    createData["country"] = data?.country?.label;
    createData["drinking_habit"] = data?.drinkingHebits?.label;
    createData["eating_habit"] = data?.eatingHebits?.label;
    createData["height"] = Number(data?.feet + "." + data?.inch);
    createData["marital_status"] = data?.martialStatus?.label;
    createData["mother_tongue"] = uniqueMoherTongue;
    createData["physical_status"] = data?.physicalStatus?.label;
    createData["religion"] = data?.religion?.label;
    createData["smoking_habit"] = data?.smokingHebits?.label;
    createData["spoken_language"] = uniqueSpokenLang;
    createData["vegetarian"] = data?.vegetarian?.label;
    dataArry.push(createData);
    try {
      const res = await updateProfile(user?._id, createData);
      success(res?.data?.message);
      SetAuthUserLocalStorage(res?.data?.data);
      navigate("/membership");
    } catch (err) {
      error(err?.response?.data?.message);
    }
  };
  const onSubmit = async (data) => {
    const dataArry = [];
    const createData = {};

    const mother_tongue = data["motherToungh"]?.map((obj) => obj.label);
    const uniqueMoherTongue = [...new Set(mother_tongue)];
    const spokenLanguage = data["spokenLanguage"]?.map((obj) => obj.label);
    const uniqueSpokenLang = [...new Set(spokenLanguage)];
    createData["images"] = uploadedImage;
    createData["age"] = data?.age;
    createData["gender"] = data?.gender?.label;
    createData["about"] = data?.about;
    createData["country"] = data?.Country;
    createData["preferredCountry"] = data?.preferredCountry?.label;
    createData["cast"] = data?.cast;
    createData["complexion"] = data?.complexion?.label;
    createData["country"] = data?.country?.label;
    createData["drinking_habit"] = data?.drinkingHebits?.label;
    createData["eating_habit"] = data?.eatingHebits?.label;
    createData["height"] = Number(data?.feet + "." + data?.inch);
    createData["marital_status"] = data?.martialStatus?.label;
    createData["mother_tongue"] = uniqueMoherTongue;
    createData["physical_status"] = data?.physicalStatus?.label;
    createData["religion"] = data?.religion?.label;
    createData["preferredReligion"] = data?.preferredReligion?.label
    createData["smoking_habit"] = data?.smokingHebits?.label;
    createData["spoken_language"] = uniqueSpokenLang;
    createData["vegetarian"] = data?.vegetarian?.label;

    dataArry.push(createData);
    try {
      const res = await createProfile(createData);
      success(res?.data?.message);
      SetAuthUserLocalStorage(res?.data?.data);
      reset()
      if (profileData) {
        reset()
        navigate("/setting/my-profile");
      }
      else {
        reset()
        navigate("/membership");
      }
    } catch (err) {
      error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (profileData) {
      const images_option = profileData.images.map((language) => ({
        label: language,
        value: language,
      }));
      // setValue("image", `${constant.BASE_URL}/${images_option}`)
      setValue("age", profileData.age);
      const motherTongueOptions = profileData.mother_tongue.map((language) => ({
        label: language,
        value: language,
      }));
      setValue("motherToungh", motherTongueOptions);
      const spokenTongueOptions = profileData.spoken_language.map(
        (language) => ({
          label: language,
          value: language,
        })
      );

      setValue("spokenLanguage", spokenTongueOptions);
      setValue("gender", { label: profileData?.gender });
      setValue("country", { label: profileData?.country });
      setValue("preferredCountry", { label: profileData?.preferredCountry });
      setValue("preferredReligion", { label: profileData?.preferredReligion });
      setValue("cast", profileData?.cast);
      setValue("complexion", { label: profileData?.complexion });
      setValue("drinkingHebits", { label: profileData?.drinking_habit });
      setValue("eatingHebits", { label: profileData?.eating_habit });
      setValue("martialStatus", { label: profileData?.marital_status });
      setValue("physicalStatus", { label: profileData?.physical_status });
      setValue("religion", { label: profileData?.religion });
      setValue("smokingHebits", { label: profileData?.smoking_habit });
      setValue("vegetarian", { label: "No" });
      const Height = profileData?.height.toString().split(".");
      setValue("feet", Height[0]);
      setValue("inch", Height[1]);
      setValue("about", profileData?.about);
    }
  }, [profileData]);

  const stepForm = {
    1: (
      <BasicInfoForm
        control={control}
        formState={formState}
        setValue={setValue}
        handleStep={handleNextStep}
        profileData={profileData}
        country={country}
        religion={religion}
      />
    ),
    2: (
      <PersonalInfoForm
        control={control}
        formState={formState}
        handleStep={handlePreviousStep}
        profileData={profileData}
        country={country}
        religion={religion}
      />
    ),
  };

  return (
    <div className="complete-profile-form">
      <Form onSubmit={handleSubmit(onSubmit)}>{stepForm[activeStep]}</Form>
    </div>
  );
};

export default CompleteProfileForm;
