import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { RingIcon } from "../../constants/svgs";
import ProfilePopup from "../popup/ProfilePopup";
import LogoutPopup from "../popup/LogoutPopup";
import {
  EmptyLocalStorage,
  GetAuthUserLocalStorage,
  GetTokenLocalStorage,
} from "../../services/localStorage/localStorage";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BiMessageDetail } from "react-icons/bi";
import DeleteConfirmPopup from "../popup/DeleteConfirmPopup";
import { DeleteUserById } from "../../services/userProfile";
import { error, success } from "../../constants/msg";

const ThemeNavbar = ({ logo, layout, userIcon }) => {
  const [profilePopup, setProfilePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const navigate = useNavigate();

  const checkToken = GetTokenLocalStorage();
  console.log(checkToken);

  const handleLogoutPopup = () => {
    setProfilePopup(false);
    setLogoutPopup(true);
  };
  const handleDeletePopup = () => {
    setProfilePopup(false);
    setDeletePopup(true);
  };

  const user_id = GetAuthUserLocalStorage()?._id;
  const DeleteAcc = async () => {
    try {
      await DeleteUserById(user_id);
      navigate("/");
      EmptyLocalStorage();
      setDeletePopup(false)
      success("Suceesfully Deleted!");
    } catch (e) {
      error(e?.response?.data?.message);
    }
  };
  return (
    <>
      <Navbar expand="lg" className="theme-navbar">
        <div className="ring-icon">
          <RingIcon />
        </div>
        <Container>
          <div className="brand-logo">
            <img src={logo} alt="" className="object-fit-contain image-fluid" />
            {layout == "auth" && (
              <div className="svg-wrapper">
                <RingIcon />
              </div>
            )}
          </div>


          <div className="d-flex align-items-center d-lg-none">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {checkToken &&
              (
                <>
                  <div
                    className="circle cursor"
                    onClick={() => setProfilePopup(true)}
                  >
                    {userIcon}
                  </div>

                  <div className="circle cursor ms-0 ">
                    <NavLink to="/setting/notifications" className="m-0">
                      <IoMdNotificationsOutline size={22} className="text-white" />
                      <div className="count">5</div>
                    </NavLink>
                  </div>

                  <div className="circle cursor ms-0">
                    <NavLink to="/setting/my-messages" className="m-0">
                      <BiMessageDetail size={22} className="text-white" />
                    </NavLink>
                  </div>
                </>
              )
            }


          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="d-flex align-items-center ms-auto">
              <div className={`basic-nav`}>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/about">About</NavLink>
                <NavLink to="/contact">Contact</NavLink>
                <NavLink to="/membership">See Plans</NavLink>
              </div>

              <div className="login-signup-box d-flex align-items-center">
                {checkToken ? (
                  <>
                    <div
                      className="circle cursor"
                      onClick={() => setProfilePopup(true)}
                    >
                      {userIcon}
                    </div>

                    <div className="circle cursor ms-0">
                      <NavLink to="/setting/notifications">
                        <IoMdNotificationsOutline
                          size={22}
                          className="text-white"
                        />
                        <div className="count">5</div>
                      </NavLink>
                    </div>

                    <div className="circle cursor ms-0">
                      <NavLink to="/setting/my-messages">
                        <BiMessageDetail size={22} className="text-white" />
                      </NavLink>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        margin: "15px",
                      }}
                    ></div>

                    <div className="auth-nav ml-5">
                      <NavLink to="/signin" className="login">
                        Login
                      </NavLink>
                      <NavLink to="/register">Register</NavLink>
                    </div>
                  </>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ProfilePopup
        profilePopup={profilePopup}
        setProfilePopup={setProfilePopup}
        handleLogoutPopup={handleLogoutPopup}
        handleDeletePopup={handleDeletePopup}
      />
      <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />
      <DeleteConfirmPopup
        deleteConfirmPopup={deletePopup}
        setDeleteConfirmPopup={setDeletePopup}
        handleDelete={DeleteAcc}
        txtFirst={"Delete Account"}
        cnfrmtxt={"Delete"}
        txtSecond={"Are you sure you want to Delete Account?"}
      />
    </>
  );
};

export default ThemeNavbar;
