import React from 'react'
import { Carousel, Container, Row, Col } from 'react-bootstrap'

const Testimonials = () => {
    return (
        <div className='testimonials'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Carousel fade>
                            <Carousel.Item>
                                <div>
                                    <h2>Sarah Mark</h2>
                                    <h4>
                                        Our wedding was a dream come true, and it wouldn't have
                                        been possible without the incredible team at Rishtey
                                        and Shadi. From the moment we met, we knew we were in expert
                                        hands. Their attention to detail, creativity, and unwavering
                                        support made the planning process enjoyable. Our wedding day
                                        was a beautiful reflection of our love story, and we can't
                                        thank them enough."
                                    </h4>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Testimonials