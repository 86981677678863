import React, { useEffect, useRef, useState } from 'react';
import MessageBox from './MessageBox';
import { GetAuthUserLocalStorage } from '../../services/localStorage/localStorage';

const MessagesWrapper = ({ socket, room, setUpdate, updateUser, selectedUser }) => {
    const localUser = GetAuthUserLocalStorage();
    const [messages, setMessages] = useState([]);
    const messagesWrapperRef = useRef(null);

    console.log(messages, 'messages wrapper');

    // Fetch messages for the selected room
    useEffect(() => {
        if (room) {
            getRoomMessages();
        }

        // Cleanup when component unmounts
        return () => {
            setMessages([]); // Clear messages when component unmounts
        };
    }, [room]);

    // Handle incoming messages
    useEffect(() => {
        const handleGetThreadMessages = (dt) => {
            if (dt?.room === room) {
                setMessages(dt?.data);
            }
        };

        socket.on('_get_thread_messages', handleGetThreadMessages);

        return () => {
            socket.off('_get_thread_messages', handleGetThreadMessages);
        };
    }, [socket, room]);

    // Scroll to bottom when messages change
    useEffect(() => {
        if (messagesWrapperRef.current) {
            messagesWrapperRef.current.scrollTop = messagesWrapperRef.current.scrollHeight;
        }
    }, [messages]);

    const getRoomMessages = () => {
        try {
            socket.emit('get_thread_messages', { room_id: room });
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div ref={messagesWrapperRef} className='messages-wrapper'>
            {messages.map((dt, index) => (
                <div
                    key={index}
                    className={`${dt?.sender_id === localUser._id ? 'my-message' : 'other-message'} mb-3`}
                >
                    <MessageBox message={dt} />
                </div>
            ))}
        </div>
    );
};

export default MessagesWrapper;
